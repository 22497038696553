import React, { useEffect, useState } from "react";
import classes from "./Table.module.scss";
import Modal from "../../UI/Modal/Modal";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/joy/Radio/Radio";
import ReactDOM from "react-dom";
import SigleAdvice from "../AddMemberCard/SigleAdvice";
import { Done } from "@mui/icons-material";
import SingleVoucher from "../AddMemberCard/SingleVoucher";
import Img from "../Img";
import { adviceService } from "../../../../services/adviceService";
import ClientRequest from "../AddMemberCard/ClientRequest";
import ContactRequest from "../AddMemberCard/ContactRequest";
import SingleDealOrder from "../AddMemberCard/SingleDealOrder";
import SingleDealOffer from "../AddMemberCard/SingleDealOffer";

export default function Table(props) {
  const [showSingleAdvice, setShowSingleAdvice] = useState(false);
  const [showSingleDealOffer, setShowSingleDealOffer] = useState(false);
  const [showSingleVoucher, setShowSingleVoucher] = useState(false);
  const [showSingleContact, setShowSingleContact] = useState(false);
  const [showIndexDealOffer, setShowIndexDealOffer] = useState("null");
  const [showIndexContact, setShowIndexContact] = useState("null");
  const [showIndexDealOrder, setShowIndexDealOrder] = useState("null");
  const [showOptions, setShowOptions] = useState(false);
  const [adviceData, setAdviceData] = useState(null);
  const [showSingleDealOrder, setShowSingleDealOrder] = useState(false);
  const history = useNavigate();
  const [target, setTarget] = useState("account");
  useEffect(() => {
    if (target)
      if (
        localStorage.getItem("side") === "admin" ||
        localStorage.getItem("side") === "company"
      ) {
        setTarget("account");
      } else {
        setTarget("haai-team");
      }
  }, []);
  useEffect(() => {
    showIndexDealOffer !== "null" && setShowSingleDealOffer(true);
  }, [showIndexDealOffer]);
  useEffect(() => {
    showIndexContact !== "null" && setShowSingleContact(true);
  }, [showIndexContact]);
  const [showIndexProductRequest, setShowIndexProductRequest] =
    useState("null");
  const [showSingleProductRequest, setShowSingleProductRequest] =
    useState(false);
  useEffect(() => {
    showIndexProductRequest !== "null" && setShowSingleProductRequest(true);
  }, [showIndexProductRequest]);
  useEffect(() => {
    showIndexDealOrder !== "null" && setShowSingleDealOrder(true);
  }, [showIndexDealOrder]);

  let getSingleAdvice = async (id) => {
    let response = await adviceService.getSingleAdvice(id);
    setAdviceData(response.data.advice);
    setShowSingleAdvice(true);
  };
  return (
    <div style={{ height: "auto" }} className={`${classes.table1} `}>
      {showSingleContact && (
        <Modal
          style={{
            padding: "24px",
          }}
          onClose={() => {
            setShowSingleContact(false);
            setShowIndexContact("null");
          }}
        >
          <ContactRequest
            data={props.wholeData[showIndexContact]}
            setShowForm={() => {
              setShowSingleContact(false);
              setShowIndexContact("null");
            }}
          />
        </Modal>
      )}
      {showSingleProductRequest && (
        <Modal
          style={{
            padding: "24px",
          }}
          onClose={() => setShowSingleProductRequest(false)}
        >
          <ClientRequest
            data={props.wholeData[showIndexProductRequest]}
            setShowForm={() => {
              setShowSingleProductRequest(false);
              setShowIndexProductRequest("null");
            }}
          />
        </Modal>
      )}
      {showSingleAdvice && (
        <Modal
          style={{
            height: `${window.innerHeight - 64}px`,
            padding: "24px",
          }}
          onClose={() => setShowSingleAdvice(false)}
        >
          <SigleAdvice
            onSuccess={() => {
              setShowSingleAdvice(false);
              props.onSuccess();
            }}
            item={adviceData}
            setShowForm={setShowSingleAdvice}
          />
        </Modal>
      )}
      {showSingleVoucher && (
        <Modal
          style={{
            padding: "24px",
          }}
          onClose={() => setShowSingleVoucher(false)}
        >
          <SingleVoucher setShowForm={setShowSingleAdvice} />
        </Modal>
      )}
      {showSingleDealOrder && (
        <Modal
          style={{
            padding: "24px",
            height: `${window.innerHeight - 66}px`,
            overflow: "scroll",
          }}
          onClose={() => {
            setShowIndexDealOrder("null");
            setShowSingleDealOrder(false);
          }}
        >
          <SingleDealOrder
            type={props.type}
            data={props.wholeData[showIndexDealOrder]}
            deliveryResponsibility={props.deliveryResponsibility}
            setShowForm={() => {
              setShowIndexDealOrder("null");
              setShowSingleDealOrder(false);
            }}
            updateData={() => {
              setShowSingleDealOrder(false);
              props.updateData();
            }}
          />
        </Modal>
      )}
      {showSingleDealOffer && (
        <Modal
          style={{
            padding: "24px",
            maxHeight: `${window.innerHeight - 66}px`,
            overflow: "scroll",
          }}
          onClose={() => {
            setShowSingleDealOffer(false);
            setShowIndexDealOffer("null");
          }}
        >
          <SingleDealOffer
            unit={props.unit}
            data={props.wholeData[showIndexDealOffer]}
            specialSpecification={props.specialSpecification}
            setShowForm={() => {
              setShowSingleDealOffer(false);
              setShowIndexDealOffer("null");
            }}
          />
        </Modal>
      )}
      <table bgcolor="white" className={classes.table}>
        {showOptions &&
          ReactDOM.createPortal(
            <div
              className={classes.backdrop}
              onClick={() => {
                setShowOptions(false);
              }}
            ></div>,
            document.getElementById("overlays")
          )}

        <thead>
          <tr className="">
            {props.options.map((item, index) => (
              <th
                onClick={(e) => {
                  e.preventDefault();
                  !showOptions && setShowOptions(index);
                }}
                key={index}
                scope="col"
                className={`
             ${
               props.type === "Supplier Offer Response Details" &&
               index > 0 &&
               props.wholeData[index - 1].isUsed &&
               classes.selected1
             }
                
                ${
                  item.filterOptions && "cursor"
                } position-relative text-nowrap`}
              >
                {item.title}
                {item.icon ? <span> {item.icon}</span> : " "}
                {item.filterOptions && showOptions === index && (
                  <div className={`${classes.options} position-absolute`}>
                    {item.filterOptions.map((i, index) => (
                      <div
                        onClick={() => {
                          item.setOption(i);
                        }}
                        key={index}
                        className="row align-items-center d-flex mx-0 px-0"
                      >
                        <div className="w-auto p-0 d-inline ">
                          <Radio
                            className="px-0  d-flex justify-content-center text-center"
                            checked={item.selectedOption === i ? true : false}
                            id={`radio${index}`}
                            checkedIcon={<Done fontSize="xl2" />}
                            slotProps={{
                              input: {
                                backgroundColor: "#134074",
                                background: "#134074",
                                color: "#134074",
                              },
                              radio: {
                                backgroundColor: "#134074",
                                background: "#134074",
                                color: "#134074",
                              },
                            }}
                          />
                        </div>
                        <label htmlFor={`radio${index}`}>{i}</label>
                      </div>
                    ))}
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>

        {props.data.length > 0 ? (
          <tbody>
            {props.data.map((data1, index1) => (
              <tr
                key={index1}
                className={`cursor  ${
                  props.type === "Cold Deal Suppliers" &&
                  index1 === 0 &&
                  classes["selected-supplier"]
                }
                ${
                  props.type === "Cold Deal Suppliers" &&
                  index1 === 0 &&
                  "rounded-pill "
                }`}
                onClick={() => {
                  props.type === "Advice" &&
                    getSingleAdvice(props.wholeData[index1]._id);
                  props.type === "Product" &&
                    history(`/account/product/${props.wholeData[index1]._id}`);
                  props.type === "Voucher" && setShowSingleVoucher(true);

                  props.type === "DealOrder" && setShowIndexDealOrder(index1);
                  props.type === "ColdDealOrder" &&
                    setShowIndexDealOrder(index1);
                  props.type === "Supplier Offer Response" &&
                    history(
                      `/account/original-deal/offer/${props.wholeData[index1]._id}`
                    );
                }}
              >
                {data1.map((item, index) => (
                  <th
                    onClick={() => {
                      if (index !== 0) {
                        (props.type === "Supplier Offer Response Details" &&
                          setShowIndexDealOffer(index - 1)) ||
                          (props.type === "Client Request" &&
                            setShowIndexContact(index1));
                        props.type === "Product Request" &&
                          setShowIndexProductRequest(index1);
                      }
                    }}
                    className={`${
                      props.type === "Supplier Offer Response" &&
                      index > 0 &&
                      props.wholeData[index1].quantities[index - 1].isUsed &&
                      classes.selected
                    }
                    ${
                      props.type === "Supplier Offer Response Details" &&
                      index > 0 &&
                      props.wholeData[index - 1].isUsed &&
                      classes.selected1
                    }  `}
                    key={`${index1 + index}`}
                    style={
                      props.customStyleIndex.includes(index + 1)
                        ? { ...props.customStyleFn[index](item) }
                        : {}
                    }
                    scope="col"
                  >
                    {props.type === "Category" &&
                    props.options[index]?.title === "Category Image" ? (
                      <Img
                        className="bg-dark"
                        key={index}
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/category/${item}`}
                      />
                    ) : props.type === "Sub Category" &&
                      props.options[index]?.title === "Sub-Category Image" ? (
                      <Img
                        className="bg-dark"
                        key={index}
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/subcategory/${item}`}
                      />
                    ) : (
                      item
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
      {props.data.length <= 0 && (
        <div className="w-100 row justify-content-center pt-5 pb-4">
          <div className="no-found-data mx-auto w-auto  text-center w-100 ">
            There is No Data Found
          </div>
        </div>
      )}
    </div>
  );
}
