import React, { useEffect, useState } from "react";
import BaseNavBar from "../../components/Reusable/Common/BaseNavBar/BaseNavBar";
import Section from "../../components/Reusable/Common/Section/Section";
import Button from "../../components/Reusable/UI/Button/Button";
import { policiesService } from "../../services/policiesService";
import { Skeleton } from "@mui/material";
export default function PoliciesPage(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lang, setLang] = useState("english");
  let getPolicies = async () => {
    let response =
      (props.type === "Terms & Conditions" &&
        (await policiesService.getAllPolicies("terms-and-conditions"))) ||
      (props.type === "Privacy Policy" &&
        (await policiesService.getAllPolicies("privacy-policy", "consumer")));

    setData(
      response.data.terms ||
        response.data.policies ||
        response.data.deliveryTerms
    );
    setLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => getPolicies());
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className={`${props.login && "d-flex flex-grow-1  flex-column"}`}>
      <BaseNavBar shadow={true} text1="Arabic" text2="English" />

      <h1
        style={{
          width: "100%",
          textAlign: "center",
          color: "#134074",
          fontWeight: " 500 !important",
          fontSize: "40px",
          paddingTop: "16px",
        }}
      >
        {lang === "english"
          ? props.type === "Terms & Conditions"
            ? "Terms & Conditions"
            : "Privacy Policy"
          : props.type === "Terms & Conditions"
          ? "الشروط و الاحكام"
          : "سياسة الخصوصية"}
      </h1>

      <div className="container">
        <>
          <div
            className={` row  mb-2 mx-0  ${
              props.login ? "px-5" : "px-0"
            } text-end justify-content-end`}
          >
            {/* <div className="col-6 me-lg-2"> */}
            <Button
              onClick={() => {
                setLang("arabic");
              }}
              // style={{ left: "0" }}
              className="w-lg-auto  me-lg-2"
              text={"Arabic"}
              color={lang === "english" ? "transparent" : "#D1E6FF"}
              borderColor={lang === "english" ? "gray" : "#134074"}
              fontColor={lang === "english" ? "gray" : "#134074"}
              type={lang === "english" ? "reverse" : "normal"}
              //   disabled={props.disabled1}
            >
              {/* {props.text} */}
            </Button>
            {/* </div> */}
            {/* <div className="col-6 ms-lg-2"> */}
            <Button
              onClick={() => {
                setLang("english");
              }}
              // style={{ left: "0", top: "40px" }}
              className="w-lg-auto mt-lg-0 mt-2  ms-lg-2 "
              text={"English"}
              color={lang === "arabic" ? "transparent" : "#D1E6FF"}
              borderColor={lang === "arabic" ? "gray" : "#134074"}
              fontColor={lang === "arabic" ? "gray" : "#134074"}
              type={lang === "arabic" ? "reverse" : "normal"}
              //   disabled={props.disabled2}
            >
              {/* {props.text} */}
            </Button>
          </div>
          {loading
            ? [0, 1, 2, 3, 4, 5, 6].map((item, index) => (
                <div
                  key={index}
                  className={`row ${props.login ? "px-3" : "px-0"} mx-0 mb-3`}
                >
                  {/* english section */}
                  <div className="col-12 px-0">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "50%" }}
                    />

                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </div>

                  {/* arabic section */}
                </div>
              ))
            : data.map((item, index) => (
                <div
                  key={index}
                  className={`row ${props.login ? "px-5" : "px-0"} mx-0`}
                >
                  {lang === "english" ? (
                    <Section title={item.title_en} text={item.content_en} />
                  ) : (
                    <Section title={item.title_ar} text={item.content_ar} />
                  )}
                </div>
              ))}
        </>
      </div>
    </div>
  );
}
