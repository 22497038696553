import React, { useState } from "react";
import classes from "./OriginalDeals.module.scss";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import DealsAvtiveL from "../../components/Reusable/SVG/DealsAvtiveL";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import SearchInput from "../../components/Reusable/UI/InputFeild/SearchInput";
import Plus from "../../components/Reusable/SVG/Plus";
import ReactDOM from "react-dom";
import VoucherCard from "../../components/Reusable/Common/Voucher/VoucherCard";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { originalDealsEshtreanaService } from "../../services/OriginalDealsEshtreana";
import { useEffect } from "react";
import UsePagination from "../../components/Reusable/UI/Pagination/Pagination";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import { mainDataHelper } from "../../Helper/MainData/MainData";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
export default function Deals() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);

  const [option, setOption] = useState("On Going");
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [deals, setDeals] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDealType, setSelectedDealType] = useState("select type");
  const [selectedCategory, setSelectedCategory] = useState("select category");
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    "select sub-category"
  );
  const [selectedArea, setSelectedArea] = useState("select city");
  const [selectedDistrict, setSelectedDistrict] = useState("select district");
  const [searchValue, setSearchValue] = useState("");
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);

  let getDeals = async () => {
    let query = "";
    if (option !== "Requests")
      query += `${`&type=${option.toLocaleLowerCase()}`}`;
    query += `${
      selectedDealType &&
      selectedDealType !== " " &&
      selectedDealType !== "select type"
        ? `${`&dealType=${selectedDealType.toLowerCase()}`}`
        : ""
    }`;
    query += `${
      selectedCategory &&
      selectedSubCategory !== " " &&
      selectedCategory !== "select category"
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ""
    }`;
    query += `${
      selectedDistrict &&
      selectedDistrict !== " " &&
      selectedDistrict !== "select district"
        ? `&district=${selectedDistrict.toLowerCase()}`
        : ""
    }`;
    query += `${
      selectedArea && selectedArea !== " " && selectedArea !== "select city"
        ? `&area=${selectedArea}`
        : ""
    }`;
    query += `${searchValue ? `&search=${searchValue}` : ""}`;
    let response =
      option === "Requests"
        ? await originalDealsEshtreanaService.getRequestsDeals(page, 6, query)
        : await originalDealsEshtreanaService.getDeals(page, 6, query);
    let Valid = false;
    //console.log(response);
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setDeals(response.data.deals);
      setTotalPages(response.data.totalPages);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }

    setLoading(false);
    setLoadingSearch(false);
  };
  let getResponseDeals = async () => {
    let query = "";
    query += `${
      selectedDealType &&
      selectedDealType !== " " &&
      selectedDealType !== "select type"
        ? `${`&dealType=${selectedDealType.toLowerCase()}`}`
        : ""
    }`;
    query += `${
      selectedCategory &&
      selectedSubCategory !== " " &&
      selectedCategory !== "select category"
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ""
    }`;
    query += `${
      selectedDistrict &&
      selectedDistrict !== " " &&
      selectedDistrict !== "select district"
        ? `&district=${selectedDistrict.toLowerCase()}`
        : ""
    }`;
    query += `${
      selectedArea && selectedArea !== " " && selectedArea !== "select city"
        ? `&area=${selectedArea}`
        : ""
    }`;
    query += `${searchValue ? `&search=${searchValue}` : ""}`;

    let response = await originalDealsEshtreanaService.getDealsResponse(
      page,
      6,
      query
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      setDeals(response.data.deals);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
    setLoadingSearch(false);
  };

  useEffect(() => {
    mainDataHelper.getAllCities(setServerError, setServerErrorMsg, setCities);
    mainDataHelper.getAllCategories(
      setServerError,
      setServerErrorMsg,
      setCategories,
      setCategoriesName
    );
  }, []);
  useEffect(() => {
    if (!firstLoad) {
      if (selectedArea !== "select city")
        mainDataHelper.getAllDistrict(
          setServerError,
          setServerErrorMsg,
          selectedArea,
          setDistrict
        );
      else {
        setSelectedDistrict("select district");
        setDistrict([]);
      }
    }
  }, [selectedArea]);
  useEffect(() => {
    setLoading(true);
    if (!firstLoad) option === "Responses" ? getResponseDeals() : getDeals();
  }, [page]);
  useEffect(() => {
    let timer = setTimeout(() => {
      if (!firstLoad) {
        setDeals([]);
        if (page === 1) {
          setLoadingSearch(true);
          option === "Responses" ? getResponseDeals() : getDeals();
        } else setPage(1);
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  useEffect(() => {
    if (!firstLoad) {
      setDeals([]);
      if (page === 1) {
        setLoading(true);
        option === "Responses" ? getResponseDeals() : getDeals();
      } else setPage(1);
    }
  }, [selectedArea, selectedCategory, selectedDistrict, selectedDealType]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      // setDeals([]);
      setSelectedDealType("select type");
      setSelectedCategory("select category");
      setSelectedSubCategory("select sub-category");
      setSelectedArea("select city");
      setSelectedDistrict("select district");
      if (page === 1) option === "Responses" ? getResponseDeals() : getDeals();
      else setPage(1);
    }
  }, [option]);
  useEffect(() => {
    setLoading(true);
    option === "Responses" ? getResponseDeals() : getDeals();
    setFirstLoad(false);
  }, []);

  return (
    <div className={`w-100 ${classes["original-deals"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      {showFilters &&
        ReactDOM.createPortal(
          <div
            className={"backdrop"}
            onClick={() => setShowFilters(false)}
          ></div>,
          document.getElementById("overlays")
        )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          onClick={() => history("/account/original-deal/new")}
          text={"Original Deals"}
          icon={<DealsAvtiveL />}
          buttonText="Add Deal"
          buttonType="normal"
          buttonIcon={<Plus color="black" />}
        />
        {loading ? (
          <section className={`${classes["main-section"]}`}>
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              <div className="row px-0 mx-0">
                {[0, 1, 2, 3].map((index) => (
                  <div key={index} className="col-xl-2 col-lg-4">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "2rem", width: "100%" }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div
              className={`row   mx-0 px-0  mt-0  align-items-end  ${classes["table-option"]}`}
            >
              <div className="col-xl-3  col-12 px-0 mb-xl-0 mb-2 ">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "3rem", width: "100%" }}
                />
              </div>
              <div className="col-xl-9 px-0 ps-xl-2 pe-xl-4">
                <div className="row px-0 mx-0 mb-xl-0 mb-2">
                  {[0, 1, 2, 3].map((index) => (
                    <div key={index} className="col-xl-3 col-3 pe-1 px-0">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "3rem", width: "100%" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <>
              <div className="row px-0 mx-0">
                {[0, 1, 2, 3, 4, 5].map((item, index) => (
                  <div key={index} className={`col-xl-4 col-lg-6 px-0 pe-lg-3`}>
                    <Skeleton
                      variant="rounded"
                      sx={{
                        borderRaduis: "10px",
                        fontSize: "2rem",
                        width: "100%",
                        height: "400px",
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          </section>
        ) : (
          <section className={`${classes["main-section"]}`}>
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setSearchValue("");
                  setOption(val);
                }}
                default={option}
                options={[
                  "On Going",
                  "Responses",
                  "Scheduled",
                  "History",
                  "Requests",
                ]}
              />
            </div>

            {!firstLoad && (
              <div
                className={`row   mx-0 px-0  mt-0  align-items-end  ${classes["table-option"]}`}
              >
                <div className="col-xl-4  col-12 px-0 mb-xl-0 mb-2 ">
                  <SearchInput
                    onChange={(val) => {
                      setLoadingSearch(true);
                      setSearchValue(val.target.value);
                    }}
                    placeholder={`Search by Deal Name or Deal Code`}
                  />
                </div>
                <div className="col-xl-8 px-0 ps-xl-2 pe-xl-4">
                  <div className="row px-0 mx-0 mb-xl-0 mb-2">
                    {/* {categoriesName.length > 0 && ( */}
                    {true && (
                      <div className="col-xl-3  col-3 pe-1 px-0">
                        <InputGroup
                          options={[
                            "select type",
                            "Original",
                            "Big area deal",
                            "Big deal",
                            "Area deal",
                          ]}
                          filter={true}
                          onChange={(e) => setSelectedDealType(e.target.value)}
                          type="dropdown"
                          value={"Deal Type"}
                          default={selectedDealType}
                        />
                      </div>
                    )}
                    {categories && (
                      <div className="col-xl-3  col-3 pe-1 px-0">
                        <InputGroup
                          options={["select category", ...categoriesName]}
                          filter={true}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          type="dropdown"
                          value={"Category"}
                          default={selectedCategory}
                        />
                      </div>
                    )}

                    {cities.length > 0 && (
                      <div className="col-xl-3 col-3 px-0 ps-1">
                        <InputGroup
                          options={["select city", ...cities]}
                          filter={true}
                          onChange={(e) => setSelectedArea(e.target.value)}
                          type="dropdown"
                          value="Area"
                        />
                      </div>
                    )}
                    {district.length > 0 && (
                      <div className="col-xl-3 col-3 px-0 ps-1">
                        <InputGroup
                          options={["select District", ...district]}
                          filter={true}
                          onChange={(e) => setSelectedDistrict(e.target.value)}
                          type="dropdown"
                          value="District"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {option === "On Going" && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: "Deal Name",
                          value: item.title_en,
                        },
                        {
                          title: "Deal Code",
                          value: item.uuid,
                        },
                        {
                          title: "Product Name",
                          value: item.product,
                        },
                        {
                          title: "Supplier Name",
                          value: item.supplier,
                        },
                        {
                          title: "Time Left",
                        },
                        {
                          title: "Deal Creation Date",
                          value: item?.createdAt?.slice(0, 10),
                        },
                        {
                          title: "Progress",
                          value: item.sold,
                          total: item.quantity,
                        },
                      ]}
                      type="On Going"
                      side={"ManagerDeal"}
                      timeLeft={item.endDate}
                      status={true}
                      statusValue={
                        item.dealType === "Big area deal"
                          ? "Big Area Deal"
                          : item.dealType === "Area deal"
                          ? "Area Deal"
                          : item.dealType === "Big deal"
                          ? "Big Deal"
                          : "Original Deal"
                      }
                      progress={true}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )}
            {option === "Scheduled" && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: "Deal Name",
                          value: item.title_en,
                        },
                        {
                          title: "Deal Code",
                          value: item.uuid,
                        },
                        {
                          title: "Product Name",
                          value: item.product,
                        },
                        {
                          title: "Supplier Name",
                          value: item?.supplier,
                        },
                        {
                          title: "Starts At",
                          value: new Date(item?.startDate).toLocaleDateString(),
                        },
                        {
                          title: "Ends At",
                          value: new Date(item?.endDate).toLocaleDateString(),
                        },
                      ]}
                      status={true}
                      statusValue={
                        item.dealType === "Big area deal"
                          ? "Big Area Deal"
                          : item.dealType === "Area deal"
                          ? "Area Deal"
                          : item.dealType === "Big deal"
                          ? "Big Deal"
                          : "Original Deal"
                      }
                      type="Scheduled"
                      side={"ManagerDeal"}
                      progress={false}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )}
            {option === "Responses" && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: "Request Code",
                          value: item.uuid,
                        },
                        {
                          title: "Total Number of Supplier",
                          value: item.suppliers,
                        },
                        {
                          title: "Joined Supplier",
                          value: item.joinedSupplier,
                        },
                        {
                          title: "Request Date",
                          value: item?.createdAt?.slice(0, 10),
                        },
                        {
                          title: "Product Name",
                          value: item.product,
                        },
                      ]}
                      type="Responses"
                      status={true}
                      statusValue={
                        item.dealType === "Big area deal"
                          ? "Big Area Deal"
                          : item.dealType === "Area deal"
                          ? "Area Deal"
                          : item.dealType === "Big deal"
                          ? "Big Deal"
                          : "Original Deal"
                      }
                      side={"ManagerDeal"}
                      progress={false}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )}
            {option === "Requests" && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: "Request Code",
                          value: item.uuid,
                        },
                        {
                          title: "Total Number of Supplier",
                          value: item.suppliers,
                        },
                        // {
                        //   title: "Joined Supplier",
                        //   value: item.joinedSupplier,
                        // },
                        {
                          title: "Request Date",
                          value: item?.createdAt?.slice(0, 10),
                        },
                        {
                          title: "Product Name",
                          value: item.product,
                        },
                      ]}
                      type="Request"
                      status={true}
                      statusValue={
                        item.dealType === "Big area deal"
                          ? "Big Area Deal"
                          : item.dealType === "Area deal"
                          ? "Area Deal"
                          : item.dealType === "Big deal"
                          ? "Big Deal"
                          : "Original Deal"
                      }
                      side={"ManagerDeal"}
                      progress={false}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )}
            {option === "History" && (
              <div className="row px-0 mx-0 ">
                {deals.map((item, index) => (
                  <div key={index} className={`col-xl-4  col-lg-6 px-0`}>
                    <VoucherCard
                      id={item._id}
                      data={[
                        {
                          title: "Deal Name",
                          value: item.title_en,
                        },
                        {
                          title: "Deal Code",
                          value: item.uuid,
                        },
                        {
                          title: "Product Name",
                          value: item.product,
                        },
                        {
                          title: "Supplier Name",
                          value: item.supplier,
                        },
                      ]}
                      type="History"
                      status={true}
                      statusValue={
                        item.dealType === "Big area deal"
                          ? "Big Area Deal"
                          : item.dealType === "Area deal"
                          ? "Area Deal"
                          : item.dealType === "Big deal"
                          ? "Big Deal"
                          : "Original Deal"
                      }
                      side={"ManagerDeal"}
                      progress={false}
                      index={index}
                      po={item.purchasingOrder && item.purchasingOrder}
                      invoice={item.invoice && item.invoice}
                    />
                  </div>
                ))}
              </div>
            )}
            {loadingSearch ? (
              <div className="w-100 row justify-content-center pt-5 pb-4">
                <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                  Searching
                </div>
              </div>
            ) : (
              deals.length === 0 && (
                <div className="w-100 row justify-content-center pt-5 pb-4">
                  <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                    There is No Deals Found
                  </div>
                </div>
              )
            )}
            {totalPages > 1 && (
              <div
                className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
              >
                <div className="w-auto d-inline mx-auto">
                  <UsePagination
                    page={page}
                    pages={totalPages}
                    onChangePage={(page) => {
                      setLoading(true);
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
