import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  error: false,
  errorMsg: "",
  counter: 0,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload.error;
      state.errorMsg = action.payload.errorMsg;
      state.counter = state.counter + 1;
    },
  },
});

export const errorReducer = errorSlice.reducer;
export const errorActions = errorSlice.actions;
