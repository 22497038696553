import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
// import ExampleCarouselImage from "components/ExampleCarouselImage";
//
export default function SwiperImages() {
  const { language } = useSelector((state) => state.language);
  return (
    <Carousel slide={true} onAnimationStart={true}>
      {[0, 1, 2].map((item, index) => (
        <Carousel.Item key={index}>
          <Carousel.Caption
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=" align-items-center d-flex"
          >
            <h3 className=" word-break ">
              {language === "en"
                ? `Welcome to the world of crowd buying`
                : `إستمتع بقوة الشراء الجماعى
`}{" "}
            </h3>
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
          <img
            style={{
              width: "100%",
              objectPosition: "center",
              objectPosition: "top",
              objectFit: "cover",
              height: `${window.innerHeight - 85}px`,
            }}
            src="https://www.figma.com/file/7UWJUUlD5Xq6OLcxzIGNmH/image/759a4e63014e1c3f311d8ade7fdab762bed3b4ad"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
