import React from "react";
import classes from "./Cashier.module.scss";
import PeopleActiveL from "../../components/Reusable/SVG/PeopleActiveL";
import Plus from "../../components/Reusable/SVG/Plus";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import UploadImageGroup from "../../components/Reusable/UI/InputGroup/UploadImageGroup";
import Button from "../../components/Reusable/UI/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { cashierService } from "../../services/CashierService";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import { useEffect } from "react";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
export default function CashierForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState({
    pic: null,
    name: null,
    password: null,
    email: null,
    phone: null,
    branchTitle: null,
    nationalId: null,
    address: null,
  });
  const [showError, setShowError] = useState(false);
  const [dataErr, setDataErr] = useState({
    pic: false,
    name: false,
    password: "Provide a Password",
    email: false,
    phone: false,
    branchTitle: false,
    nationalId: false,
    address: false,
  });

  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, "").length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === "password") {
      if (
        !e.target.value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_.@#\$%\^&\*])(?=.{8,})/
        )
      ) {
        setDataErr((prev) => ({
          ...prev,
          [e.target
            .name]: `The Password must be eight characters or longer and must contain atleast:
        [ 1 lower case character
       ,  1 upper case character
       ,  1 numeric 
       ,  1 special character ]`,
        }));
        // setValidationPasswordErr(true);
      } else {
        setDataErr((prev) => ({
          ...prev,
          [e.target.name]: null,
        }));
      }
    }
    if (e.target.name === "email") {
      if (
        !e.target.value.match(
          /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
        )
      ) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }

    if (e.target.name === "nationalId") {
      var isANumber = isNaN(e.target.value) === false;

      isANumber
        ? setDataErr((prev) => ({ ...prev, [e.target.name]: true }))
        : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      ((e.target.value.startsWith("0") && e.target.value !== "0") ||
        e.target.value.startsWith("-") ||
        e.target.value === "" ||
        e.target.value.startsWith("+")) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      // if (e.target.value.length !== 10)
      //   setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };

  let getCashierData = async () => {
    let response = await cashierService.getSingleCashier(id);

    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setData({
        pic: response.data.cashier.pic,
        name: response.data.cashier.name,
        // password: null,
        email: response.data.cashier.email,
        phone: response.data.cashier.phone,
        branchTitle: response.data.cashier.branchTitle,
        nationalId: response.data.cashier.nationalId,
        address: response.data.cashier.address,
      });
      setDataErr({
        pic: true,
        name: true,
        password: false,
        email: true,
        phone: true,
        branchTitle: true,
        nationalId: true,
        address: true,
      });
      setLoading(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
  };

  let handleSubmit = async () => {
    setShowError(true);
    if (
      dataErr.address &&
      dataErr.branchTitle &&
      dataErr.email &&
      dataErr.name &&
      dataErr.nationalId &&
      dataErr.phone &&
      dataErr.pic &&
      !dataErr.password
    ) {
      let fd = new FormData();
      fd.append("name", data.name);
      fd.append("phone", data.phone);
      fd.append("address", data.address);
      !id && fd.append("email", data.email);
      !id && fd.append("nationalId", data.nationalId);
      fd.append("branchTitle", data.branchTitle);
      data.password && fd.append("password", data.password);
      data.pic.name && fd.append("pic", data.pic, data.pic.name);
      let response = id
        ? await cashierService.updateCashier(fd, id)
        : await cashierService.createCashier(fd);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setButtonLoading(false);

        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history(id ? `/supplier/cashiers/${id}` : `/supplier/cashiers`, {
            replace: true,
          });
        }, 2000);
      } else {
        setButtonLoading(false);

        if (serverErrorMsg && serverErrorMsg.includes("Email exists"))
          setDataErr((prev) => ({ ...prev, email: false }));
        else if (serverErrorMsg && serverErrorMsg.includes("nationalId"))
          setDataErr((prev) => ({ ...prev, nationalId: false }));
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
      }
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    if (serverError)
      setTimeout(() => {
        setServerError(false);
      }, 2000);
  }, [serverError]);

  useEffect(() => {
    id && getCashierData();
  }, [id]);
  return (
    <div className={`w-100 ${classes["cashier"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Cashier ${id ? "Edited" : "Added"} Successfully`}
          />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <div className={classes["title"]}>
          <PageTitle
            text={`${id ? "Edit" : "Add"} Cashier`}
            icon={<PeopleActiveL />}
          />
        </div>
        <section className={`${classes["main-section"]}`}>
          {loading && id ? null : (
            <>
              <div className="row px-0 mx-0">
                <div className="col-12 ">
                  <div className={classes.input}>
                    <UploadImageGroup
                      video={false}
                      default={data.pic}
                      type="cashier"
                      onChange={(e) => {
                        setDataErr((prev) => ({
                          ...prev,
                          pic: true,
                        }));
                        setData((prev) => ({
                          ...prev,
                          pic: e.target.files[0],
                        }));
                      }}
                      onRemove={() => {
                        setData((prev) => ({
                          ...prev,
                          pic: null,
                        }));
                        setDataErr((prev) => ({
                          ...prev,
                          pic: false,
                        }));
                      }}
                      name="pic"
                      error={showError && !dataErr.pic}
                      errorMsg="Provide a Cashier Profile Picture"
                      value={"Profile Picture"}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={data.name}
                      name="name"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dataErr.name}
                      errorMsg="Provide an Employee Name"
                      value={"Employee Name"}
                    />
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <InputGroup
                      disable={id ? true : false}
                      default={data.email}
                      name="email"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dataErr.email}
                      errorMsg={
                        data.email
                          ? "Provide a Valid Email"
                          : "Provide a Valid Email"
                      }
                      value={"Email"}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={data.phone}
                      name="phone"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dataErr.phone}
                      errorMsg="Provide a Valid Employee Phone"
                      value={"Phone"}
                    />
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={data.password}
                      name="password"
                      onChange={(e) => handleChange(e)}
                      error={showError && dataErr.password}
                      errorMsg={dataErr.password}
                      value={"Password"}
                      type="password"
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={data.branchTitle}
                      name="branchTitle"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dataErr.branchTitle}
                      errorMsg="Provide a Branch Title"
                      value={"Branch Title"}
                    />
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={data.nationalId}
                      disable={id ? true : false}
                      name="nationalId"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dataErr.nationalId}
                      errorMsg={
                        data.nationalId
                          ? "Provide a Valid National ID"
                          : "Provide an National ID"
                      }
                      value={"National ID"}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 ">
                  <div className={classes.input}>
                    <InputGroup
                      default={data.address}
                      name="address"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dataErr.address}
                      errorMsg="Provide an Address"
                      value={"Address"}
                    />
                  </div>
                </div>
              </div>

              <div className={`row mx-0 px-0  mb-pb-0 ${classes["input"]}`}>
                <div className="col-4 ms-auto px-0  mb-pb-0">
                  <div className={`  mb-pb-0`}>
                    {buttonLoading ? (
                      <div className="text-center px-5">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          // setOption("Communication Data");
                          setButtonLoading(true);
                          handleSubmit();
                        }}
                        // icon={<Plus />}
                        color={"#D1E6FF"}
                        borderColor={"#134074"}
                        fontColor={"#134074"}
                        className={"w-auto px-5  ms-auto text-end"}
                        type="normal"
                        text={"Save"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </section>
    </div>
  );
}
