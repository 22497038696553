export let serverErrorCatcher = (
  response,
  setServerError,
  setServerErrorMsg
) => {
  if (response.response) {
    setServerErrorMsg(
      response.response.data.error || response.response.data.message
    );
    setServerError(true);
    localStorage.setItem(
      "errorMsg",
      response.response.data.error || response.response.data.message
    );
    return -1;
  } else {
    if (response.message === "Network Error") {
      setServerErrorMsg(
        "You're Offline Now ,Please check Your Internet Connection"
      );
      setServerError(true);
      localStorage.setItem("errorMsg", "Offline");
      return -1;
    } else if (response.message === "canceled") {
      return -1;
    }
    return true;
  }
};
