import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Avatar, Skeleton } from "@mui/material";
import classes from "./OriginalDeals.module.scss";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { PieChart } from "react-minimal-pie-chart";
import Img from "../../components/Reusable/Common/Img";
import { Pagination, Navigation } from "swiper/modules";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import Download from "../../components/Reusable/SVG/Download";
import Button from "../../components/Reusable/UI/Button/Button";
import Table from "../../components/Reusable/Common/Table/Table";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import DealsAvtiveL from "../../components/Reusable/SVG/DealsAvtiveL";
import Section from "../../components/Reusable/Common/Section/Section";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import UsePagination from "../../components/Reusable/UI/Pagination/Pagination";
import LoadingTable from "../../components/Reusable/Common/Table/LoadingTable";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import ManagerHeader from "../../components/Reusable/Common/MemberCard/ManagerHeader";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import { originalDealsEshtreanaService } from "../../services/OriginalDealsEshtreana";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import DownloadPopUp from "../../components/Reusable/Common/DownloadPopUp/DownloadPopUp";
import UploadImageGroup2 from "../../components/Reusable/UI/InputGroup/UploadImageGroup2";
import TermsSections from "../../components/Reusable/Common/TermsSections/TermsSections";
import ShowAreaDistrict from "../../components/Reusable/Common/AddMemberCard/ShowAreaDistrict";
import StoreLocationCard from "../../components/Reusable/Common/StoreLocationCard/StoreLocationCard";
import { errorActions } from "../../store/reducers/errorSlice";
export default function DealsDetails() {
  //----------------------Declarations---------------------------------
  let timeinterval = () => {};
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const [option, setOption] = useState("Main Data");
  const [totalPagesOrder, setTotalPagesOrder] = useState(1);
  const [pageOrder, setPageOrder] = useState(1);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [showDistrict, setShowDistrict] = useState(false);
  const [loading, setLoading] = useState(true);
  const [closeOption, setCloseOption] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [showIndexArea, setShowIndexArea] = useState(null);
  const [showAreaDistrict, setShowAreaDistrict] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [data, setData] = useState(null);
  const [dealOrders, setDealOrders] = useState([]);
  const [tableOptions, setTableOptions] = useState([]);
  const [wholeOrders, setWholeOrders] = useState([]);
  const [wholeOrders1, setWholeOrders1] = useState([]);
  const [loadingOffer, setLoadingOffer] = useState(true);
  const [totalPagesOffer, setTotalPagesOffer] = useState(1);
  const [pageOffer, setPageOffer] = useState(1);
  const [wholeOffers, setWholeOffers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [quantites, setWholeQuantites] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [doc, setDoc] = useState([]);
  const [purchasingDone, setPurchasingDoen] = useState(false);
  //----------------------Functions---------------------------------
  function getTimeRemaining(endtime) {
    const total =
      Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      clock[0].innerHTML =
        t.total > 0
          ? (t.days > 0 ? t.days : 0) +
            "D: " +
            (t.hours > 0 ? t.hours : 0) +
            "H: " +
            (t.minutes > 0 ? t.minutes : 0) +
            "M: " +
            (t.seconds > 0 ? t.seconds : 0) +
            "S "
          : "Finished";
      if (t.total <= 0) clearInterval(timeinterval);
    }, 1000);
  }
  let getDealDetails = async () => {
    let response =
      type === "response" || type === "request"
        ? await originalDealsEshtreanaService.getOfferDetails(id)
        : await originalDealsEshtreanaService.getDealDetails(id);
    let Valid = false;
    // console.log(response);
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setData(response.data.deal);
      type === "history" &&
        setPurchasingDoen(response.data.deal.purchasingDone);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };
  let getSuppliersOffers = async () => {
    let response = await originalDealsEshtreanaService.getDealOffers(
      id,
      pageOffer,
      5,
      false
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      let arr = [];
      let arr2 = [];
      if (response.data.offers.length > 0) {
        response.data.offers[0].quantities.map((item) => {
          arr2.push(item.quantity);
        });
        setWholeQuantites(arr2);
      }
      setWholeOffers(response.data.offers);
      response.data.offers.map((item) => {
        let arr1 = [];
        arr1.push(item.supplier.name_en);
        item.quantities.map((i) => {
          arr1.push(`${parseInt(i.quantity * i.price)} L.E.`);
        });
        arr.push(arr1);
      });
      setOffers(arr);
      setTotalPagesOffer(response.data.totalPages);

      let options = [];
      options.push({
        title: "Supplier",
        icon: false,
      });

      arr2.map((item) => {
        options.push({
          title: item,
          icon: false,
        });
      });

      setTableOptions(options);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoadingOffer(false);
  };

  let getDealOrders = async () => {
    let response = await originalDealsEshtreanaService.getDealOrders(
      id,
      pageOrder,
      5,
      false
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      let arr = [];
      response.data.orders.map((item) => {
        arr.push([
          item.uuid,
          item.customer.customerID,
          item.customer.customerType,
          item.customer.customerName,
          item.customer.customerPhone || "-",
          item.quantity,
          item.status,
        ]);
      });
      setDealOrders(arr);
      setTotalPagesOrder(response.data.totalPages);
      setWholeOrders(response.data.orders);
      setWholeOrders1(response.data);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoadingOrder(false);
  };

  let handleToggleAgreement = async () => {
    let response = await originalDealsEshtreanaService.toggleAgreements(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    Valid === true
      ? setPurchasingDoen((prev) => !prev)
      : dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
  };
  //TODO:to helper
  let sendPO = async () => {
    let fd = new FormData();
    fd.append("doc", doc[0], doc[0].name);
    let response = await originalDealsEshtreanaService.sendPoInvoice(id, fd);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setDoc([]);
      setData((prev) => ({
        ...prev,
        purchasingOrder: doc[0],
      }));
      setShowConfirmation(true);
      setLoading(true);

      setTimeout(() => {
        setShowConfirmation(false);
        setLoading(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
  };
  //----------------------Use Effescts---------------------------------
  useEffect(() => {
    setCloseOption(true);
  }, [closeOption]);
  useEffect(() => {
    !firstLoad && type === "response" && getSuppliersOffers();
  }, [pageOffer]);
  useEffect(() => {
    !firstLoad && getDealOrders();
  }, [pageOrder]);

  useEffect(() => {
    if (type === "onGoing") {
      if (data && data.endDate) {
        if (option !== "Main Data") {
          clearInterval(timeinterval);
        } else {
          initializeClock(new Date(data.endDate));
        }
      }

      return () => clearInterval(timeinterval);
    }
  }, [data?.endDate, option]);

  useEffect(() => {
    getDealDetails();
    getDealOrders();

    type === "response" && getSuppliersOffers();

    setFirstLoad(false);
  }, [type, id]);

  useEffect(() => {
    showIndexArea !== null && setShowDistrict(true);
  }, [showIndexArea]);
  useEffect(() => {
    doc.length > 0 && sendPO();
  }, [doc]);

  return (
    <div className={`w-100 ${classes["original-deals"]}`}>
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopup message1={"The Agreement File Sent Successfully"} />
        </Modal>
      )}

      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}
      {showAreaDistrict && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowAreaDistrict(false)}
        >
          <ShowAreaDistrict setShowForm={setShowAreaDistrict} />
        </Modal>
      )}
      {showDistrict && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowDistrict(false);
            setShowIndexArea(null);
          }}
        >
          <ShowAreaDistrict
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
            area={data.areas[showIndexArea]}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className=" custom-container ">
        <div className={classes["title"]}>
          <PageTitle text="Deal Details" icon={<DealsAvtiveL />} />
        </div>
        {loading ? (
          <div className={classes["main-section"]}>
            {type !== "response" && <ManagerHeader loading={true} />}
            <div className={`row px-0 mx-0  mb-3  ${classes.options} mb-0`}>
              <div className="row px-0 mx-0 mb-2">
                {[0, 1, 2, 3].map((index) => (
                  <div key={index} className="col-xl-2 col-lg-4">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "2rem", width: "100%" }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="row px-0 mx-0">
              <div className="col-lg-8 order-lg-1 order-2 px-0 mx-0">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <div key={index} className="row mx-0 px-0">
                    <div className={`w-auto d-inline px-0 ${classes.info}`}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "250px" }}
                        />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-4 order-lg-2 order-1 p-5 pt-0 mx-0">
                <div className={`${classes.info}`}>
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "223px", width: "284px" }}
                  />
                </div>
              </div>
            </div>
            <>
              {[0, 1, 2].map((item, index) => (
                <div key={index} className={`row w-100 px-0 mx-0 mt-2 `}>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto">
                      <p style={{ fontSize: "2rem", width: "250px" }}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "90%" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "90%" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        ) : (
          <section className={`${classes["main-section"]}`}>
            {type !== "scheduled" &&
              type !== "response" &&
              type !== "request" && (
                <ManagerHeader
                  data={{
                    name: data.supplier.name_en,
                    categories: data.supplier.categories,
                  }}
                  profilePicture={data.supplier.pic}
                  type="supplier"
                  button={
                    option === "Orders" && (
                      <Button
                        icon={<Download color={"#134074"} />}
                        color={"#D1E6FF"}
                        borderColor={"#D1E6FF"}
                        fontColor={"#134074"}
                        className={" px-2 ms-auto text-center"}
                        type="normal"
                        text={"Download"}
                      />
                    )
                  }
                  closeOption={closeOption}
                  optionComponent={
                    option === "Orders" && (
                      <DownloadPopUp
                        options={[
                          "Download Financial CSV",
                          "Download Reports CSV",
                          "Download Orders CSV",
                        ]}
                        id={id}
                        type={"original"}
                        setServerError={setServerError}
                        setServerErrorMsg={setServerErrorMsg}
                      />
                    )
                  }
                />
              )}

            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={
                  (type === "onGoing" && [
                    "Main Data",
                    "Delivery",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                    "Special Specification",
                    "Other Policies",
                    "Orders",
                  ]) ||
                  (type === "request" && [
                    "Main Data",
                    // "Delivery",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                    // "Special Specification",
                    "Other Policies",
                  ]) ||
                  (type === "history" && [
                    "Main Data",
                    "Delivery",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                    "Special Specification",
                    "Other Policies",
                    "Orders",
                    "Agreements",
                  ]) ||
                  (type === "scheduled" && [
                    "Main Data",
                    "Delivery",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                    "Other Policies",
                    "Special Specification",
                  ]) ||
                  (type === "response" && [
                    "Main Data",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                    "Other Policies",
                    "Suppliers Offers",
                  ]) ||
                  []
                }
              />
            </div>

            {option === "Main Data" && (
              <>
                <div className="row  px-0 mx-0">
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-xl-6 col-12 px-0">
                      <div
                        className={`${classes["single-item"]}  row px-0 mx-0 row-cols-2 align-items-center `}
                      >
                        <p
                          style={{ fontSize: "20px", width: "200px" }}
                          className="col"
                        >
                          Deal Code :
                        </p>
                        <span className="col d-flex flex-grow-1 justify-content-cenetr">
                          {data?.uuid}
                        </span>
                      </div>
                    </div>
                    {type !== "scheduled" &&
                      type !== "response" &&
                      type !== "request" &&
                      type !== "history" && (
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} row align-items-center px-0 mx-0 row-cols-2 `}
                          >
                            <p
                              style={{ width: "200px", fontSize: "20px" }}
                              className="col"
                            >
                              Time Left :
                            </p>
                            <span className="clock"></span>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-9 px-0">
                    {type !== "response" && type !== "request" && (
                      <div
                        className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                          >
                            <p style={{ width: "200px" }} className="col">
                              Deal Name :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                              {data.title_en}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                          >
                            <p style={{ width: "200px" }} className="col">
                              اسم العرض :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                              {data.title_ar}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Product Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Model:
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.model}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            اسم المنتج :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.name_ar}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Country of Origin :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.country_en}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Product Code :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.uuid}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            بلد المنشأ :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.country_ar}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.category.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Factory Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.factory_en}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Sub Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.subcategory.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            اسم المصنع :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.product.factory_ar}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Market Price :
                          </p>
                          <span className="col  justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.product.price}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Batch Number :
                          </p>
                          <span className="col  justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.product.patchNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Unit :
                          </p>
                          <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.unit}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Customer Type :
                          </p>
                          <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.customerType}
                          </span>
                        </div>
                      </div>
                    </div>

                    {type === "scheduled" && (
                      <div
                        className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes["single-item"]}>
                            <p>Starts At:</p>
                            <span>
                              {new Date(data?.startDate).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes["single-item"]}>
                            <p>Ends At:</p>
                            <span>
                              {new Date(data?.endDate).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {data?.expireDate && (
                      <div
                        className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes["single-item"]}>
                            <p>Expiry Date:</p>
                            <span>
                              {new Date(data?.expireDate).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {data.deposit && (
                      <div
                        className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} ${classes["green"]}`}
                          >
                            <p>Deposite:</p>
                            <span>{data.deposit} L.E.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 mb-lg-0 mb-3 px-0">
                    <div className={`${classes.info}`}>
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        loop={false}
                        loopFillGroupWithBlank={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper    align-items-center d-flex p-0 "
                      >
                        {data.product.productImgs.map((item, index) => (
                          <SwiperSlide key={index}>
                            <Img
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${item}`}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p>Description</p>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-xl-6 pe-xl-2 px-0">
                    <Section border={true} text={data.product.description_en} />
                  </div>
                  <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                    <Section border={true} text={data.product.description_ar} />
                  </div>
                </div>
                <div className="row px-0 mx-0 mb-3 ">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p className="mb-4">Areas</p>
                      {data.areas.length > 0 ? (
                        data.areas.map((item, index) => (
                          <span
                            onClick={() => {
                              item.district.length > 0 &&
                                setShowIndexArea(index);
                            }}
                            key={index}
                            className={`${classes["area"]} w-auto  d-inline`}
                          >
                            {item.city.city_en}
                          </span>
                        ))
                      ) : (
                        <span
                          // onClick={() => setShowIndexArea(index)}
                          // key={index}
                          className={`${classes["area"]} w-auto  d-inline`}
                        >
                          All Egypt
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {type === "request" && (
                  <div className="row px-0 mx-0 mb-3 ">
                    <div className="col-12 px-0 ">
                      <div className={classes.input}>
                        <p className="mb-4">Deal Quantites</p>
                        {data.quantities &&
                          data.quantities.map((item, index) => (
                            <span
                              key={index}
                              className={`${classes["area"]} w-auto  d-inline`}
                            >
                              {item}
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {option === "Delivery" && (
              <>
                {data.deliveryResponsibility === "Eshtarena" && (
                  <>
                    <TermsSections
                      title="Payment Terms"
                      terms={data.deliveryTerms}
                      border={true}
                    />
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-md-6 col-12 px-0">
                        <div className={classes["single-item"]}>
                          <p>Delivery Fees :</p>
                          <span>{data.deliveryFees} L.E.</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {data.deliveryResponsibility === "Supplier" && (
                  <TermsSections
                    title="Delivery Terms"
                    terms={data.supplierTerms}
                    border={true}
                  />
                )}
                {data.deliveryResponsibility ===
                  "Pick From supplier location" && (
                  <>
                    <div className="row  pt-0 px-0 mx-0">
                      <div className="col-12 px-0 pe-2 ">
                        <div
                          className={`${classes.input1}   row d-flex align-items-center`}
                        >
                          <p
                            style={{ fontSize: "20px" }}
                            className="w-auto  d-inline"
                          >
                            Recieve on Store
                          </p>
                        </div>
                      </div>
                    </div>
                    {data.branches.map((item, index) => (
                      <div
                        key={index}
                        className="row px-0 d-flex justify-content-center align-items-cenetr"
                      >
                        <div className={` ${"col-12"}`}>
                          <StoreLocationCard item={item} />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            {option === "Special Specification" && (
              <>
                <div className="row  pt-0 px-0 mx-0">
                  <div className="col-12 px-0 pe-2 ">
                    <div
                      className={`${classes.input1}   row d-flex align-items-center`}
                    >
                      <p
                        style={{ fontSize: "20px" }}
                        className="w-auto d-inline"
                      >
                        Offer Details
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-lg-4 col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Quantity</p>
                      <span className="d-block">{data.quantity}</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Unit</p>
                      <span className="d-block">{data.unit}</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Unit Price</p>
                      <span className="d-block">{data.price}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Special Specification</p>
                      <span className="d-block">
                        {data.specialSpecification}
                      </span>
                    </div>
                  </div>
                </div>

                {((data.color && data.color.length > 0) ||
                  (data.size && data.size.length > 0)) && (
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-12 px-0">
                      <div className={classes["single-item"]}>
                        <p>Colors & Sizes</p>
                      </div>
                    </div>
                  </div>
                )}
                {data.color && data.color.length > 0 ? (
                  data.color.map((item, index) => (
                    <div
                      key={index}
                      className={`row  d-flex px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div
                        style={{
                          background: `${item.title}`,
                          width: "5px",
                          borderRadius: "10px",
                          boxSizing: "content-box",
                          marginInlineEnd: "8px",
                        }}
                        className="w-auto d-inline"
                      ></div>
                      <div
                        style={{ minWidth: "200px" }}
                        className="w-auto d-inline  px-0 pt-3"
                      >
                        <div className={classes["single-item"]}>
                          <p className="text-black ">{item.title}</p>
                          <span className="d-block text-black ">
                            {item.quantity} {data.unit}
                          </span>
                        </div>
                      </div>
                      <div className="w-auto d-inline">
                        <Swiper
                          spaceBetween={10}
                          pagination={{
                            clickable: true,
                          }}
                          breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            1025: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                            1441: {
                              slidesPerView: 4,
                              spaceBetween: 10,
                            },
                          }}
                          loop={false}
                          loopFillGroupWithBlank={true}
                          navigation={true}
                          modules={[Pagination, Navigation]}
                          className="mySwiper    align-items-center d-flex p-0 "
                        >
                          {item.size.map((item1, index1) => (
                            <SwiperSlide
                              key={index1}
                              style={{ minWidth: "200px" }}
                              className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}
                            >
                              <div className="  px-0">
                                <div className={classes["single-item"]}>
                                  <p>{item.title}</p>
                                  <span className="d-block">
                                    {item.quantity} {data.unit}
                                  </span>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-auto d-inline">
                    <Swiper
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1025: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1441: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                      }}
                      loop={false}
                      loopFillGroupWithBlank={true}
                      navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper    align-items-center d-flex p-0 "
                    >
                      {data.size.map((item1, index1) => (
                        <SwiperSlide
                          key={index1}
                          style={{ minWidth: "200px" }}
                          className={` text-start px-0 pt-0 mt-0 me-3  w-auto px-0 pt-3`}
                        >
                          <div className="  px-0">
                            <div className={classes["single-item"]}>
                              <p>{item1.title}</p>
                              <span className="d-block">
                                {item1.quantity} {data.unit}
                              </span>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </>
            )}
            {option === "Supplier Payment Terms" && (
              <TermsSections
                title="Payment Terms & Conditions"
                terms={data.supplierPaymentTerms}
                border={true}
              />
            )}
            {option === "Other Policies" && (
              <TermsSections
                title="Other Policies"
                terms={data.otherPolices}
                border={true}
              />
            )}
            {option === "Customer Payment Terms" && (
              <TermsSections
                title="Customer Terms & Conditions"
                terms={data.customerPaymentTerms}
                border={true}
              />
            )}

            {option === "Agreements" && (
              <>
                {!purchasingDone && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 ">
                      <div className={classes.input}>
                        <UploadImageGroup2
                          type="supplier"
                          onChange={(e) => setDoc([e])}
                          errorMsg="Provide a File"
                          title={"Purshacing Order"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup2
                        noDeleteAll={true}
                        default={
                          (data.purchasingOrder &&
                            data.invoice && [
                              data.purchasingOrder,
                              data.invoice,
                            ]) ||
                          (data.purchasingOrder &&
                            !data.invoice && [data.purchasingOrder]) ||
                          (!data.purchasingOrder &&
                            data.invoice && [data.invoice])
                        }
                        imageType="invoice/po"
                        errorMsg="Provide a File"
                        title={"Invoice"}
                        subTitle="click on invoice to download"
                      />
                    </div>
                  </div>
                </div>

                <div className={`row mx-0 px-0  justify-content-end mb-pb-0  `}>
                  <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={handleToggleAgreement}
                        className={"w-auto d-inline px-5  ms-auto text-end"}
                        type="normal"
                        text={
                          !purchasingDone
                            ? "Stop Agreements"
                            : "Start Agreements"
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {option === "Orders" &&
              (loadingOrder ? (
                <LoadingTable />
              ) : (
                <>
                  <Table
                    type="DealOrder"
                    options={[
                      {
                        title: "Order ID",
                        icon: false,
                      },
                      {
                        title: "Customer ID",
                        icon: false,
                      },
                      {
                        title: "Customer Type",
                        icon: false,
                      },
                      {
                        title: "Customer Name",
                        icon: false,
                      },
                      {
                        title: "Customer Phone",
                        icon: false,
                      },
                      {
                        title: "Quantity",
                        icon: false,
                      },
                      {
                        title: "Status",
                        icon: false,
                      },
                    ]}
                    wholeData={wholeOrders}
                    data={dealOrders}
                    deliveryResponsibility={data.deliveryResponsibility}
                    customStyleIndex={[7]}
                    updateData={() => {
                      setPageOrder(1);
                      getDealOrders();
                    }}
                    filterOption={[]}
                    customStyleFn={[
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      (val) => {
                        return (
                          (val === "Delivered" && {
                            borderRadius: "10px",
                            background: "#E3FDE5",
                            color: "#2CD63D",
                            textAlign: "center",
                            fontWeight: "600",
                            width: "auto",
                            marginTop: "8px ",
                            padding: "8px",
                          }) ||
                          (val === "Shipping" && {
                            borderRadius: "10px",
                            color: "#7A7E00",
                            fontWeight: "600",
                            width: "auto",
                            marginTop: "8px",
                            padding: "8px",
                            background: "#FCFFAB",
                            textAlign: "center",
                          }) ||
                          (val === "Canceled" && {
                            borderRadius: "10px",
                            color: "#F2055C",
                            fontWeight: "600",
                            width: "auto",
                            marginTop: "8px",
                            padding: "8px",
                            background: "#F2055C69",
                            textAlign: "center",
                          }) ||
                          (val === "On going" && {
                            borderRadius: "10px",
                            color: "blue",
                            fontWeight: "600",
                            width: "auto",
                            marginTop: "8px",
                            padding: "8px",
                            background: "lightBlue",
                            textAlign: "center",
                          }) ||
                          (val === "Preparing" && {
                            borderRadius: "10px",
                            color: "black",
                            fontWeight: "600",
                            width: "auto",
                            marginTop: "8px",
                            padding: "8px",
                            background: "tla",
                            textAlign: "center",
                          })
                        );
                      },
                    ]}
                  />
                  {totalPagesOrder > 1 && (
                    <div
                      className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                    >
                      <div className="w-auto d-inline ms-auto">
                        <UsePagination
                          page={pageOrder}
                          onChangePage={(page) => {
                            setLoadingOrder(true);
                            setPageOrder(page);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {dealOrders.length !== 0 &&
                    (wholeOrders1.shipping !== 0 ||
                      wholeOrders1.delivered !== 0 ||
                      wholeOrders1.canceled !== 0 ||
                      wholeOrders1.onGoing !== 0 ||
                      wholeOrders1.preparing !== 0) && (
                      <div
                        className={`row  px-0 mx-0 mt-4 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-12 px-0">
                          <div dir="auto" className={classes["single-item"]}>
                            <p>Progress</p>
                            <div className="row justify-content-center pt-3 px-0 mx-0">
                              <div className="col-lg-2 col-6">
                                <PieChart
                                  data={[
                                    {
                                      title: "Shipping",
                                      value: wholeOrders1.shipping,
                                      color: "yellow",
                                    },
                                    {
                                      title: "delivered",
                                      value: wholeOrders1.delivered,
                                      color: "#2DA840",
                                    },
                                    {
                                      title: "Canceled",
                                      value: wholeOrders1.canceled,

                                      color: "red",
                                    },
                                    {
                                      title: "On Going",
                                      value: wholeOrders1.onGoing,
                                      color: "lightBlue",
                                    },
                                    {
                                      title: "preparing",
                                      value: wholeOrders1.preparing,
                                      color: "lightgray",
                                    },
                                  ]}
                                />
                              </div>
                              <div className="col-lg-6 mt-lg-0 mt-4  d-flex flex-column  justify-content-center ms-auto d-inline">
                                <div className="row px-0 mx-0">
                                  <div
                                    className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: "#2DA840",
                                        color: "#2DA840",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    ></Avatar>

                                    {/* <div className={`${classes["single-item-progess1"]}`} style={{ width: 30 }}></div> */}
                                  </div>
                                  <div
                                    className={`col-6  ${classes["single-item"]}`}
                                  >
                                    <p>Delivered</p>
                                  </div>
                                  <div
                                    className={`w-auto d-inline mx-auto  ${classes["single-item"]}`}
                                  >
                                    <p>{wholeOrders1.delivered}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div
                                    className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: "lightBlue",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    ></Avatar>

                                    {/* <div className={`${classes["single-item-progess1"]}`} style={{ width: 30 }}></div> */}
                                  </div>
                                  <div
                                    className={`col-6 ${classes["single-item"]}`}
                                  >
                                    <p>On Going</p>
                                  </div>
                                  <div
                                    className={`w-auto d-inline mx-auto  ${classes["single-item"]}`}
                                  >
                                    <p>{wholeOrders1.onGoing}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div
                                    className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: "lightgray",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    ></Avatar>

                                    {/* <div className={`${classes["single-item-progess1"]}`} style={{ width: 30 }}></div> */}
                                  </div>
                                  <div
                                    className={`col-6 ${classes["single-item"]}`}
                                  >
                                    <p>PreParing</p>
                                  </div>
                                  <div
                                    className={`w-auto d-inline mx-auto  ${classes["single-item"]}`}
                                  >
                                    <p>{wholeOrders1.preparing}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div
                                    className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: "yellow",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div
                                    className={`col-6 ${classes["single-item"]}`}
                                  >
                                    <p>Shipping</p>
                                  </div>
                                  <div
                                    className={`w-auto d-inline mx-auto text-center ${classes["single-item"]}`}
                                  >
                                    <p>{wholeOrders1.shipping}</p>
                                  </div>
                                </div>
                                <div className="row px-0 mx-0">
                                  <div
                                    className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: "red",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    ></Avatar>
                                  </div>
                                  <div
                                    className={`col-6 ${classes["single-item"]}`}
                                  >
                                    <p>Canceled</p>
                                  </div>
                                  <div
                                    className={`w-auto mx-auto d-inline text-center ${classes["single-item"]}`}
                                  >
                                    <p>{wholeOrders1.canceled}</p>
                                  </div>
                                </div>
                                {/* <div className="row px-0 mx-">Sold datas 600</div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              ))}

            {option === "Suppliers Offers" &&
              (loadingOffer ? (
                <LoadingTable />
              ) : (
                tableOptions.length > 0 &&
                quantites.length > 0 && (
                  <div className="">
                    <Table
                      type="Supplier Offer Response"
                      options={tableOptions}
                      wholeData={wholeOffers}
                      data={offers}
                      customStyleIndex={[]}
                      filterOption={[]}
                      customStyleFn={[false, false, false, false]}
                    />
                    {totalPagesOffer > 1 && (
                      <div
                        className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                      >
                        <div className="w-auto d-inline ms-auto">
                          <UsePagination
                            page={pageOffer}
                            onChangePage={(page) => {
                              setLoadingOffer(true);
                              setPageOffer(page);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )
              ))}
          </section>
        )}
      </section>
    </div>
  );
}
