import React, { useState } from "react";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import Cross from "../../components/Reusable/SVG/Cross";
import Edit from "../../components/Reusable/SVG/Edit";
import Eye2 from "../../components/Reusable/SVG/Eye2";
import FilterDots from "../../components/Reusable/SVG/FilterDots";
import classes from "./Manager.module.scss";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import ManagerHeader from "../../components/Reusable/Common/MemberCard/ManagerHeader";
import Img from "../../components/Reusable/Common/Img";
import { useNavigate, useParams } from "react-router-dom";
import { managerAccountsService } from "../../services/managerAccountsService";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import pdf from "../../assests/pdf.png";
import { managerService } from "../../services/manager";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
export default function ManagerDetails(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [option, setOption] = useState("Data");
  const { id } = useParams();
  const [data, setData] = useState({});
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [close, setClose] = useState(true);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [active, setActive] = useState("true");
  let getManagerData = async () => {
    let response =
      localStorage.getItem("side") === "Top Manager"
        ? await managerAccountsService.getSingleManagerData(
            props.type === "Marketing Manager"
              ? "marketing-manager"
              : "category-manager",
            id
          )
        : await managerService.getManagerData(
            props.type === "Marketing Manager"
              ? "marketing-manager"
              : "category-manager"
          );

    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setActive(
        props.type === "Marketing Manager"
          ? response.data.marketingManager.activated
          : response.data.categoryManager.activated
      );
      setData(
        props.type === "Marketing Manager"
          ? response.data.marketingManager
          : response.data.categoryManager
      );
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };
  let getManagerMedia = async () => {
    let response =
      localStorage.getItem("side") === "Top Manager"
        ? await managerAccountsService.getSingleManagerMedia(
            props.type === "Marketing Manager"
              ? "marketing-manager"
              : "category-manager",
            id
          )
        : await managerService.getManagerMedia(
            props.type === "Marketing Manager"
              ? "marketing-manager"
              : "category-manager"
          );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setMedia(
        props.type === "Marketing Manager"
          ? response.data.marketingManager
          : response.data.categoryManager
      );
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
  };

  let handleAcctivateManager = async () => {
    let response = await managerAccountsService.toggleManagerActivation(
      props.type === "Marketing Manager"
        ? "marketing-manager"
        : "category-manager",
      id
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setClose(false);
      setActive((prev) => !prev);
      setShowConfirmation(true);

      setTimeout(() => {
        setClose(true);
        setShowConfirmation(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
  };
  useEffect(() => {
    getManagerMedia();
    getManagerData();
  }, [id]);
  return (
    <div className={`w-100 ${classes["managers"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Manager ${
              active ? "activated" : "de-activated"
            } successfully`}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes["title"]}>
          <PageTitle
            onClick={() => {
              // setShowAddForm(true);
            }}
            text={`${props.type} Profile`}
            // icon={<PeopleActiveL />}
            // buttonText={`Add Supplier`}
            // buttonType="normal"
            // buttonIcon={<Plus />}
          />
        </div>
        {loading ? (
          <section className={`${classes["main-section"]}`}>
            <ManagerHeader loading={true} />
            {/* <div className="br"></div> */}
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <div className="row px-0 mx-0">
                <div className="w-auto d-inline">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "2rem", width: "150px" }}
                  />
                </div>
                <div className="w-auto d-inline">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "2rem", width: "150px" }}
                  />
                </div>
              </div>
            </div>

            <>
              {[0, 1, 2, 3].map((item, index) => (
                <div
                  key={index}
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-6 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "5rem", width: "250px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div dir="auto" className="col-6 px-0">
                    <div className={classes["single-item"]}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "5rem", width: "250px" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes["main-section"]}`}>
            <ManagerHeader
              type={props.type}
              profilePicture={data.pic}
              closeOption={close}
              data={{
                name: `${data.firstName} ${data.lastName}`,
                categories: data.categories,
              }}
              optionComponent={
                <>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      props.type === "Marketing Manager"
                        ? history(`/account/marketing-manager/${id}/edit`)
                        : history(`/account/category-manager/${id}/edit`);
                    }}
                    className={`row mx-0 ${
                      localStorage.getItem("side") === "Top Manager" && "mb-2"
                    } mx-0`}
                  >
                    <div className="w-auto d-inline">
                      <Edit color="#6E7980" />
                    </div>
                    &nbsp; Edit {props.type} Profile
                  </div>
                  {localStorage.getItem("side") === "Top Manager" && (
                    <div
                      onClick={handleAcctivateManager}
                      className="row mx-0 mx-0"
                    >
                      <div className="w-auto d-inline">
                        <Cross />
                      </div>
                      &nbsp;{active ? "Deactive" : "Activate"} {props.type}
                    </div>
                  )}
                </>
              }
            />
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={["Data", "Media"]}
              />
            </div>

            {option === "Data" && (
              <>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Job Description</p>
                      <span>{data.jobDescription}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-6 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Phone Number</p>
                      <span>{data.phone}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-6 px-0">
                    <div className={classes["single-item"]}>
                      <p>Email Address</p>
                      <span>{data.email}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-6 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Birthday</p>
                      <span>{data.birthDate}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-6 px-0">
                    <div className={classes["single-item"]}>
                      <p>National ID</p>
                      <span>{data.nationalId}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Address</p>
                      <span>{data.address}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {option === "Media" && (
              <div className={`${classes["company-profile--data"]}`}>
                <div className="row mx-0 px-0 mb-4">
                  {media.contract.length > 0 && (
                    <div className={classes["single-item"]}>
                      <p className="mb-3">Contract</p>
                      <span>
                        {media.contract.map((item, index) =>
                          item.includes(".pdf") ||
                          item.includes(".docx") ||
                          item.includes(".xlsx") ? (
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                                props.type === "Marketing Manager"
                                  ? "marketing-manager"
                                  : "category-manager"
                              }/${item}`}
                            >
                              <Img
                                noShow={true}
                                key={index}
                                src={pdf}
                                className="mb-2 bg-transparent me-2"
                              />
                            </a>
                          ) : (
                            <Img
                              style={{
                                background: "#91c8e4",
                              }}
                              key={index}
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                                props.type === "Marketing Manager"
                                  ? "marketing-manager"
                                  : "category-manager"
                              }/${item}`}
                              className="mb-2"
                            />
                          )
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <div className="row mx-0 px-0 mb-4">
                  {media.cv.length > 0 && (
                    <div className={classes["single-item"]}>
                      <p className="mb-3">CV</p>
                      <span className="mb-2">
                        {media.cv.map((item, index) =>
                          item.includes(".pdf") ||
                          item.includes(".docx") ||
                          item.includes(".xlsx") ? (
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                                props.type === "Marketing Manager"
                                  ? "marketing-manager"
                                  : "category-manager"
                              }/${item}`}
                            >
                              <Img
                                noShow={true}
                                key={index}
                                src={pdf}
                                className="mb-2 me-2 bg-transparent "
                              />
                            </a>
                          ) : (
                            <Img
                              style={{
                                background: "#91c8e4",
                              }}
                              key={index}
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                                props.type === "Marketing Manager"
                                  ? "marketing-manager"
                                  : "category-manager"
                              }/${item}`}
                              className="mb-2 me-2"
                            />
                          )
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <div className="row mx-0 px-0 mb-4">
                  {((media.more && media.more.length > 0) ||
                    (media.governmentRegistrationNumber &&
                      media.governmentRegistrationNumber.length > 0)) && (
                    <div className={classes["single-item"]}>
                      <p className="mb-3">More</p>
                      <span classname="mb-2">
                        {media.governmentRegistrationNumber &&
                          media.governmentRegistrationNumber.map(
                            (item, index) =>
                              item.includes(".pdf") ||
                              item.includes(".docx") ||
                              item.includes(".xlsx") ? (
                                <a
                                  rel="noreferrer"
                                  href={`${
                                    process.env.REACT_APP_IMAGE_BASE_URL
                                  }/${
                                    props.type === "Marketing Manager"
                                      ? "marketing-manager"
                                      : "category-manager"
                                  }/${item}`}
                                  target="_blank"
                                >
                                  <Img
                                    noShow={true}
                                    key={index}
                                    src={pdf}
                                    className="mb-2 bg-transparent me-2"
                                  />
                                </a>
                              ) : (
                                <Img
                                  style={{
                                    background: "#91c8e4",
                                  }}
                                  key={index}
                                  src={`${
                                    process.env.REACT_APP_IMAGE_BASE_URL
                                  }/${
                                    props.type === "Marketing Manager"
                                      ? "marketing-manager"
                                      : "category-manager"
                                  }/${item}`}
                                  className="mb-2 me-2"
                                />
                              )
                          )}
                        {media.more &&
                          media.more.map((item, index) =>
                            item.includes(".pdf") ||
                            item.includes(".docx") ||
                            item.includes(".xlsx") ? (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={`${
                                  process.env.REACT_APP_IMAGE_BASE_URL
                                }/${
                                  props.type === "Marketing Manager"
                                    ? "marketing-manager"
                                    : "category-manager"
                                }/${item}`}
                              >
                                <Img
                                  noShow={true}
                                  key={index}
                                  src={pdf}
                                  className="mb-2 me-2 bg-transparent"
                                />
                              </a>
                            ) : (
                              <Img
                                style={{
                                  background: "#91c8e4",
                                }}
                                key={index}
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${
                                  props.type === "Marketing Manager"
                                    ? "marketing-manager"
                                    : "category-manager"
                                }/${item}`}
                                className="mb-2 me-2"
                              />
                            )
                          )}
                      </span>
                    </div>
                  )}
                </div>
                {media.contract.length === 0 &&
                  media.cv.length === 0 &&
                  ((media.more && media.more.length === 0) ||
                    (media.governmentRegistrationNumber &&
                      media.governmentRegistrationNumber.length === 0)) && (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                        There is No Media Found
                      </div>
                    </div>
                  )}
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
