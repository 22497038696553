import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        background: "#340040",
        height: `100vh !important`,
        overflow: "hidden",
        // objectFit: "contain",
      }}
      className="blanking-loader-container justify-content-center d-flex"
    >
      <div
        style={{
          transform: `scale(${(window.innerHeight - 400) / 100})`,
          objectFit: "contain",
        }}
        className="text-center"
      >
        <svg
          className="blanking-loader"
          // width="200"
          // height="103"
          // viewBox="0 0 300 103"
          width="93"
          height="51"
          viewBox="0 0 93 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5838_1642)">
            <path
              d="M71.3308 8.69066C73.0929 8.69066 74.5214 7.26336 74.5214 5.50268C74.5214 3.74201 73.0929 2.3147 71.3308 2.3147C69.5686 2.3147 68.1401 3.74201 68.1401 5.50268C68.1401 7.26336 69.5686 8.69066 71.3308 8.69066Z"
              fill="white"
            />
            <path
              d="M61.6315 8.69066C63.3937 8.69066 64.8222 7.26336 64.8222 5.50268C64.8222 3.74201 63.3937 2.3147 61.6315 2.3147C59.8694 2.3147 58.4409 3.74201 58.4409 5.50268C58.4409 7.26336 59.8694 8.69066 61.6315 8.69066Z"
              fill="white"
            />
            <path
              d="M20.4318 11.7781C22.194 11.7781 23.6225 10.3508 23.6225 8.59008C23.6225 6.82941 22.194 5.4021 20.4318 5.4021C18.6697 5.4021 17.2412 6.82941 17.2412 8.59008C17.2412 10.3508 18.6697 11.7781 20.4318 11.7781Z"
              fill="white"
            />
            <path
              d="M81.4055 8.69066C83.1676 8.69066 84.5961 7.26336 84.5961 5.50268C84.5961 3.74201 83.1676 2.3147 81.4055 2.3147C79.6433 2.3147 78.2148 3.74201 78.2148 5.50268C78.2148 7.26336 79.6433 8.69066 81.4055 8.69066Z"
              fill="white"
            />
            <path
              d="M81.4055 50.8105C83.1676 50.8105 84.5961 49.3832 84.5961 47.6226C84.5961 45.8619 83.1676 44.4346 81.4055 44.4346C79.6433 44.4346 78.2148 45.8619 78.2148 47.6226C78.2148 49.3832 79.6433 50.8105 81.4055 50.8105Z"
              fill="white"
            />
            <path
              d="M79.41 13.4165C77.4183 13.0378 76.2503 14.6106 75.6199 16.3224C73.1835 22.9495 70.9558 29.6617 68.9099 36.4241C68.0746 39.187 67.0807 41.579 69.7105 42.4832C72.6691 43.5033 72.8663 41.5828 73.8989 38.5881C76.084 32.2507 78.3465 25.4574 80.5509 18.6603C81.568 15.5302 81.8039 13.8686 79.41 13.4126V13.4165Z"
              fill="white"
            />
            <path
              d="M69.6444 13.4165C67.6527 13.0378 66.4847 14.6106 65.8543 16.3224C63.4178 22.9495 61.1902 29.6617 59.1443 36.4241C58.309 39.187 57.315 41.579 59.9449 42.4832C62.9035 43.5033 63.1007 41.5828 64.1333 38.5881C66.3184 32.2507 68.5808 25.4574 70.7853 18.6603C71.8024 15.5302 72.0383 13.8686 69.6444 13.4126V13.4165Z"
              fill="white"
            />
            <path
              d="M59.8792 13.4165C57.8875 13.0378 56.7196 14.6106 56.0892 16.3224C53.6527 22.9495 51.4251 29.6617 49.3792 36.4241C48.5438 39.187 47.5499 41.579 50.1797 42.4832C53.1383 43.5033 53.3356 41.5828 54.3682 38.5881C56.5533 32.2507 58.8157 25.4574 61.0201 18.6603C62.0373 15.5302 62.2732 13.8686 59.8792 13.4126V13.4165Z"
              fill="white"
            />
            <path
              d="M48.1648 16.4153C46.378 16.0752 45.33 17.4895 44.7653 19.0236C42.5764 24.9745 40.5769 30.9989 38.7399 37.0696C37.9896 39.5504 37.1001 41.695 39.4553 42.5104C42.1123 43.4262 42.2902 41.7028 43.2145 39.0133C45.1753 33.3251 47.2057 27.2235 49.1858 21.1258C50.0985 18.3165 50.3112 16.8249 48.1609 16.4153H48.1648Z"
              fill="white"
            />
            <path
              d="M91.1283 2.39206C89.1365 1.87039 87.9686 4.03822 87.3382 6.39153C84.9017 15.5111 82.6741 24.7466 80.6282 34.0439C79.7928 37.8424 78.7989 41.1348 81.4288 42.3829C84.3873 43.7895 84.5846 41.1463 85.6172 37.0271C87.8023 28.3094 90.0647 18.9618 92.2692 9.61429C93.2863 5.30569 93.5222 3.02193 91.1283 2.39593V2.39206Z"
              fill="white"
            />
            <path
              d="M37.0306 25.1986C35.6577 24.7503 34.7218 26.0912 34.161 27.5905C31.9759 33.3869 29.9339 39.2721 28.0157 45.2075C27.2306 47.6343 26.3643 49.7171 28.1472 50.6754C30.1582 51.7535 30.4328 50.0455 31.3687 47.4217C33.3566 41.8727 35.4295 35.9179 37.4638 29.9593C38.3997 27.2118 38.6859 25.7396 37.0306 25.1986Z"
              fill="white"
            />
            <path
              d="M29.5354 16.4153C27.7486 16.0752 26.7006 17.4895 26.1359 19.0236C23.947 24.9745 21.9475 30.9989 20.1105 37.0696C19.3602 39.5504 18.4707 41.695 20.826 42.5104C23.4829 43.4262 23.6608 41.7028 24.5851 39.0133C26.5459 33.3251 28.5763 27.2235 30.5564 21.1258C31.4691 18.3165 31.6818 16.8249 29.5315 16.4153H29.5354Z"
              fill="white"
            />
            <path
              d="M22.7171 44.2917C22.0094 44.2917 21.4331 44.8675 21.4331 45.5747C21.4331 46.2818 22.0094 46.8576 22.7171 46.8576C23.4248 46.8576 24.0011 46.2818 24.0011 45.5747C24.0011 44.8675 23.4248 44.2917 22.7171 44.2917Z"
              fill="white"
            />
            <path
              d="M18.6253 44.2917C17.9176 44.2917 17.3413 44.8675 17.3413 45.5747C17.3413 46.2818 17.9176 46.8576 18.6253 46.8576C19.333 46.8576 19.9093 46.2818 19.9093 45.5747C19.9093 44.8675 19.333 44.2917 18.6253 44.2917Z"
              fill="white"
            />
            <path
              d="M18.989 16.4153C17.2023 16.0752 16.1542 17.4895 15.5895 19.0236C13.4006 24.9745 11.4011 30.9989 9.56409 37.0696C8.81381 39.5504 7.92431 41.695 10.2796 42.5104C12.9365 43.4262 13.1144 41.7028 14.0387 39.0133C15.9995 33.3251 18.0299 27.2235 20.01 21.1258C20.9227 18.3165 21.1354 16.8249 18.9851 16.4153H18.989Z"
              fill="white"
            />
            <path
              d="M11.3974 2.39206C9.40563 1.87039 8.23766 4.03822 7.60727 6.39153C5.17466 15.5072 2.94702 24.7427 0.901155 34.0439C0.0657906 37.8424 -0.928138 41.1348 1.70171 42.3829C4.66029 43.7895 4.85753 41.1463 5.89013 37.0271C8.07523 28.3094 10.3377 18.9618 12.5421 9.61429C13.5592 5.30569 13.7952 3.02193 11.4012 2.39593L11.3974 2.39206Z"
              fill="white"
            />
            <path
              d="M49.2441 10.5686C49.2441 11.2757 49.8204 11.8515 50.5281 11.8515C51.2359 11.8515 51.8121 11.2757 51.8121 10.5686C51.8121 9.86141 51.2359 9.28564 50.5281 9.28564C49.8204 9.28564 49.2441 9.86141 49.2441 10.5686Z"
              fill="white"
            />
            <path
              d="M45.1523 10.5686C45.1523 11.2757 45.7286 11.8515 46.4363 11.8515C47.1441 11.8515 47.7203 11.2757 47.7203 10.5686C47.7203 9.86141 47.1441 9.28564 46.4363 9.28564C45.7286 9.28564 45.1523 9.86141 45.1523 10.5686Z"
              fill="white"
            />
            <path
              d="M45.6008 4.05739C46.1693 3.89123 46.6914 3.71347 47.1439 3.53958C47.3566 3.45843 47.55 3.37729 47.724 3.3L53.413 0.935097L53.5677 0.869406C54.3025 0.560268 54.7512 0.278179 54.7047 0.119746C54.6274 -0.146885 53.1346 0.0540547 51.371 0.57186C50.9301 0.69938 50.5125 0.838492 50.1373 0.973739H50.1335C49.6926 1.13604 49.3445 1.29061 49.008 1.44131C47.8942 1.94752 45.3378 2.99859 43.9958 3.52413C43.6439 3.66324 43.2958 3.79849 43.029 3.926C42.5185 4.16945 42.2284 4.37812 42.2671 4.50564C42.3445 4.77227 43.8411 4.57133 45.6047 4.05352L45.6008 4.05739Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_5838_1642">
              <rect width="93" height="51" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Loading;
