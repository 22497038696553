import React, { useEffect, useState } from "react";
import "./index.css";
import four from "../../assests/4.png";
import { useSelector } from "react-redux";
export default function Section2() {
  const [width, setWidth] = useState(1400);
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <div dir={language === "ar" && "rtl"} style={{ background: "#F0F0F5" }}>
      <div className="container">
        <div
          style={{
            height: `${
              window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
            }`,
            paddingBottom: `${window.innerWidth < 1200 && "100px"}`,
          }}
          className="row   d-flex align-items-center   px-0 mx-0 "
        >
          <div
            style={{
              height: `${
                window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
              }`,
            }}
            className={`col-xl-8  order-xl-1 order-2 d-flex flex-column ${
              language === "en" ? `text-xl-start` : `text-xl-end`
            } text-center  pt-xl-0 justify-content-xl-center position-relative `}
          >
            <h1
              className={`main-title  ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center`}
            >
              {language === "en"
                ? `What is original deal?`
                : `ما هي الصفقات الأصلية ؟
`}
            </h1>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center`}
            >
              {language === "en"
                ? `They are pre-prepared group purchase offers from Eshtarena that
              specify the price, quantity and supplier for a specific product
              after receiving price offers from all verified suppliers for this
              product and choosing the best offer to be presented it to you`
                : `هي عروض شراء جماعي محضرة مسبقاً من اشترينا محدده السعر و الكمية و المورد على منتج معين بعد تلقى عروض الاسعار من كل الموردين الموثقين لهذا المنتج و إختيار أفضل عرض و تقديمه لكم
`}
            </p>
            <ul className="px-0 mx-0">
              <div
                className={`row px-0  row-cols-2 ${
                  language === "en"
                    ? "text-start justify-content-start"
                    : "text-end justify-content-end"
                } mx-0 align-items-center`}
              >
                <div
                  className={`w-auto col d-inline ${
                    language === "en" ? "text-end" : "text-start"
                  } px-0 mx-0 d-xl-inline d-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className=" col  d-flex flex-grow-1 d-inline mx-xl-0 mx-auto">
                  <li
                    className={`${
                      language === "en" ? "text-xl-start" : "text-xl-end"
                    } text-center`}
                  >
                    {language === "en"
                      ? `The offer ends when the specified quantity or deal time ends`
                      : `ينتهي العرض بإنتهاء الكمية المحدده او وقت الصفقه
`}
                  </li>
                </div>
              </div>
              <div
                className={`row px-0  row-cols-2 ${
                  language === "en"
                    ? "text-start justify-content-start"
                    : "text-end justify-content-end"
                } mx-0 align-items-center`}
              >
                <div
                  className={`w-auto col d-inline ${
                    language === "en" ? "text-end" : "text-start"
                  } px-0 mx-0 d-xl-inline d-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className=" col  d-flex flex-grow-1 d-inline mx-xl-0 mx-auto">
                  <li
                    className={`${
                      language === "en" ? "text-xl-start" : "text-xl-end"
                    } text-center`}
                  >
                    {language === "en"
                      ? `You can buy any quantity you want and get the group offer
                    price automatically at the time of purchase`
                      : `يمكنك شراء أي كمية تريدها و تحصل على سعر العرض الجماعي تلقائياً وقت الشراء
`}
                  </li>
                </div>
              </div>
              <div
                className={`row px-0  row-cols-2 ${
                  language === "en"
                    ? "text-start justify-content-start"
                    : "text-end justify-content-end"
                } mx-0 align-items-center`}
              >
                <div
                  className={`w-auto col d-inline ${
                    language === "en" ? "text-end" : "text-start"
                  } px-0 mx-0 d-xl-inline d-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className=" col  d-flex flex-grow-1 d-inline mx-xl-0 mx-auto">
                  <li
                    className={`${
                      language === "en" ? "text-xl-start" : "text-xl-start"
                    } text-center`}
                  >
                    {language === "en"
                      ? `Eshtarena guarantees you the best price for the product in
                    the market throughout the offer`
                      : `اشترينا يضمن لكم افضل سعر للمنتج في السوق طول فترة العرض
`}
                  </li>
                </div>
              </div>
              <div
                className={`row px-0  row-cols-2 ${
                  language === "en"
                    ? "text-start justify-content-start"
                    : "text"
                } mx-0 align-items-center`}
              >
                <div
                  className={`w-auto col d-inline ${
                    language === "en" ? "text-end" : "text-start"
                  } px-0 mx-0 d-xl-inline d-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className=" col  d-flex flex-grow-1 d-inline mx-xl-0 mx-auto">
                  <li
                    className={`${
                      language === "en" ? "text-xl-start" : "text-xl-end"
                    } text-center`}
                  >
                    {language === "en"
                      ? `You can view the page of the supplier, view the return
                    policies and after-sales services`
                      : `يمكنك الإطلاع على صفحة المورد المقدم للعرض و الإطلاع على سياسات الاسترجاع و خدمات ما بعد البيع
`}
                  </li>
                </div>
              </div>
            </ul>
          </div>
          <div
            // style={{ height: `${window.innerWidth > 1283 ? window.innerHeight : window.innerHeight * 0.5}px` }}
            className={`col-xl-4  order-xl-2 order-1 pt-xl-0 pt-5 ${
              language === "en" ? "text-xl-start" : "text-xl-end"
            } text-center justify-content-center align-items-center d-flex mb-xl-0 mb-5`}
          >
            <img
              alt="image_feature"
              style={{
                width: `100%`,
                objectFit: "contain",
                height: "100%",
                maxwidth: `${window.innerWidth}px`,
                maxHeight: `${window.innerHeight - 200}px`,
                // height: `${window.innerWidth > 1283 ? window.innerHeight - 200 : window.innerHeight * 0.4}px`,
              }}
              src={four}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
