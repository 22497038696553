import React, { useEffect, useState } from "react";
import p1 from "../../assests/3f0d6784aaf304bbf92e0a669634a63c.png";
import p2 from "../../assests/0426e86ee73b073c3b4ea7cc10006a45.png";
import p3 from "../../assests/035ad90a96f63f2dbffaa45dbcf49046.png";
import p4 from "../../assests/Rectangle.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";
import { useSelector } from "react-redux";
export default function Section5() {
  const [width, setWidth] = useState(1400);
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <div>
      <div className="container">
        <div
          style={{
            height: `${window.innerHeight - 90}px`,
          }}
          className={`row  px-0 mx-0  align-items-xl-center align-items-center  d-flex`}
        >
          <h1 className="title-partners text-cenetr mt-5 mb-0 pb-0">
            {language === "en" ? `Our Partners` : `شركاء و موردين إشترينا`}
          </h1>
          <div className="row  mx-auto justify-content-cenetr ">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={50}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="text-center w-auto mx-auto"
            >
              {[p1, p2, p3, p4, p1, p2, p3, p4].map((item1, index1) => (
                <SwiperSlide
                  key={index1}
                  style={{
                    cursor: "pointer",
                  }}
                  className={`text-xl-start text-center px-0 pt-0 mt-0 mx-xl-5 w-xl-auto  px-0 `}
                >
                  <img
                    style={{
                      width: "fit-content",
                      minHeight: "150px",
                      minWidth: "150px",
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                    src={item1}
                    alt="feature_image"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="row mx-0 px-0  mb-0 pb-0   ">
            <button className=" p-0 m-0  d-flex align-items-center justify-content-center  text-center  row btn-partners mx-auto   ">
              <a
                target="_blank"
                style={{ color: "inherit", textDecoration: "inherit" }}
                href="https://mail.google.com/mail/?view=cm&fs=1&to=Vendors@eshtarena.com"
              >
                {language === "en" ? (
                  <>
                    Join Eshtarena <br /> partners and suppliers
                  </>
                ) : (
                  <>
                    إنضم لشركاء <br />و مورين إشترينا الأن
                  </>
                )}{" "}
              </a>
            </button>
            <div className="row mx-0 px-0 mt-3">
              <div
                style={{ wordBreak: "break-all" }}
                className="w-auto  d-inline mx-auto text-center   mb-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M20 4.11426H4C2.9 4.11426 2.01 5.01426 2.01 6.11426L2 18.1143C2 19.2143 2.9 20.1143 4 20.1143H20C21.1 20.1143 22 19.2143 22 18.1143V6.11426C22 5.01426 21.1 4.11426 20 4.11426ZM20 8.11426L12 13.1143L4 8.11426V6.11426L12 11.1143L20 6.11426V8.11426Z"
                    fill="#340040"
                  />
                </svg>
                Vendors@eshtarena.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
