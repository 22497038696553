import axios from "axios";
import React, { useEffect, useState } from "react";
import SuccessPopUp from "./SuccessPopUp";
import Modal from "../Reusable/UI/Modal/Modal";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import FailPop from "../Reusable/Common/AddMemberCard/FailPop";
import { useSelector } from "react-redux";

export default function Section6() {
  const { language } = useSelector((state) => state.language);
  const [showError, setShowError] = useState(false);
  const [width, setWidth] = useState(1400);
  const [serverError, setServerError] = useState(false);
  const [servererrorMsg1, setServererrorMsg1] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [change, setChange] = useState(false);
  const [data, setData] = useState({
    name: null,
    phone: null,
    email: null,
    message: null,
  });
  const [dataErr, setDataErr] = useState({
    name: false,
    phone: false,
    email: false,
    message: false,
  });

  const handleChange = (e) => {
    if (e.target.value !== "")
      setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
    else setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    if (e.target.name === "phone")
      if (
        /^01[0125][0-9]{8}$/.test(e.target.value) ||
        /^01[0125][0-9]{8}$/.test(e.target.value)
      ) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
      } else {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }

    if (e.target.name === "email")
      if (
        !e.target.value.match(
          /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
        )
      ) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      } else {
        setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
      }
  };

  useEffect(() => {
    if (serverError)
      setTimeout(() => {
        setServerError(false);
      }, 2000);
  }, [serverError]);
  let handleSendMessage = async () => {
    let response;
    if (dataErr.name && dataErr.email && dataErr.message && dataErr.phone) {
      try {
        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_BASE_URL}/contact-us`,

          data,
        };
        response = await axios(config);
        let Valid = false;
        Valid = serverErrorCatcher(
          response,
          setServerError,
          setServererrorMsg1
        );
        if (Valid === true) {
          setData({ name: "", phone: "", email: "", message: "" });
          setDataErr({
            name: false,
            phone: false,
            email: false,
            message: false,
          });
          setShowConfirmation(true);
          setTimeout(() => {
            setShowConfirmation(false);
          }, 2000);
          setChange(false);
          setShowError(false);
        }
      } catch (e) {
        serverErrorCatcher(e, setServerError, setServererrorMsg1);
        setChange(false);
      }
    }
    // setData({ name: "", phone: "", email: "", message: "" });
    setChange(false);
  };
  return (
    <div dir={language === "ar" && "rtl"}>
      {serverError && (
        <Modal
          style={{ top: "150px", padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={servererrorMsg1} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ top: "150px", padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopUp
            message1={
              language === "en"
                ? "Your Message Sent Successfully"
                : "تم إرسال رسالتك بنجاح"
            }
          />
        </Modal>
      )}
      <div className="container">
        <div
          style={{
            height: `${window.innerWidth > 1283 ? window.innerHeight : "auto"}`,
          }}
          className="row  px-0 mx-0 align-items-center d-flex"
        >
          <h1 className="title-partners text-cenetr pb-5">
            {language === "en"
              ? `Contact us`
              : `تواصل معنا
`}
          </h1>
          <div className="row px-0 mx-0">
            <div className="col-xl-6  justify-content-center align-items-center mt-xl-0 mt-5 pt-xl-0 pt-4  d-flex order-xl-1 order-2">
              <ul style={{ listStyle: "none" }} className="px-0">
                {/* <li className="text-start mb-3 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M12 11.6143C11.337 11.6143 10.7011 11.3509 10.2322 10.882C9.76339 10.4132 9.5 9.7773 9.5 9.11426C9.5 8.45122 9.76339 7.81533 10.2322 7.34649C10.7011 6.87765 11.337 6.61426 12 6.61426C12.663 6.61426 13.2989 6.87765 13.7678 7.34649C14.2366 7.81533 14.5 8.45122 14.5 9.11426C14.5 9.44256 14.4353 9.76765 14.3097 10.071C14.1841 10.3743 13.9999 10.6499 13.7678 10.882C13.5356 11.1142 13.26 11.2983 12.9567 11.424C12.6534 11.5496 12.3283 11.6143 12 11.6143ZM12 2.11426C10.1435 2.11426 8.36301 2.85176 7.05025 4.16451C5.7375 5.47726 5 7.25774 5 9.11426C5 14.3643 12 22.1143 12 22.1143C12 22.1143 19 14.3643 19 9.11426C19 7.25774 18.2625 5.47726 16.9497 4.16451C15.637 2.85176 13.8565 2.11426 12 2.11426Z"
                      fill="#340040"
                    />
                  </svg>
                  &nbsp;Location desc
                </li> */}
                {/* <li className="text-start mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M16.5569 13.0202L16.1019 13.4732C16.1019 13.4732 15.0189 14.5492 12.0639 11.6112C9.10886 8.67324 10.1919 7.59724 10.1919 7.59724L10.4779 7.31124C11.1849 6.60924 11.2519 5.48124 10.6349 4.65724L9.37486 2.97424C8.61086 1.95424 7.13586 1.81924 6.26086 2.68924L4.69086 4.24924C4.25786 4.68124 3.96786 5.23924 4.00286 5.85924C4.09286 7.44624 4.81086 10.8592 8.81486 14.8412C13.0619 19.0632 17.0469 19.2312 18.6759 19.0792C19.1919 19.0312 19.6399 18.7692 20.0009 18.4092L21.4209 16.9972C22.3809 16.0442 22.1109 14.4092 20.8829 13.7422L18.9729 12.7032C18.1669 12.2652 17.1869 12.3942 16.5569 13.0202Z"
                      fill="#340040"
                    />
                  </svg>
                  &nbsp;279-87946312
                </li> */}
                <li
                  style={{ wordBreak: "break-all" }}
                  className="text-start  mb-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M20 4.11426H4C2.9 4.11426 2.01 5.01426 2.01 6.11426L2 18.1143C2 19.2143 2.9 20.1143 4 20.1143H20C21.1 20.1143 22 19.2143 22 18.1143V6.11426C22 5.01426 21.1 4.11426 20 4.11426ZM20 8.11426L12 13.1143L4 8.11426V6.11426L12 11.1143L20 6.11426V8.11426Z"
                      fill="#340040"
                    />
                  </svg>
                  &nbsp; customerservice@eshtarena.com
                </li>
              </ul>
            </div>
            <div className="col-xl-6 col-md-8 mx-xl-0 mx-md-auto order-xl-2 order-1">
              {change ? (
                <div
                  style={{
                    height: "300px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="form-container  d-flex "
                >
                  <div className="text-center">
                    <span className="spinner-border text-white spinner-border-sm"></span>
                    <p className="text-white">Sending</p>
                  </div>
                </div>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSendMessage();
                  }}
                  className="form-container "
                >
                  <div className="row ">
                    <div className="col-xl-6 col-12 ">
                      <label>{language === "en" ? "Name" : "الأسم"}</label>
                      <input
                        className="px-2 mb-1"
                        placeholder={language === "en" ? "Name" : "الأسم"}
                        name="name"
                        defaultValue={data.name}
                        onChange={(e) => {
                          setData((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }));
                          handleChange(e);
                        }}
                      />
                      {showError && !dataErr.name && (
                        <span className="errorMsg1 text-start  ">
                          {language === "en"
                            ? "Prvide a Name"
                            : "من فضلك سجل الأسم"}
                        </span>
                      )}
                    </div>
                    <div className="col-xl-6 col-12">
                      <label>
                        {language === "en" ? "Phone" : "رقم الهاتف"}
                      </label>
                      <input
                        className="px-2 mb-1"
                        placeholder={language === "en" ? "Phone" : "رقم الهاتف"}
                        name="phone"
                        defaultValue={data.phone}
                        onChange={(e) => {
                          setData((prev) => ({
                            ...prev,
                            phone: e.target.value,
                          }));
                          handleChange(e);
                        }}
                      />
                      {showError && !dataErr.phone && (
                        <span className="errorMsg1 text-start  ">
                          {data.phone
                            ? language === "en"
                              ? "Provide a Vaild Phone Number"
                              : "من فضلك سجل رقم هاتف صحيح"
                            : language === "en"
                            ? "Prvide a Phone Number"
                            : "من فضلك سجل رقم هاتف"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label>
                      {language === "en" ? "Email" : "البريد الإلكتروني"}
                    </label>
                    <input
                      className="px-2 mb-1"
                      placeholder={
                        language === "en" ? "Email" : "البريد الإلكتروني"
                      }
                      defaultValue={data.email}
                      name="email"
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, email: e.target.value }));
                        handleChange(e);
                      }}
                    />
                    {showError && !dataErr.email && (
                      <span className="errorMsg1 text-start  ">
                        {data.email
                          ? language === "en"
                            ? "Provide a Vaild Email"
                            : "من فضلك سجل بريد الإلكتروني صحيح"
                          : language === "en"
                          ? "Prvide an Email"
                          : "من فضلك سجل بريد إلكتروني"}
                      </span>
                    )}
                  </div>
                  <label>{language === "en" ? "Message" : "الرسالة"}</label>
                  <textarea
                    className="px-2 pt-2 mb-1"
                    placeholder={language === "en" ? "Message" : "الرسالة"}
                    name="message"
                    defaultValue={data.message}
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, message: e.target.value }));
                      handleChange(e);
                    }}
                  />
                  {showError && !dataErr.message && (
                    <span className="errorMsg1 text-start  ">
                      {language === "en"
                        ? "Prvide a Messasge"
                        : "من فضلك سجل الرسالة"}
                    </span>
                  )}

                  <div
                    className={`row text-center ${
                      language === "en" ? `text-xl-end` : "text-xl-start"
                    } justify-content-center d-flex`}
                  >
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setChange(true);
                          setShowError(true);
                          handleSendMessage();
                        }}
                        className="form-btn "
                      >
                        {language === "en" ? "Send Message" : "إرسال"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
