import { useDispatch } from "react-redux";
import classes from "./ColdDeals.module.scss";
import { useParams } from "react-router-dom";
import { Avatar, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Img from "../../components/Reusable/Common/Img";
import { Pagination, Navigation } from "swiper/modules";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import Button from "../../components/Reusable/UI/Button/Button";
import { CircularProgressbar } from "react-circular-progressbar";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import DealsAvtiveL from "../../components/Reusable/SVG/DealsAvtiveL";
import Section from "../../components/Reusable/Common/Section/Section";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import { coldDealsSupplierService } from "../../services/ColdDealsSuppliers";
import { coldDealsEshtreanaService } from "../../services/ColdDealsEshtreana";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import { originalDealsEshtreanaService } from "../../services/OriginalDealsEshtreana";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import TermsSections from "../../components/Reusable/Common/TermsSections/TermsSections";
import UploadImageGroup2 from "../../components/Reusable/UI/InputGroup/UploadImageGroup2";
import ShowAreaDistrict from "../../components/Reusable/Common/AddMemberCard/ShowAreaDistrict";
import { errorActions } from "../../store/reducers/errorSlice";
export default function SupplierColdDealsDetails() {
  //----------------------Declarations---------------------------------
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const [doc, setDoc] = useState([]);
  const [data, setData] = useState(null);
  const [price, setPrice] = useState(null);
  const [loading1, setLoading1] = useState(true);
  const [option, setOption] = useState("Main Data");
  const [ValidPrice, setValidPrice] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [showDistrict, setShowDistrict] = useState(false);
  const [showIndexArea, setShowIndexArea] = useState(null);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationAgreement, setShowConfirmationAgreement] =
    useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  //----------------------Functions---------------------------------
  let getDealDetails = async () => {
    let response = await coldDealsEshtreanaService.getDealDetails(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setData(response.data.deal);
      setLoading1(false);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
      setLoading1(false);
    }
  };
  //TODO:helper
  function getTimeRemaining(endtime) {
    const total =
      Date.parse(endtime) + 1 * 22 * 60 * 60 * 1000 - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }
  let timeinterval;
  //TODO:helper
  function initializeClock(endtime) {
    const clock = document.getElementsByClassName(`clock`);
    timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      clock[0].innerHTML =
        t.total > 0
          ? (t.days > 0 ? t.days : 0) +
            "D: " +
            (t.hours > 0 ? t.hours : 0) +
            "H: " +
            (t.minutes > 0 ? t.minutes : 0) +
            "M: " +
            (t.seconds > 0 ? t.seconds : 0) +
            "S "
          : "Finished";
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  }
  let sendPO = async () => {
    let fd = new FormData();
    fd.append("doc", doc[0], doc[0].name);
    let response = await originalDealsEshtreanaService.sendPoInvoice(id, fd);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setDoc([]);
      setData((prev) => ({
        ...prev,
        purchasingOrder: doc[0],
      }));
      setShowConfirmationAgreement(true);
      setTimeout(() => {
        setShowConfirmationAgreement(false);
        setLoading1(false);
      }, 2000);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading1(false);
  };
  let handleAddingPrice = (e) => {
    setPrice(e.target.value);
    if (
      isNaN(e.target.value) ||
      parseFloat(e.target.value) <= 0 ||
      e.target.value.startsWith("-") ||
      e.target.value === "" ||
      e.target.value.startsWith("+")
    )
      setValidPrice(false);
    else setValidPrice(true);
  };
  let joinDeal = async () => {
    if (ValidPrice || !data.joined) {
      let response = data.joined
        ? await coldDealsSupplierService.joinDeal(id, { price })
        : await coldDealsSupplierService.joinDeal(id);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
      if (Valid === true) {
        clearInterval(timeinterval);

        setShowConfirmation(true);
        setData(null);
        setLoading1(true);
        getDealDetails();
        setPrice("");
        setTimeout(() => {
          setShowConfirmation(false);
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
      }
    }
    setLoadingButton(false);
  };

  //----------------------Use Effects---------------------------------
  useEffect(() => {
    showIndexArea !== null && setShowDistrict(true);
  }, [showIndexArea]);
  useEffect(() => {
    if (type === "onGoing") {
      if (data) {
        if (
          option === "Your Offer" ||
          option === "Current Offer" ||
          option === "Main Data"
        )
          initializeClock(new Date(data.mainData.endDate));
        else {
          clearInterval(timeinterval);
        }
      }

      return () => {
        clearInterval(timeinterval);
      };
    }
  }, [data?.mainData?.endDate, option]);
  useEffect(() => {
    getDealDetails();
  }, [type]);
  useEffect(() => {
    serverError &&
      setTimeout(() => {
        setServerError(false);
      }, 2000);
  }, [serverError]);
  useEffect(() => {
    if (doc.length > 0) {
      setLoading1(true);
      sendPO();
    }
  }, [doc]);

  return (
    <div className={`w-100 ${classes["cold-deals"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {(showConfirmation || showConfirmationAgreement) && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            showConfirmationAgreement
              ? setShowConfirmationAgreement(false)
              : setShowConfirmation(false);
          }}
        >
          <SuccessPopup
            setShowForm={() => {
              showConfirmationAgreement
                ? setShowConfirmationAgreement(false)
                : setShowConfirmation(false);
            }}
            message1={
              showConfirmationAgreement
                ? "Your Agreement File Sent Successfully"
                : "You Joined The Deal Successfully"
            }
          />
        </Modal>
      )}

      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showDistrict && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowDistrict(false);
            setShowIndexArea(null);
          }}
        >
          <ShowAreaDistrict
            setShowForm={() => {
              setShowDistrict(false);
              setShowIndexArea(null);
            }}
            area={data.areas[showIndexArea]}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes["title"]}>
          <PageTitle text="Deal Details" icon={<DealsAvtiveL />} />
        </div>
        {loading1 ? (
          <div className={classes["main-section"]}>
            <div className={`row px-0 mx-0  mb-3  ${classes.options} mb-0`}>
              <div className="row px-0 mx-0 mb-2">
                {[0, 1, 2, 3].map((index) => (
                  <div key={index} className="col-xl-2 col-lg-4">
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "2rem", width: "100%" }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="row px-0 mx-0">
              <div className="col-lg-8 order-lg-1 order-2 px-0 mx-0">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <div key={index} className={`row mx-0 px-0 ${classes.info}`}>
                    <h1 className="w-auto d-inline px-0">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "250px" }}
                      />
                    </h1>
                    <h1 className="w-auto d-inline px-0 ms-auto">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "250px" }}
                      />
                    </h1>
                  </div>
                ))}
              </div>
              <div className="col-lg-4 justify-content-center text-center order-lg-2 order-1 p-5 pt-0 mx-0">
                <div
                  className={`${classes.info} text-center  d-flex justify-content-center`}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "223px", width: "284px" }}
                  />
                </div>
              </div>
            </div>
            <>
              {[0, 1, 2].map((item, index) => (
                <div key={index} className={`row w-100 px-0 mx-0 mt-2 `}>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto">
                      <p style={{ fontSize: "2rem", width: "250px" }}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "90%" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "90%" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
        ) : (
          <section className={`${classes["main-section"]} mb-5`}>
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={
                  (type === "onGoing" && [
                    "Main Data",
                    "Delivery Terms",
                    "Terms and Condition",
                    data.yourOffer ? "Your Offer" : "Current Offer",
                  ]) ||
                  (type === "history" && [
                    "Main Data",
                    "Delivery Terms",
                    "Terms and Condition",
                    "Your Offer",
                    "Agreements",
                  ]) ||
                  []
                }
              />
            </div>
            {option === "Main Data" && (
              <>
                <div className="row  px-0 mx-0">
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-xl-6 col-12 px-0">
                      <div
                        className={`${classes["single-item"]}  row px-0 mx-0 row-cols-2 align-items-center `}
                      >
                        <p
                          style={{ fontSize: "20px", width: "200px" }}
                          className="col"
                        >
                          Deal Code :
                        </p>
                        <span className="col d-flex flex-grow-1 justify-content-cenetr">
                          {data.mainData.uuid}
                        </span>
                      </div>
                    </div>

                    <div className="col-xl-6 col-12 px-0">
                      <div
                        className={`${classes["single-item"]} row align-items-center px-0 mx-0 row-cols-2 `}
                      >
                        <p
                          style={{ width: "200px", fontSize: "20px" }}
                          className="col"
                        >
                          Time Left :
                        </p>
                        <span className="clock"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 order-xl-1 order-2 px-0">
                    {type !== "response" && (
                      <div
                        className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                          >
                            <p style={{ width: "200px" }} className="col">
                              Deal Name :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                              {data.mainData.title_en}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                          >
                            <p style={{ width: "200px" }} className="col">
                              اسم العرض :
                            </p>
                            <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                              {data.mainData.title_ar}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Product Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Model :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.model}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            اسم المنتج :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.name_ar}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Country of Origin :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.country_en}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Product Code :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.uuid}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            بلد المنشأ :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.country_ar}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.category.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Factory Name :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.factory_en}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Sub Category :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.subcategory.name_en}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            اسم المصنع :
                          </p>
                          <span className="col d-flex flex-grow-1  justify-content-md-start justify-content-center">
                            {data.mainData.product.factory_ar}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Market Price :
                          </p>
                          <span className="col  justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.mainData.product.price}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Batch Number :
                          </p>
                          <span className="col  justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.mainData.product.patchNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Unit :
                          </p>
                          <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.mainData.unit}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 px-0">
                        <div
                          className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                        >
                          <p style={{ width: "200px" }} className="col">
                            Customer Type :
                          </p>
                          <span className=" justify-content-md-start justify-content-center d-flex flex-grow-1">
                            {data.mainData.customerType}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      {(data.mainData.product.variants.includes("colors") ||
                        data.mainData.product.variants.includes("Colors")) && (
                        <div className="col-xl-6 col-12 px-0">
                          <div className={`${classes["single-item"]} d-flex`}>
                            <p>Color:</p>
                            <div
                              className="col-2"
                              style={{
                                backgroundColor: `${data.mainData.color}`,
                                borderRadius: "10px",
                                width: "100px",
                                height: "40px",
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                      {(data.mainData.product.variants.includes("sizes") ||
                        data.mainData.product.variants.includes("Sizes")) && (
                        <div className="col-xl-6 col-12 px-0">
                          <div className={classes["single-item"]}>
                            <p>Size:</p>
                            <span>{data.mainData.size}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    {data.mainDatadeposit && (
                      <div
                        className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                      >
                        <div className="col-xl-6 col-12 px-0">
                          <div
                            className={`${classes["single-item"]} ${classes["green"]}`}
                          >
                            <p>Deposite:</p>
                            <span>{data.mainData.deposit} L.E.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-xl-3 order-xl-2 order-1   mb-xl-0 mb-3 px-0">
                    <div className={`${classes.info}`}>
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                          clickable: true,
                        }}
                        loop={false}
                        loopFillGroupWithBlank={true}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper    align-items-center d-flex p-0 "
                      >
                        {data.mainData.product.productImgs.map(
                          (item, index) => (
                            <SwiperSlide key={index}>
                              <Img
                                style={{
                                  objectFit: "cover",
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${item}`}
                              />
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 ">
                    <div className={classes.input}>
                      <p>Description</p>
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-xl-6 pe-xl-2 px-0">
                    <Section
                      border={true}
                      text={data.mainData.product.description_en}
                    />
                  </div>

                  <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                    <Section
                      border={true}
                      text={data.mainData.product.description_ar}
                    />
                  </div>
                </div>
                {data.mainData.areas.length > 0 && (
                  <div className="row px-0 mx-0 mb-3 ">
                    <div className="col-12 px-0 ">
                      <div className={classes.input}>
                        <p className="mb-4">Areas</p>

                        {data.mainData.areas.map((item, index) => (
                          <span
                            onClick={() =>
                              item.district.length > 0 &&
                              setShowIndexArea(index)
                            }
                            key={index}
                            className={`${classes["area"]} w-auto  d-inline`}
                          >
                            {item.city.city_en}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {option === "Delivery Terms" && (
              <>
                <TermsSections
                  terms={data.deliveryTerms}
                  title="Eshtarena Delivey Terms"
                  border={true}
                />

                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Delivery Fees :</p>
                      <span>{data.deliveryFees} L.E.</span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === "Terms and Condition" && (
              <TermsSections
                title="Terms & Condition"
                terms={data.supplierPaymentTerms}
                border={true}
              />
            )}
            {(option === "Current Offer" || option === "Your Offer") && (
              <>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-xl-8 col-12 px-0">
                    <div
                      className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                    >
                      <p className="col">Quantity :</p>
                      <span className="col d-flex flex-grow-1   justify-content-end">
                        {data.yourOffer
                          ? data.yourOffer.quantity
                          : data.currentOffer.quantity}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-xl-8 col-12 px-0">
                    <div
                      className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                    >
                      <p className="col">Market price per unit :</p>
                      <span className="col d-flex flex-grow-1   justify-content-end">
                        {data.yourOffer
                          ? data.yourOffer.marketPrice
                          : data.currentOffer.marketPrice}
                        L.E.
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-xl-8 col-12 px-0">
                    <div
                      className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                    >
                      <p className="col">Starting Price per unit :</p>
                      <span className="col d-flex flex-grow-1   justify-content-end">
                        {data.yourOffer
                          ? data.yourOffer.startingPrice
                          : data.currentOffer.startingPrice}{" "}
                        L.E.
                      </span>
                    </div>
                  </div>
                </div>
                {data.joined && (
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-xl-8 col-12 px-0">
                      <div
                        className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                      >
                        <p className="col">Current Supplier Price per unit :</p>
                        <span className="col d-flex flex-grow-1   justify-content-end">
                          {data.yourOffer
                            ? data.yourOffer.supplierPrice
                            : data.currentOffer.supplierPrice}{" "}
                          L.E.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {type === "onGoing" && (
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-xl-8 col-12 px-0">
                      <div
                        className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                      >
                        <p className="col">Time left :</p>
                        <span className="col d-flex flex-grow-1 clock   justify-content-end"></span>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-xl-8 col-12 px-0">
                    <div
                      className={`${classes["single-item"]} row px-0 mx-0 row-cols-2 `}
                    >
                      <p className="col">Minimum price decrease percentage :</p>
                      <span className="col d-flex flex-grow-1   justify-content-end">
                        {data.yourOffer
                          ? data.yourOffer.decreasePercentage
                          : data.currentOffer.decreasePercentage}{" "}
                        % of the Current Supplier Price.
                      </span>
                    </div>
                  </div>
                </div>

                {type === "onGoing" && (
                  <>
                    <div className="row px-0 mx-0 pt-0 mt-0 align-items-center">
                      {data.joined && (
                        <div className="col-lg-6  px-0">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (data.joined)
                                price && ValidPrice && joinDeal();
                              else joinDeal();
                            }}
                          >
                            <InputGroup
                              value={"Your Price"}
                              error={price && !ValidPrice}
                              default={price}
                              errorMsg={"Provide a Valid Price"}
                              onChange={(e) => handleAddingPrice(e)}
                              underLineTextNoWrap={true}
                              underLineText="By joining the deal you accept our payment terms and delivery terms"
                            />
                          </form>
                        </div>
                      )}

                      <div
                        className={`${
                          data.joined
                            ? "w-lg-auto px-0 ps-lg-2"
                            : "px-0 col-xl-6 mx-auto col-12"
                        }`}
                      >
                        {loadingButton ? (
                          <Button
                            onClick={() => {}}
                            className={" px-5 py-1 mx-auto text-center"}
                            type="normal"
                            text={
                              <div className="text-center">
                                <span className="spinner-border spinner-border-sm"></span>
                              </div>
                            }
                            color={"#D1E6FF"}
                            borderColor={"#134074"}
                            fontColor={"#134074"}
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              setLoadingButton(true);
                              if (data.joined)
                                price && ValidPrice && joinDeal();
                              else joinDeal();
                            }}
                            className={" px-2 mx-auto text-center"}
                            type="normal"
                            text={
                              data.yourOffer
                                ? "Join With Better Offer"
                                : "Join Deal"
                            }
                            color={"#D1E6FF"}
                            borderColor={"#134074"}
                            fontColor={"#134074"}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={`row  px-0 mx-0 mt-4 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-12 px-0">
                        <div dir="auto" className={classes["single-item"]}>
                          <p>Progress</p>
                          <div className="row  px-0 mx-0">
                            <div className="col-xl-2 me-xl-5 col-md-4">
                              <CircularProgressbar
                                value={
                                  data.yourOffer
                                    ? data.yourOffer.sold
                                    : data.currentOffer.sold
                                }
                                text={
                                  data.yourOffer
                                    ? data.yourOffer.sold
                                    : data.currentOffer.sold
                                }
                              />
                            </div>
                            <div className="col-xl-4 col-md-8 ms-xl-5   mt-lg-0 mt-4  d-flex flex-column  justify-content-center  d-inline">
                              <div className="row px-0 mx-0">
                                <div
                                  className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                >
                                  <Avatar
                                    sx={{
                                      bgcolor: "#3e98c7",

                                      width: "30px",
                                      height: "30px",
                                    }}
                                  ></Avatar>
                                </div>
                                <div
                                  className={`w-auto d-inline pe-xl-5 ${classes["single-item"]}`}
                                >
                                  <p>Available Quantities</p>
                                </div>
                                <div
                                  className={`w-auto d-inline  ms-auto ${classes["single-item"]}`}
                                >
                                  <p>
                                    {data.yourOffer
                                      ? data.yourOffer.available
                                      : data.currentOffer.available}
                                  </p>
                                </div>
                              </div>
                              <div className="row px-0 mx-0">
                                <div
                                  className={`w-auto d-inline pe-2 ${classes["single-item"]}`}
                                >
                                  <Avatar
                                    sx={{
                                      bgcolor: "#949494",

                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    {""}
                                  </Avatar>
                                </div>
                                <div
                                  className={`w-auto d-inline pe-xl-5 ${classes["single-item"]}`}
                                >
                                  <p>Sold Quantities</p>
                                </div>
                                <div
                                  className={`w-auto d-inline text-center ms-auto ${classes["single-item"]}`}
                                >
                                  <p>
                                    {data.yourOffer
                                      ? data.yourOffer.sold
                                      : data.currentOffer.sold}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {option === "Agreements" && (
              <>
                {!data.purchasingDone && (
                  <div className="row px-0 mx-0">
                    <div className="col-12 ">
                      <div className={classes.input}>
                        <UploadImageGroup2
                          default={doc}
                          type="supplier"
                          onChange={(e) => setDoc([e])}
                          errorMsg="Provide a Profile Picture"
                          title={"Purshacing Order"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-12 ">
                    <div className={classes.input}>
                      <UploadImageGroup2
                        noDeleteAll={true}
                        default={
                          (data.purchasingOrder &&
                            data.invoice && [
                              data.purchasingOrder,
                              data.invoice,
                            ]) ||
                          (data.purchasingOrder &&
                            !data.invoice && [data.purchasingOrder]) ||
                          (!data.purchasingOrder &&
                            data.invoice && [data.invoice])
                        }
                        imageType="invoice/po"
                        errorMsg="Provide a Profile Picture"
                        title={"Invoice"}
                        subTitle="click on invoice to download"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
