// import React from 'react'
import React, { Component } from "react";
import Scanner from "./Scanner";
import { useNavigate } from "react-router-dom";

export default function BarCodeScanner() {
  const history = useNavigate();
  let onDetected = (result) => {
    history(`/cashier/${result}`);
  };
  return (
    <div
      style={{ height: `${window.innerHeight}px` }}
      className="row w-100 justify-content-center d-flex align-items-center overflow-hidden"
    >
      <div
        style={{ position: "relative", left: "25%", top: "0" }}
        className="w-auto d-inline h-100 align-items-center d-flex   mx-auto"
      >
        <Scanner onDetected={onDetected} />
      </div>
    </div>
  );
}
