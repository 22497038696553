import React from "react";
import AndoridBtn from "./AndoridBtn";
import IosBtn from "./IosBtn";
import { Link } from "react-scroll";
import EshtreanaMainLogo2 from "../Reusable/SVG/EshtarenaMainLogo2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoOld from "../Reusable/SVG/LogoOld";

export default function Footer(props) {
  const history = useNavigate();
  const { language } = useSelector((state) => state.language);

  return (
    <div dir={language === "ar" && "rtl"} className={"footer"}>
      <div className="row px-xl-0 px-3">
        <div
          onClick={() => history("/")}
          className={`col-xl-2 col-xl-3 col-12   justify-content-center 
            justify-content-xl-start align-items-xl-start
            align-items-center  d-flex flex-column my-3  my-xl-0`}
        >
          <div className="w-auto d-inline  mx-auto">
            <LogoOld />
          </div>
          <div
            className={`d-flex mt-1 mx-auto ${
              language === "en"
                ? `justify-content-xl-start`
                : `justify-content-xl-end`
            } justify-content-center`}
          >
            {props.social.map((item, index) => (
              <div key={index} className={`pe-2 m-1 socialMedia `}>
                <a
                  target="_blank"
                  style={{
                    background: "transparent", // Ensure no white background
                    backgroundColor: "transparent", // Ensure no white background
                  }}
                  href={item.link}
                  rel="noreferrer"
                >
                  <img
                    style={{
                      height: "20px",
                      width: "20px",
                      // padding: "2px",
                      background: "transparent", // Ensure no white background
                      backgroundColor: "transparent", // Ensure no white background
                      display: "block", // Avoid inline spacing issues
                      border: "none",
                      borderRadius: "0px",
                    }}
                    alt="Social_Media"
                    show={false}
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${item.logo}`}
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="col-xl-4  col-lg-4   col-12  my-3 my-xl-0">
          <div className="row  px-0 mx-0 ">
            <div className="col-xl-6 ">
              {/* <p className={`footer-title`}>
                {language === "en" ? "Company" : "الشركة"}
              </p> */}
              <div>
                <Link className={`link`} to="about-us">
                  {language === "en" ? "About Us" : "من نحن"}
                </Link>
              </div>
              {/* <div>FAQs</div> */}
            </div>
            <div className="col-xl-6 mt-xl-0 mt-3">
              {/* <p className={`footer-title text-nowrap`}>
                {language === "en" ? "Get to now us" : "تعرف علينا"}
              </p> */}
              {/* <div>Careers</div> */}
              <div className="text-nowrap">
                <a
                  style={{ color: "unset", textDecoration: "none" }}
                  rel="noreferrer"
                  href={`https://eshtarena.com/terms-conditions`}
                  target="_blank"
                >
                  {language === "en"
                    ? "Terms and Conditions"
                    : "الشروط و الأحكام"}
                </a>
              </div>
              <div>
                <a
                  style={{ color: "unset", textDecoration: "none" }}
                  rel="noreferrer"
                  href={`https://eshtarena.com/privacy-policy`}
                  target="_blank"
                >
                  {language === "en" ? "Privacy Policy" : "سياسة الخصوصية"}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-xl-5  ${
            language === "en" ? "ms-auto" : "me-auto"
          } col-12 my-3 my-xl-0`}
        >
          <div
            className={`row  ${
              language === "en"
                ? `text-end justify-content-end`
                : `text-start justify-content-start`
            }`}
          >
            <div className={` m-0 mb-3 text-center downlod`}>
              {language === "en"
                ? "Download the app now and get started"
                : "حمل التطبيق الأن و إبدأ فتوفير أموالك"}
            </div>
            <div className="  px-0 mx-0 text-center    ">
              <a
                style={{ cursor: "pointer" }}
                className="w-auto px-0 d-inline pe-md-1"
                href={props?.data?.apple}
                rel="noreferrer"
                target="_blank"
              >
                <div className="w-auto d-inline px-0 mx-0 mb-xl-0 mb-3 ">
                  <IosBtn />
                </div>
              </a>
              <a
                style={{ cursor: "pointer" }}
                className="w-auto px-0 d-inline pe-md-1"
                href={props?.data?.google}
                rel="noreferrer"
                target="_blank"
              >
                <div className={`w-auto d-inline mx-0  px-0 ps-xl-3 `}>
                  <AndoridBtn />
                </div>
              </a>
            </div>
            <div className={` m-0 pt-3 text-center downlod`}>
              {language === "en"
                ? `©️ copyright 2024 : All rights reserved by ESHTARENA LTD IBC`
                : `  جميع الحقوق محفوظة لشركة اشترينا المحدوده ، شركة أعمال عالمية ٢٠٢٤ `}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
