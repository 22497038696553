import React, { useEffect, useState } from "react";
import classes from "./Product.module.scss";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import ProductActiveL from "../../components/Reusable/SVG/ProductActiveL";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import { Checkbox, Skeleton } from "@mui/material";
import Button from "../../components/Reusable/UI/Button/Button";
import TextAreaGroup from "../../components/Reusable/UI/InputGroup/TextAreaGroup";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import UploadImageGroup2 from "../../components/Reusable/UI/InputGroup/UploadImageGroup2";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import { productService } from "../../services/ProductServices";
import { useNavigate, useParams } from "react-router-dom";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import { mainDataHelper } from "../../Helper/MainData/MainData";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
export default function ProductForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showError, setShowError] = useState(false);
  const [option, setOption] = useState("Data");
  const [loading, setLoading] = useState(true);
  const [mainImages, setManiImages] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("select category");
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    "select sub-category"
  );
  const [selectedCategoryFinal, setSelectedCategoryFinal] = useState();
  const [selectedSubCategoryFinal, setSelectedSubCategoryFinal] = useState();
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);

  useEffect(() => {
    mainDataHelper.getAllCategories(
      setServerError,
      setServerErrorMsg,
      setCategories,
      setCategoriesName
    );
  }, []);
  useEffect(() => {
    selectedCategory !== "select category" &&
      categoriesName.includes(selectedCategory) &&
      mainDataHelper.getAllSubCategories(
        setServerError,
        setServerErrorMsg,
        selectedCategory,
        categories,
        setSubCategories,
        setSubCategoriesName
      );
  }, [selectedCategory]);
  const [data, setData] = useState({
    name_EN: null,
    name_AR: null,
    id: null,
    barcode: null,
    category: null,
    subCategory: null,
    markettPrice: null,
    vatPercentage: null,
    model: null,
    patchNumber: null,
    country_EN: null,
    country_AR: null,
    factory_EN: null,
    factory_AR: null,
    productionDate: null,
    options: { 0: false, 1: false, 2: false },
    description_AR: null,
    description_EN: null,
  });

  const [dataErr, setDataErr] = useState({
    name_EN: false,
    name_AR: false,
    id: false,
    barcode: false,
    category: false,
    subCategory: false,
    marketPrice: false,
    vatPercentage: false,
    model: false,
    patchNumber: false,
    country_EN: false,
    country_AR: false,
    factory_EN: false,
    factory_AR: false,
    productionDate: false,
    description_AR: false,
    description_EN: false,
  });

  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    e.target.value.replace(/\s/g, "").length === 0
      ? setDataErr((prev) => ({ ...prev, [e.target.name]: false }))
      : setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === "marketPrice" || e.target.name === "vatPercentage") {
      var isANumber = isNaN(e.target.value) === false;

      isANumber
        ? setDataErr((prev) => ({ ...prev, [e.target.name]: true }))
        : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      (parseFloat(e.target.value) <= 0 ||
        e.target.value.startsWith("-") ||
        e.target.value === "" ||
        e.target.value.startsWith("+")) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      if (e.target.name === "vatPercentage") {
        if (!(e.target.value >= 0 && e.target.value <= 100))
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        else setDataErr((prev) => ({ ...prev, [e.target.name]: true }));
      }

      if (e.target.value === "")
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };
  let handleSubmit = async () => {
    setShowError(true);
    if (
      dataErr.barcode &&
      dataErr.category &&
      dataErr.country_AR &&
      dataErr.country_EN &&
      dataErr.description_AR &&
      dataErr.description_EN &&
      dataErr.factory_AR &&
      dataErr.factory_EN &&
      dataErr.id &&
      dataErr.marketPrice &&
      dataErr.model &&
      dataErr.name_EN &&
      dataErr.name_AR &&
      dataErr.patchNumber &&
      dataErr.productionDate &&
      dataErr.subCategory &&
      dataErr.vatPercentage &&
      productImages.length > 0 &&
      mainImages.length > 0
    ) {
      let fd = new FormData();
      fd.append("name_en", data.name_EN);
      fd.append("name_ar", data.name_AR);
      !id && fd.append("uuid", data.id);
      fd.append("barcode", data.barcode);
      fd.append("description_en", data.description_EN);
      fd.append("description_ar", data.description_AR);
      fd.append("price", data.marketPrice);
      fd.append("vatPercentage", data.vatPercentage);
      fd.append("model", data.model);
      fd.append("patchNumber", data.patchNumber);
      fd.append("country_en", data.country_EN);
      fd.append("country_ar", data.country_AR);
      fd.append("factory_en", data.factory_EN);
      fd.append("factory_ar", data.factory_AR);
      selectedCategoryFinal && fd.append("category", selectedCategoryFinal);
      selectedSubCategoryFinal &&
        fd.append("subcategory", selectedSubCategoryFinal);

      let index = 0;
      if (!id) {
        if (data.options[0]) {
          fd.append(`variants[${index}]`, "colors");
          index++;
        }
        if (data.options[1]) {
          fd.append(`variants[${index}]`, "sizes");
          index++;
        }
        if (data.options[2]) fd.append(`variants[${index}]`, "ExpireDate");
      }

      fd.append("productionDate", data.productionDate);

      mainImages[0].name &&
        fd.append("defaultImg", mainImages[0], mainImages[0].name);
      for (let i = 0; i < productImages.length; i++) {
        productImages[i].name
          ? fd.append("productImgs", productImages[i], productImages[i].name)
          : fd.append("picExists", productImages[i]);
      }
      let response = id
        ? await productService.updateProduct(fd, id)
        : await productService.addProduct(fd);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setButtonLoading(false);
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history(id ? `/account/product/${id}` : `/account/product`, {
            replace: true,
          });
        }, 2000);
      } else {
        setButtonLoading(false);
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
      }
    } else {
      setServerErrorMsg(
        `There a Missing / Incorrect Data are Required Must be Filled , Please Check Your Data .`
      );
      setServerError(true);
      setTimeout(() => {
        setServerError(false);
      }, 2000);
    }
    setButtonLoading(false);
  };

  let getProduct = async () => {
    let response = await productService.getSingleProduct(id);

    setData({
      name_EN: response.data.product.name_en,
      name_AR: response.data.product.name_ar,
      id: response.data.product.uuid,
      barcode: response.data.product.barcode,
      marketPrice: response.data.product.price,
      vatPercentage: response.data.product.vatPercentage,
      model: response.data.product.model,
      patchNumber: response.data.product.patchNumber,
      country_EN: response.data.product.country_en,
      country_AR: response.data.product.country_ar,
      factory_EN: response.data.product.factory_en,
      factory_AR: response.data.product.factory_ar,
      productionDate: response.data.product.productionDate.slice(0, 10),
      options: {
        0: response.data.product.variants.includes("colors") && true,
        1: response.data.product.variants.includes("sizes") && true,
        2: response.data.product.variants.includes("ExpireDate") && true,
      },
      description_AR: response.data.product.description_ar,
      description_EN: response.data.product.description_en,
    });
    setSelectedCategory(response.data.product.category.name_en);
    setSelectedSubCategory(response.data.product.subcategory.name_en);
    setManiImages([response.data.product.defaultImg]);
    setProductImages(response.data.product.productImgs);

    setDataErr({
      name_EN: true,
      name_AR: true,
      id: true,
      barcode: true,
      category: true,
      subCategory: true,
      marketPrice: true,
      vatPercentage: true,
      model: true,
      patchNumber: true,
      country_EN: true,
      country_AR: true,
      factory_EN: true,
      factory_AR: true,
      productionDate: true,
      description_AR: true,
      description_EN: true,
    });

    setLoading(false);
  };
  useEffect(() => {
    id ? getProduct() : setLoading(false);
  }, [id]);

  return (
    <div className={`w-100 ${classes["product-page"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Product ${id ? "Edited" : "Added"} Successfully`}
            button={
              <Button
                text="Back to Products"
                color="#134074"
                bordeColor="#134074"
                fontColor="white"
                type="normal"
                className="w-auto px-4"
              />
            }
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle text="Add Product" icon={<ProductActiveL />} />
        {loading ? (
          <section className={`${classes["main-section"]} mb-5`}>
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <div className="w-auto d-inline">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "3rem", width: "200px" }}
                />
              </div>
              <div className="w-auto d-inline">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "3rem", width: "200px" }}
                />
              </div>
            </div>

            {[0, 1, 2, 3, 4].map((item, index) => (
              <div key={index} className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "50%" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ height: "3rem", width: "90%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "50%" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ height: "3rem", width: "90%" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </section>
        ) : (
          <section className={`${classes["main-section"]} mb-5`}>
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={["Data", "Description", "Media"]}
              />
            </div>

            {option === "Data" && (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.name_EN}
                        name="name_EN"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.name_EN}
                        errorMsg="Provide a Product Name"
                        value={"Product Name"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.name_AR}
                        name="name_AR"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.name_AR}
                        errorMsg="من فضلك سجل اسما للمنتج"
                        value={"اسم المنتج"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.id}
                        name="id"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.id}
                        errorMsg="Provide a Product ID"
                        value={"Product ID"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.barcode}
                        name="barcode"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.barcode}
                        errorMsg="Provide a Product BarCode"
                        value={"Product Barcode"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="dropdown"
                        default={selectedCategory}
                        options={["select category", ...categoriesName]}
                        name="category"
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          if (e.target.value !== "select category") {
                            categories.map((item) => {
                              if (item.name_en === e.target.value) {
                                setSelectedCategoryFinal(item._id);
                              }
                            });
                            setSelectedSubCategory(null);
                            setSelectedSubCategoryFinal(null);
                            setDataErr((prev) => ({ ...prev, category: true }));
                          } else {
                            setDataErr((prev) => ({
                              ...prev,
                              subCategory: false,
                              category: false,
                            }));
                            setSelectedSubCategory("select SubCategory");
                          }
                        }}
                        error={showError && !dataErr.category}
                        errorMsg="Provide a Category"
                        value={"Category"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        type="dropdown"
                        options={["select SubCategory", ...subCategoriesName]}
                        default={selectedSubCategory}
                        name="subCategory"
                        onChange={(e) => {
                          setSelectedSubCategory(e.target.value);
                          if (e.target.value !== "select SubCategory") {
                            subCategories.map((item) => {
                              if (item.name_en === e.target.value) {
                                setSelectedSubCategoryFinal(item._id);
                              }
                            });
                            setDataErr((prev) => ({
                              ...prev,
                              subCategory: true,
                            }));
                          } else {
                            setDataErr((prev) => ({
                              ...prev,
                              subCategory: false,
                            }));
                          }
                        }}
                        error={showError && !dataErr.subCategory}
                        errorMsg="Provide a Sub Category"
                        value={"Sub Category"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.marketPrice}
                        name="marketPrice"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.marketPrice}
                        errorMsg={
                          data.marketPrice
                            ? "Provide a Valid Market Price"
                            : "Provide a Market Price"
                        }
                        value={"Market Price (including vat)"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.vatPercentage}
                        name="vatPercentage"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.vatPercentage}
                        errorMsg={
                          data.vatPercentage
                            ? "Provide a Valid VAT Percentage"
                            : "Provide a VAT Percentage"
                        }
                        value={"Vat Percentage"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.model}
                        name="model"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.model}
                        errorMsg="Provide a Product Model"
                        value={"Model"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.patchNumber}
                        name="patchNumber"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.patchNumber}
                        errorMsg={"Provide a Batch Number"}
                        value={"Batch Number"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.country_EN}
                        name="country_EN"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.country_EN}
                        errorMsg="Provide a Country of Origin"
                        value={"Country of Origin"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.country_AR}
                        name="country_AR"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.country_AR}
                        errorMsg="من فضلك سجل بلد المنشأ"
                        value={"بلد المنشأ"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.factory_EN}
                        name="factory_EN"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.factory_EN}
                        errorMsg="Provide a Factory Name"
                        value={"Factory Name"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.factory_AR}
                        name="factory_AR"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.factory_AR}
                        errorMsg="من فضلك سجل اسم المصنع"
                        value={"اسم المصنع"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={data.productionDate}
                        name="productionDate"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.productionDate}
                        errorMsg="Provide a Production Date"
                        value={"Production Date"}
                        type="date"
                      />
                    </div>
                  </div>
                  {!id && (
                    <div className="col-lg-6 px-0 ps-lg-2">
                      <div className={classes.input}>
                        <span style={{ fontWeight: "500" }} className="px-0">
                          This Product has
                        </span>
                        <div className="row px-0 mx-0 mb-0 pb-0 mt-3">
                          {["Colors", "Sizes", "Expiry Date"].map(
                            (item, index) => (
                              <div
                                key={index}
                                className="col-xl-4 d-flex flex-row"
                              >
                                <div className="w-auto h-auto px-0  py-0 my-0 d-inline ">
                                  <Checkbox
                                    onClick={() => {
                                      item === "Colors" &&
                                        setData((prev) => ({
                                          ...prev,
                                          options: {
                                            ...data.options,
                                            0: !data.options[0],
                                          },
                                        }));
                                      item === "Sizes" &&
                                        setData((prev) => ({
                                          ...prev,
                                          options: {
                                            ...data.options,
                                            1: !data.options[1],
                                          },
                                        }));
                                      item === "Expiry Date" &&
                                        setData((prev) => ({
                                          ...prev,
                                          options: {
                                            ...data.options,
                                            2: !data.options[2],
                                          },
                                        }));
                                    }}
                                    color="success"
                                    className="py-0"
                                    checked={
                                      (item === "Colors" &&
                                        data.options[0] &&
                                        true) ||
                                      (item === "Sizes" &&
                                        data.options[1] &&
                                        true) ||
                                      (item === "Expiry Date" &&
                                        data.options[2] &&
                                        true)
                                    }
                                  />
                                </div>
                                <div
                                  onClick={() => {
                                    item === "Colors" &&
                                      setData((prev) => ({
                                        ...prev,
                                        options: {
                                          ...data.options,
                                          0: !data.options[0],
                                        },
                                      }));
                                    item === "Sizes" &&
                                      setData((prev) => ({
                                        ...prev,
                                        options: {
                                          ...data.options,
                                          1: !data.options[1],
                                        },
                                      }));
                                    item === "Expiry Date" &&
                                      setData((prev) => ({
                                        ...prev,
                                        options: {
                                          ...data.options,
                                          2: !data.options[2],
                                        },
                                      }));
                                  }}
                                  className="w-auto d-inline cursor px-0 pe-3 py-0 d-flex align-items-center  h-auto pb-1"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {item}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {false && <span className={classes.error}></span>}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mx-0 px-0  mb-pb-0">
                  <div className="col-4 ms-auto px-0  mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => setOption("Description")}
                        color={"#D1E6FF"}
                        borderColor={"#134074"}
                        fontColor={"#134074"}
                        className={"w-auto px-5  ms-auto text-end"}
                        type="normal"
                        text={"Next"}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === "Description" && (
              <>
                <div className="row px-0 mx-0">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <TextAreaGroup
                        default={data.description_EN}
                        name="description_EN"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.description_EN}
                        errorMsg="Provide a Description"
                        value={"Description"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 ps-lg-2">
                    <div className={classes.input}>
                      <TextAreaGroup
                        default={data.description_AR}
                        name="description_AR"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dataErr.description_AR}
                        errorMsg="من فصلك سجل الوصف للمنتج"
                        value={"الوصف"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mx-0 px-0  justify-content-end mb-pb-0">
                  <div className="w-auto d-inline  px-0 pe-lg-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => setOption("Data")}
                        className={"w-auto d-inline px-5   "}
                        type="reverse"
                        text={"Back"}
                      />
                    </div>
                  </div>
                  <div className="w-auto d-inline  px-0 ps-lg-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => setOption("Media")}
                        color={"#D1E6FF"}
                        borderColor={"#134074"}
                        fontColor={"#134074"}
                        className={"w-auto d-inline px-5  ms-auto text-end"}
                        type="normal"
                        text={"Next"}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === "Media" && (
              <>
                <div className={classes.input}>
                  <UploadImageGroup2
                    default={mainImages}
                    imageType={"Product"}
                    onChange={(e) => setManiImages([e])}
                    error={showError && mainImages.length === 0}
                    errorMsg={"Provide Product Main Image"}
                    onRemove={(data) => setManiImages(data)}
                    title="Product Main Photo"
                    type="Photo"
                  />
                </div>
                <div className={classes.input}>
                  <UploadImageGroup2
                    default={productImages}
                    imageType={"Product"}
                    error={showError && productImages.length === 0}
                    errorMsg={"Provide Product Images"}
                    onChange={(e) => setProductImages((prev) => [...prev, e])}
                    onRemove={(data) => setProductImages(data)}
                    title="Product Images"
                    type="Photo"
                  />
                </div>
                <div className="row mx-0 px-0  justify-content-end mb-pb-0">
                  <div className="w-auto d-inline  px-0 pe-lg-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      <Button
                        onClick={() => setOption("Description")}
                        color={"#D1E6FF"}
                        borderColor={"#134074"}
                        fontColor={"#134074"}
                        className={"w-auto d-inline px-5   "}
                        type="reverse"
                        text={"Back"}
                      />
                    </div>
                  </div>
                  <div className="w-auto d-inline  px-0 ps-lg-2 mb-pb-0">
                    <div className={`  mb-pb-0`}>
                      {buttonLoading ? (
                        <div className="text-center px-5">
                          <span className="spinner-border spinner-border-sm"></span>
                        </div>
                      ) : (
                        <Button
                          onClick={() => {
                            setButtonLoading(true);
                            handleSubmit();
                          }}
                          color={"#D1E6FF"}
                          borderColor={"#134074"}
                          fontColor={"#134074"}
                          className={"w-auto d-inline px-5  ms-auto text-end"}
                          type="normal"
                          text={id ? "Edit Product" : "Add Product"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
