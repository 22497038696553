import React, { Component, useEffect } from "react";
import Quagga from "quagga";
import withNavigateHook from "./WithNavigateHook";
import { scanActions } from "../../store/reducers/scanSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import React from 'react'

function Scanner() {
  const dispatch = useDispatch();
  const history = useNavigate();
  let _onDetected = (result) => {
    Quagga.offProcessed(_onDetected);
    Quagga.offDetected(_onDetected);
    Quagga.stop();
    history(`/cashier/${result.codeResult.code}`);
  };
  useEffect(() => {
    localStorage.setItem("scan", "true");
    dispatch(scanActions.setScan({ scan: true }));

    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            // width: 500,
            // height: 500,

            facingMode: "environment",
          },
        },
        locator: {
          halfSample: true,
          patchSize: "small", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: true,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true,
            },
          },
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["code_128_reader"],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true,
          },
        },
        locate: true,
      },
      function (err) {
        if (err) return;
        Quagga.start();
      }
    );
    Quagga.onDetected(_onDetected);

    return () => {
      Quagga.offDetected(_onDetected);
    };
  }, []);
  return (
    <div
      id="interactive"
      // style={{ width: "500px !important", height: "500px" }}
      className="viewport px-0"
    />
  );
}

export default withNavigateHook(Scanner);
