import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import classes from "./NavBar.module.css";
import Lang from "../Reusable/SVG/Lang";
import EshtreanaMainLogo2 from "../Reusable/SVG/EshtarenaMainLogo2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { languageActions } from "../../store/reducers/langugeSlice";
import { debounce } from "lodash";
import LogoOld from "../Reusable/SVG/LogoOld";

const NavBar = () => {
  const [openToggle, setOpenToggle] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [language, setLanguage] = useState("en"); // Default language is English
  const [scrollingDown, setScrollingDown] = useState(false); // Track scroll direction
  const history = useNavigate();
  const dispatch = useDispatch();
  const navbarRef = useRef(null); // Ref to track the navbar
  let lastScrollY = 0; // To track the last scroll position

  const toggleLanguage = () => {
    setOpenToggle(false);
    localStorage.setItem("language", language === "en" ? "ar" : "en");
    dispatch(languageActions.updateLanguge(language === "en" ? "ar" : "en"));
    setLanguage((prevLanguage) => (prevLanguage === "en" ? "ar" : "en"));
  };

  const handleToggle = debounce(() => {
    setOpenToggle((prev) => !prev);
  }, 300);

  useEffect(() => {
    const handleScroll = () => {
      if (openToggle) return; // Ignore scroll changes if the toggle menu is open

      const sectionOffsets = [];
      document.querySelectorAll("section").forEach((sec) => {
        const { id, offsetTop } = sec;
        sectionOffsets.push({ id, offsetTop });
      });

      const scrollPosition = window.scrollY + 180; // Considering some offset
      const currentSection = sectionOffsets.find(
        (section) =>
          section.offsetTop <= scrollPosition &&
          section.offsetTop +
            document.getElementById(section.id).offsetHeight -
            90 >
            scrollPosition
      );

      setActiveSection(currentSection ? currentSection.id : "");

      // Detect scroll direction
      if (window.scrollY > lastScrollY) {
        setScrollingDown(true); // Scrolling down
      } else {
        setScrollingDown(false); // Scrolling up
      }
      lastScrollY = window.scrollY; // Update last scroll position
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [openToggle]); // Dependency on openToggle

  const scrollToSection = (id) => {
    setActiveSection(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setOpenToggle(false); // Close toggle after navigation
  };

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "en");
  }, [language]);

  // Handle clicks outside the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpenToggle(false); // Close the navbar if the click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar
      ref={navbarRef} // Attach the ref to the Navbar
      collapseOnSelect
      dir={language === "en" ? "ltr" : "rtl"}
      expand="lg"
      variant="dark"
      className={`px-0 ${classes["nav-bar"]} sticky-top ${
        scrollingDown ? classes["collapsed"] : ""
      }`}
    >
      <Container>
        <Navbar.Brand
          onClick={() => {
            history("/");
          }}
        >
          <LogoOld />
        </Navbar.Brand>

        <Navbar.Toggle
          onClick={(e) => {
            e.stopPropagation();
            handleToggle();
          }}
          bg="light"
          color="light"
          className="text-light"
          aria-controls="basic-navbar-nav"
        />

        <Navbar.Collapse
          in={openToggle}
          className={`justify-content-lg-end justify-content-center d-lg-block ${
            openToggle ? "show" : ""
          }`}
          id="basic-navbar-nav"
        >
          <Nav className={`${language === "en" ? "ms-auto" : "me-auto"}`}>
            <Nav.Link
              className={`${classes["nav-link"]} ${
                activeSection === "about-us" && classes["active"]
              }`}
              onClick={() => scrollToSection("about-us")}
            >
              {language === "en" ? "About us" : "من نحن"}
            </Nav.Link>
            <Nav.Link
              className={`${classes["nav-link"]} ${
                activeSection === "deals" && classes["active"]
              }`}
              onClick={() => scrollToSection("deals")}
            >
              {language === "en" ? "Deals" : "عروض"}
            </Nav.Link>
            <Nav.Link
              className={`${classes["nav-link"]} ${
                activeSection === "partners" && classes["active"]
              }`}
              onClick={() => scrollToSection("partners")}
            >
              {language === "en" ? "Partners" : "شركاؤنا"}
            </Nav.Link>
            <Nav.Link
              className={`${classes["nav-link"]} ${
                activeSection === "contact-us" && classes["active"]
              }`}
              onClick={() => scrollToSection("contact-us")}
            >
              {language === "en" ? "Contact us" : "اتصل بنا"}
            </Nav.Link>
            <Nav.Link
              className={`${classes["nav-link"]}`}
              onClick={() => {
                setOpenToggle(false);
                history("/login");
              }}
            >
              {language === "en" ? "Login" : "تسجيل الدخول"}
            </Nav.Link>
          </Nav>
          <Button
            variant="outline-light"
            className={`d-flex ${classes["language-button"]} w-lg-auto ${
              language === "en" ? "ms-3 ms-auto" : "me-3 me-auto"
            }`}
            onClick={toggleLanguage}
          >
            {language === "en" ? "العربية" : "English"}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
