import React from "react";
import classes from "./BaseNavBar.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../UI/Button/Button";
export default function BaseNavBar(props) {
  const history = useNavigate();
  return (
    <div
      style={{
        boxShadow: props.shadow && "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
      className={`justifuy-content-center position-relative d-flex ${
        classes.nav
      }  ${props.shadow && "sticky-top bg-light"} `}
    >
      <svg
        onClick={() => history("/")}
        className="w-auto d-inline mx-auto"
        width="220"
        height="76"
        viewBox="0 0 220 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M133.69 6.73814C135.371 6.73814 136.734 5.63123 136.734 4.2658C136.734 2.90036 135.371 1.79346 133.69 1.79346C132.008 1.79346 130.646 2.90036 130.646 4.2658C130.646 5.63123 132.008 6.73814 133.69 6.73814Z"
          fill={props.shadow ? "#134074" : "url(#paint0_linear_1097_80379)"}
        />
        <path
          d="M124.435 6.73814C126.116 6.73814 127.479 5.63123 127.479 4.2658C127.479 2.90036 126.116 1.79346 124.435 1.79346C122.754 1.79346 121.391 2.90036 121.391 4.2658C121.391 5.63123 122.754 6.73814 124.435 6.73814Z"
          fill={props.shadow ? "#134074" : "url(#paint1_linear_1097_80379)"}
        />
        <path
          d="M85.1276 9.13316C86.8088 9.13316 88.1717 8.02625 88.1717 6.66082C88.1717 5.29538 86.8088 4.18848 85.1276 4.18848C83.4464 4.18848 82.0835 5.29538 82.0835 6.66082C82.0835 8.02625 83.4464 9.13316 85.1276 9.13316Z"
          fill={props.shadow ? "#134074" : "url(#paint2_linear_1097_80379)"}
        />
        <path
          d="M143.305 6.73814C144.986 6.73814 146.349 5.63123 146.349 4.2658C146.349 2.90036 144.986 1.79346 143.305 1.79346C141.624 1.79346 140.261 2.90036 140.261 4.2658C140.261 5.63123 141.624 6.73814 143.305 6.73814Z"
          fill={props.shadow ? "#134074" : "url(#paint3_linear_1097_80379)"}
        />
        <path
          d="M143.305 39.402C144.986 39.402 146.349 38.2951 146.349 36.9296C146.349 35.5642 144.986 34.4573 143.305 34.4573C141.624 34.4573 140.261 35.5642 140.261 36.9296C140.261 38.2951 141.624 39.402 143.305 39.402Z"
          fill={props.shadow ? "#134074" : "url(#paint4_linear_1097_80379)"}
        />
        <path
          d="M141.401 10.4033C139.5 10.1096 138.386 11.3293 137.785 12.6568C135.46 17.7963 133.335 23.0017 131.383 28.2461C130.586 30.3888 129.637 32.2438 132.147 32.945C134.969 33.7362 135.157 32.2468 136.143 29.9243C138.227 25.0096 140.386 19.7412 142.489 14.4699C143.459 12.0425 143.685 10.7539 141.401 10.4033Z"
          fill={props.shadow ? "#134074" : "url(#paint5_linear_1097_80379)"}
        />
        <path
          d="M132.083 10.4033C130.183 10.1096 129.069 11.3293 128.467 12.6568C126.143 17.7963 124.017 23.0017 122.065 28.2461C121.268 30.3888 120.32 32.2438 122.829 32.945C125.652 33.7362 125.84 32.2468 126.825 29.9243C128.91 25.0096 131.068 19.7412 133.172 14.4699C134.142 12.0425 134.367 10.7539 132.083 10.4033Z"
          fill={props.shadow ? "#134074" : "url(#paint6_linear_1097_80379)"}
        />
        <path
          d="M122.767 10.4033C120.867 10.1096 119.753 11.3293 119.151 12.6568C116.827 17.7963 114.701 23.0017 112.749 28.2461C111.952 30.3888 111.004 32.2438 113.513 32.945C116.336 33.7362 116.524 32.2468 117.509 29.9243C119.594 25.0096 121.753 19.7412 123.856 14.4699C124.826 12.0425 125.051 10.7539 122.767 10.4033Z"
          fill={props.shadow ? "#134074" : "url(#paint7_linear_1097_80379)"}
        />
        <path
          d="M111.59 12.7285C109.886 12.4648 108.886 13.5616 108.347 14.7514C106.259 19.3664 104.351 24.0384 102.598 28.7463C101.882 30.6703 101.034 32.3335 103.281 32.9658C105.816 33.676 105.986 32.3395 106.867 30.2537C108.738 25.8425 110.675 21.1105 112.564 16.3816C113.439 14.203 113.638 13.0462 111.59 12.7285Z"
          fill={props.shadow ? "#134074" : "url(#paint8_linear_1097_80379)"}
        />
        <path
          d="M152.577 1.85421C150.677 1.44965 149.562 3.13083 148.961 4.95587C146.636 12.0283 144.511 19.1906 142.559 26.4008C141.762 29.3466 140.814 31.8999 143.323 32.8679C146.146 33.9587 146.334 31.9089 147.319 28.7143C149.404 21.9536 151.562 14.7044 153.665 7.45519C154.636 4.11079 154.861 2.33969 152.577 1.85421Z"
          fill={props.shadow ? "#134074" : "url(#paint9_linear_1097_80379)"}
        />
        <path
          d="M100.964 19.5405C99.6543 19.1928 98.7614 20.2327 98.2264 21.3955C96.1416 25.8906 94.1934 30.4547 92.3632 35.0578C91.6142 36.9397 90.7877 38.555 92.4887 39.2982C94.4074 40.1343 94.6694 38.8097 95.5623 36.7749C97.4589 32.4715 99.4366 27.8535 101.377 23.2325C102.27 21.1018 102.543 19.96 100.964 19.5405Z"
          fill={props.shadow ? "#134074" : "url(#paint10_linear_1097_80379)"}
        />
        <path
          d="M93.814 12.7285C92.1093 12.4648 91.1094 13.5616 90.5706 14.7514C88.4822 19.3664 86.5746 24.0384 84.8219 28.7463C84.1061 30.6703 83.2574 32.3335 85.5045 32.9658C88.0394 33.676 88.2092 32.3395 89.091 30.2537C90.9618 25.8425 92.8989 21.1105 94.7881 16.3816C95.6626 14.203 95.8655 13.0462 93.814 12.7285Z"
          fill={props.shadow ? "#134074" : "url(#paint11_linear_1097_80379)"}
        />
        <path
          d="M87.311 34.3479C86.6357 34.3479 86.0859 34.7944 86.0859 35.3428C86.0859 35.8912 86.6357 36.3378 87.311 36.3378C87.9862 36.3378 88.536 35.8912 88.536 35.3428C88.536 34.7914 87.9862 34.3479 87.311 34.3479Z"
          fill={props.shadow ? "#134074" : "url(#paint12_linear_1097_80379)"}
        />
        <path
          d="M83.4047 34.3479C82.7295 34.3479 82.1797 34.7944 82.1797 35.3428C82.1797 35.8912 82.7295 36.3378 83.4047 36.3378C84.0799 36.3378 84.6297 35.8912 84.6297 35.3428C84.6297 34.7914 84.0836 34.3479 83.4047 34.3479Z"
          fill={props.shadow ? "#134074" : "url(#paint13_linear_1097_80379)"}
        />
        <path
          d="M83.7549 12.7285C82.0502 12.4648 81.0503 13.5616 80.5116 14.7514C78.4231 19.3664 76.5155 24.0384 74.7628 28.7463C74.047 30.6703 73.1984 32.3335 75.4454 32.9658C77.9803 33.676 78.1501 32.3395 79.0319 30.2537C80.9027 25.8425 82.8398 21.1105 84.729 16.3816C85.6035 14.203 85.8027 13.0462 83.7549 12.7285Z"
          fill={props.shadow ? "#134074" : "url(#paint14_linear_1097_80379)"}
        />
        <path
          d="M76.5114 1.85421C74.6112 1.44965 73.4968 3.13083 72.8954 4.95587C70.5708 12.0283 68.4455 19.1906 66.4936 26.4008C65.6966 29.3466 64.7483 31.8999 67.2574 32.8679C70.0801 33.9587 70.2683 31.9089 71.2534 28.7143C73.3382 21.9536 75.4967 14.7044 77.5999 7.45519C78.5703 4.11079 78.7954 2.33969 76.5114 1.85421Z"
          fill={props.shadow ? "#134074" : "url(#paint15_linear_1097_80379)"}
        />
        <path
          d="M112.62 8.19316C112.62 8.74157 113.17 9.18809 113.845 9.18809C114.52 9.18809 115.07 8.74157 115.07 8.19316C115.07 7.64475 114.52 7.19824 113.845 7.19824C113.166 7.19824 112.62 7.64475 112.62 8.19316Z"
          fill={props.shadow ? "#134074" : "url(#paint16_linear_1097_80379)"}
        />
        <path
          d="M108.713 8.19316C108.713 8.74157 109.263 9.18809 109.938 9.18809C110.613 9.18809 111.163 8.74157 111.163 8.19316C111.163 7.64475 110.613 7.19824 109.938 7.19824C109.263 7.19824 108.713 7.64475 108.713 8.19316Z"
          fill={props.shadow ? "#134074" : "url(#paint17_linear_1097_80379)"}
        />
        <path
          d="M109.139 3.14507C109.682 3.01621 110.18 2.87835 110.612 2.74349C110.815 2.68056 110.999 2.61763 111.165 2.55769L116.593 0.723668L116.74 0.672728C117.442 0.432985 117.87 0.21422 117.825 0.091352C117.751 -0.115426 116.327 0.0404017 114.645 0.44197C114.224 0.540864 113.826 0.648759 113.468 0.753646H113.464C113.043 0.876514 112.711 0.999371 112.39 1.11624C111.328 1.50882 108.889 2.32395 107.608 2.73151C107.272 2.8394 106.94 2.94428 106.686 3.04317C106.199 3.23197 105.922 3.39379 105.959 3.49269C106.033 3.69947 107.457 3.54364 109.139 3.14507Z"
          fill={props.shadow ? "#134074" : "url(#paint18_linear_1097_80379)"}
        />
        <path
          d="M11.5011 54.8162C11.6598 54.8162 11.907 54.8042 12.2428 54.7772C12.5785 54.7503 12.8258 54.7383 12.9844 54.7383C15.9215 54.7383 18.663 55.4515 21.2016 56.878C23.7402 58.3045 25.1534 60.1355 25.4412 62.3651L4.50155 62.443C4.37241 63.3271 4.30969 64.0643 4.30969 64.6606C4.30969 68.6553 5.76347 71.4573 8.67104 73.0666C10.3647 73.9236 12.1358 74.3522 13.9917 74.3522C15.8772 74.3522 17.7147 73.9297 19.5043 73.0876C21.2938 72.2455 22.6812 71.1367 23.6738 69.7611C23.707 69.7102 24.2014 68.7872 25.1608 66.9981L25.8803 67.5825C25.3674 69.0869 24.4966 70.4294 23.2679 71.6101C22.0392 72.7909 20.5448 73.7049 18.7885 74.3552L14.3792 75.2512H13.5637C10.5602 75.2512 7.90724 74.6609 5.60849 73.4801C3.30975 72.2994 1.67885 70.5942 0.719505 68.3616C0.23983 66.7524 0 65.6256 0 64.9753C0 63.366 0.479672 61.8167 1.43902 60.3243C2.39837 58.8319 3.75622 57.5673 5.51257 56.5304C7.52351 55.5684 9.51969 54.999 11.5011 54.8162ZM11.7889 55.5564C9.90343 55.7392 8.33896 56.4075 7.0918 57.5613C5.84465 58.715 5.11038 60.0726 4.88899 61.6279H20.7514V61.0045C20.7514 59.3953 19.9618 58.0857 18.3789 57.0728C16.796 56.0599 14.9695 55.5534 12.8922 55.5534H11.7889V55.5564Z"
          fill={props.shadow ? "#134074" : "url(#paint19_linear_1097_80379)"}
        />
        <path
          d="M44.0378 54.9328C45.7942 55.1665 47.5357 55.595 49.2626 56.2184C49.2294 57.1264 48.8161 58.3072 48.0154 59.7606C47.3771 58.49 46.2111 57.4441 44.5175 56.6289C42.8239 55.8108 41.0675 55.4033 39.2447 55.4033C36.8796 55.4033 35.0605 55.9996 33.7802 57.1923C32.9795 58.0224 32.581 58.8795 32.581 59.7606C32.581 60.7975 33.2673 61.6935 34.6399 62.4457C37.9607 63.2249 41.2372 63.6654 44.4621 63.7703C46.189 63.8482 47.6317 64.3816 48.7977 65.3675C49.9636 66.3535 50.5466 67.4953 50.5466 68.7929C50.5466 69.5181 50.34 70.2463 49.923 70.9715C47.687 73.8274 43.8681 75.2539 38.4699 75.2539C37.577 75.2539 36.3298 75.203 34.7321 75.0981C31.153 74.7864 28.6292 74.1901 27.1606 73.309L28.8395 69.6889C29.7657 72.2572 32.5773 73.8275 37.2744 74.3968L42.0195 74.28C43.2666 74.1241 44.4326 73.5937 45.5174 72.6857C46.6022 71.7777 47.1483 70.8307 47.1483 69.8447C47.1483 68.9637 46.7166 68.2115 45.8532 67.5882C45.1816 67.1746 44.2703 66.8749 43.1227 66.6921L35.4553 65.7961C33.7285 65.4844 32.2378 64.8821 30.9759 63.986C29.714 63.09 29.083 62.0202 29.083 60.7765C29.083 60.4648 29.2122 59.9464 29.4668 59.2182C29.9464 58.1273 30.7767 57.2403 31.9574 56.551C33.1381 55.8648 34.4074 55.3913 35.7653 55.1305C37.1231 54.8728 38.5843 54.741 40.1488 54.741C40.6579 54.741 41.3775 54.7739 42.3036 54.8369C43.2408 54.8998 43.8164 54.9328 44.0378 54.9328Z"
          fill={props.shadow ? "#134074" : "url(#paint20_linear_1097_80379)"}
        />
        <path
          d="M58.3206 47.6138V59.9515C58.8962 58.3422 60.1249 57.0716 62.0104 56.1366C63.8959 55.2016 65.9401 54.7371 68.1429 54.7371C69.9952 54.7371 71.7146 55.0667 73.2938 55.729C74.8768 56.3913 76.0501 57.3563 76.8176 58.6269C77.4227 60.0294 77.7585 61.2491 77.8249 62.286V72.6788C77.9836 73.0954 78.2714 73.4909 78.6883 73.8655C79.1016 74.2431 79.5517 74.4949 80.0314 74.6237L71.7404 74.7016C72.8917 74.2341 73.6075 73.5329 73.8953 72.6009C74.2458 69.5142 74.4229 67.3206 74.4229 66.023C74.4229 63.2479 73.9912 60.8086 73.1278 58.7048C72.4895 57.7968 71.5781 57.0836 70.3973 56.5651C69.2166 56.0467 67.9842 55.786 66.7075 55.786C64.7925 55.786 63.2244 56.2265 62.0104 57.1106C61.0511 57.8897 60.3316 58.588 59.8556 59.2113C58.7708 61.1053 58.2247 63.4547 58.2247 66.2567C58.2247 66.958 58.4018 68.9568 58.7523 72.2503C58.7523 73.392 59.4386 74.1562 60.8112 74.5458L60.7153 74.7796L52.4243 74.7016V74.39C52.6789 74.366 53.0959 74.2491 53.6715 74.0393C54.439 73.3141 54.8707 72.5859 54.9666 71.8607V51.1949C54.9666 50.4697 54.664 49.8973 54.0552 49.4838C53.8633 49.4328 53.4944 49.304 52.952 49.0942L52.904 49.0163C52.8708 48.9653 52.856 48.9264 52.856 48.8994L58.3206 47.6138Z"
          fill={props.shadow ? "#134074" : "url(#paint21_linear_1097_80379)"}
        />
        <path
          d="M88.6998 50.688L88.8916 50.9996V56.214H93.4928L93.1091 57.188L88.8916 57.2659V70.149C89.0835 71.3687 89.755 72.5884 90.9026 73.8081C91.6996 74.1468 92.2604 74.3415 92.5814 74.3925L92.3896 74.7042C91.5889 74.86 90.6959 74.9379 89.7071 74.9379C88.8437 74.9379 87.9802 74.86 87.1205 74.7042L86.401 74.3535C85.7922 73.5234 85.4896 72.6933 85.4896 71.8632V57.2689L81.9917 57.191V56.7624C84.962 55.9563 87.198 53.9335 88.6998 50.688Z"
          fill={props.shadow ? "#134074" : "url(#paint22_linear_1097_80379)"}
        />
        <path
          d="M104.85 54.7383H106.385C107.662 54.7383 108.924 54.8432 110.171 55.05C111.418 55.2567 112.621 55.5834 113.791 56.0239C114.957 56.4644 115.89 57.0938 116.595 57.9119C117.296 58.73 117.65 59.683 117.65 60.7738C117.458 63.6537 117.362 65.1071 117.362 65.1341C117.362 67.1569 117.584 69.5304 118.034 72.2575C118.159 73.1385 118.768 73.9836 119.853 74.7868H115.444C114.709 73.5401 114.34 72.3474 114.34 71.2056C113.381 72.5302 112.078 73.5341 110.437 74.2234C108.791 74.9096 106.979 75.2542 104.998 75.2542L100.397 74.553C98.7657 74.2174 97.4263 73.561 96.371 72.5871C95.3157 71.6131 94.7881 70.5313 94.7881 69.3386C94.7881 68.3796 95.2198 67.3787 96.0832 66.3418C98.5443 63.9804 103.064 62.7996 109.643 62.7996C110.123 62.7996 110.89 62.8116 111.942 62.8386C112.997 62.8655 113.732 62.8775 114.145 62.8775C114.082 61.1903 113.809 59.7639 113.329 58.5952C112.85 57.4264 111.89 56.5573 110.455 55.988C109.208 55.4965 107.85 55.2477 106.381 55.2477C104.466 55.2477 102.684 55.6943 101.039 56.5903C99.393 57.4863 98.2343 58.6611 97.5628 60.1115L96.1718 56.7641L96.2677 56.4524C99.2121 55.4905 102.072 54.9211 104.85 54.7383ZM113.861 63.6897H111.082C103.828 63.6897 99.8984 65.4158 99.2933 68.8651C99.2933 68.97 99.2601 69.1378 99.1974 69.3716C99.1347 69.6053 99.1015 69.7612 99.1015 69.8391C99.1015 71.2416 99.8025 72.3504 101.208 73.1655C102.614 73.9836 104.241 74.3912 106.097 74.3912C106.481 74.3912 107.23 74.3282 108.348 74.1964C109.241 73.9626 110.09 73.639 110.887 73.2224C112.709 72.1855 113.794 70.5882 114.145 68.4366V64.0762L113.861 63.6897Z"
          fill={props.shadow ? "#134074" : "url(#paint23_linear_1097_80379)"}
        />
        <path
          d="M136.427 55.0881L134.844 58.6303C134.236 58.1119 133.472 57.8511 132.546 57.8511C131.778 57.8511 131.051 58.0639 130.365 58.4924C129.679 58.921 129.144 59.4844 128.76 60.1856C127.896 61.666 127.45 62.4422 127.417 62.5201V70.8871C127.417 71.6903 127.616 72.4364 128.015 73.1257C128.413 73.812 129.044 74.2345 129.907 74.3903H130.339L130.243 74.7799H121.233L121.137 74.4683C121.775 74.3664 122.269 74.2225 122.624 74.0397C123.295 73.3145 123.742 72.0409 123.967 70.2248V58.6273C123.967 58.5494 123.959 58.4085 123.945 58.1988C123.93 57.992 123.919 57.8631 123.919 57.8092C123.502 57.1349 122.72 56.7064 121.572 56.5236L127.513 55.2379V60.0238C128.247 58.7532 129.372 57.6114 130.893 56.5985C132.409 55.5856 133.985 55.0791 135.612 55.0791H136.427V55.0881Z"
          fill={props.shadow ? "#134074" : "url(#paint24_linear_1097_80379)"}
        />
        <path
          d="M149.606 54.8162C149.765 54.8162 150.012 54.8042 150.348 54.7772C150.684 54.7503 150.931 54.7383 151.089 54.7383C154.026 54.7383 156.768 55.4515 159.307 56.878C161.845 58.3045 163.258 60.1355 163.546 62.3651L142.607 62.443C142.477 63.3271 142.415 64.0643 142.415 64.6606C142.415 68.6553 143.868 71.4573 146.776 73.0666C148.47 73.9236 150.241 74.3522 152.097 74.3522C153.982 74.3522 155.816 73.9297 157.609 73.0876C159.399 72.2455 160.786 71.1367 161.779 69.7611C161.808 69.7102 162.306 68.7872 163.266 66.9981L163.985 67.5825C163.472 69.0869 162.602 70.4294 161.373 71.6101C160.144 72.7909 158.65 73.7049 156.893 74.3552L152.484 75.2512H151.669C148.665 75.2512 146.012 74.6609 143.713 73.4801C141.415 72.2994 139.784 70.5942 138.824 68.3616C138.345 66.7524 138.105 65.6256 138.105 64.9753C138.105 63.366 138.585 61.8167 139.544 60.3243C140.503 58.8319 141.861 57.5673 143.618 56.5304C145.628 55.5684 147.625 54.999 149.606 54.8162ZM149.894 55.5564C148.008 55.7392 146.444 56.4075 145.197 57.5613C143.95 58.715 143.215 60.0726 142.994 61.6279H158.856V61.0045C158.856 59.3953 158.067 58.0857 156.484 57.0728C154.901 56.0599 153.075 55.5534 150.997 55.5534H149.894V55.5564Z"
          fill={props.shadow ? "#134074" : "url(#paint25_linear_1097_80379)"}
        />
        <path
          d="M181.231 54.7383C183.563 54.7383 185.647 55.2447 187.485 56.2546C189.322 57.2676 190.433 58.6551 190.817 60.4202C191.167 65.1671 191.344 68.3856 191.344 70.0728V72.68C191.695 73.5611 192.429 74.2623 193.547 74.7808H185.064C186.374 74.1334 187.171 73.0306 187.459 71.4723C187.585 69.7072 187.651 68.3197 187.651 67.3068C187.651 65.0502 187.474 62.7007 187.123 60.2613C186.836 58.8588 186.027 57.765 184.703 56.9739C183.378 56.1827 181.869 55.7872 180.175 55.7872C178.131 55.7872 176.356 56.3595 174.855 57.4983C172.715 59.5991 171.645 62.6348 171.645 66.6055C171.645 67.7473 171.788 69.6293 172.076 72.2485C172.172 73.1834 172.825 73.9476 174.043 74.544C174.072 74.571 174.117 74.6099 174.161 74.6609C174.209 74.7118 174.25 74.7508 174.283 74.7778H165.752C167.508 73.8697 168.386 72.5721 168.386 70.8849V59.0536C168.386 58.9518 168.346 58.757 168.268 58.4693C168.187 58.1846 168.147 58.0018 168.147 57.9239C167.667 57.3545 167.077 56.911 166.376 56.5993L166.471 56.2097L171.792 55.2358V60.0995C172.43 58.4124 173.604 57.0968 175.312 56.1498C177.021 55.2118 178.995 54.7383 181.231 54.7383Z"
          fill={props.shadow ? "#134074" : "url(#paint26_linear_1097_80379)"}
        />
        <path
          d="M205.001 54.7383H206.536C207.812 54.7383 209.074 54.8432 210.322 55.05C211.569 55.2567 212.772 55.5834 213.938 56.0239C215.104 56.4644 216.037 57.0938 216.742 57.9119C217.443 58.73 217.797 59.683 217.797 60.7738C217.605 63.6537 217.509 65.1071 217.509 65.1341C217.509 67.1569 217.731 69.5304 218.181 72.2575C218.306 73.1385 218.915 73.9836 220 74.7868H215.591C214.856 73.5401 214.487 72.3474 214.487 71.2056C213.528 72.5302 212.225 73.5341 210.584 74.2234C208.938 74.9096 207.126 75.2542 205.145 75.2542L200.544 74.553C198.913 74.2174 197.573 73.561 196.518 72.5871C195.463 71.6131 194.935 70.5313 194.935 69.3386C194.935 68.3796 195.367 67.3787 196.23 66.3418C198.691 63.9804 203.211 62.7996 209.79 62.7996C210.27 62.7996 211.037 62.8116 212.089 62.8386C213.144 62.8655 213.879 62.8775 214.292 62.8775C214.229 61.1903 213.956 59.7639 213.476 58.5952C212.997 57.4264 212.037 56.5573 210.602 55.988C209.355 55.4965 207.997 55.2477 206.528 55.2477C204.613 55.2477 202.831 55.6943 201.186 56.5903C199.54 57.4863 198.381 58.6611 197.71 60.1115L196.319 56.7641L196.415 56.4524C199.359 55.4905 202.219 54.9211 205.001 54.7383ZM214.008 63.6897H211.229C203.975 63.6897 200.049 65.4158 199.44 68.8651C199.44 68.97 199.407 69.1378 199.344 69.3716C199.282 69.6053 199.248 69.7612 199.248 69.8391C199.248 71.2416 199.95 72.3504 201.355 73.1655C202.761 73.9836 204.388 74.3912 206.244 74.3912C206.628 74.3912 207.377 74.3282 208.495 74.1964C209.388 73.9626 210.237 73.639 211.034 73.2224C212.856 72.1855 213.941 70.5882 214.292 68.4366V64.0762L214.008 63.6897Z"
          fill={props.shadow ? "#134074" : "url(#paint27_linear_1097_80379)"}
        />
        <defs>
          <linearGradient
            id="paint0_linear_1097_80379"
            x1="130.416"
            y1="4.23707"
            x2="137.114"
            y2="4.23707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1097_80379"
            x1="121.162"
            y1="4.23707"
            x2="127.86"
            y2="4.23707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1097_80379"
            x1="81.8542"
            y1="6.63209"
            x2="88.5521"
            y2="6.63209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1097_80379"
            x1="140.031"
            y1="4.23707"
            x2="146.729"
            y2="4.23707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1097_80379"
            x1="140.031"
            y1="36.9009"
            x2="146.729"
            y2="36.9009"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1097_80379"
            x1="130.047"
            y1="21.6267"
            x2="143.978"
            y2="21.6267"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1097_80379"
            x1="120.73"
            y1="21.6267"
            x2="134.661"
            y2="21.6267"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1097_80379"
            x1="111.414"
            y1="21.6267"
            x2="125.345"
            y2="21.6267"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1097_80379"
            x1="101.4"
            y1="22.8046"
            x2="113.903"
            y2="22.8046"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1097_80379"
            x1="141.223"
            y1="17.2962"
            x2="155.155"
            y2="17.2962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1097_80379"
            x1="91.0897"
            y1="29.3958"
            x2="102.761"
            y2="29.3958"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1097_80379"
            x1="83.6232"
            y1="22.8046"
            x2="96.128"
            y2="22.8046"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1097_80379"
            x1="85.9937"
            y1="35.3313"
            x2="88.6891"
            y2="35.3313"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1097_80379"
            x1="82.0874"
            y1="35.3313"
            x2="84.7828"
            y2="35.3313"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1097_80379"
            x1="73.5641"
            y1="22.8046"
            x2="86.0677"
            y2="22.8046"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1097_80379"
            x1="65.1578"
            y1="17.2962"
            x2="79.0893"
            y2="17.2962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_1097_80379"
            x1="112.528"
            y1="8.1816"
            x2="115.223"
            y2="8.1816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_1097_80379"
            x1="108.621"
            y1="8.1816"
            x2="111.316"
            y2="8.1816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_1097_80379"
            x1="105.508"
            y1="1.77124"
            x2="118.57"
            y2="1.77124"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_1097_80379"
            x1="-0.974717"
            y1="64.8756"
            x2="27.4973"
            y2="64.8756"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_1097_80379"
            x1="26.2799"
            y1="64.8783"
            x2="52.0078"
            y2="64.8783"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_1097_80379"
            x1="51.3846"
            y1="61.0388"
            x2="81.7563"
            y2="61.0388"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_1097_80379"
            x1="81.5585"
            y1="62.6721"
            x2="94.2114"
            y2="62.6721"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_1097_80379"
            x1="93.8441"
            y1="64.8771"
            x2="121.419"
            y2="64.8771"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_1097_80379"
            x1="120.561"
            y1="64.8151"
            x2="137.383"
            y2="64.8151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_1097_80379"
            x1="137.13"
            y1="64.8756"
            x2="165.602"
            y2="64.8756"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_1097_80379"
            x1="164.705"
            y1="64.6431"
            x2="195.284"
            y2="64.6431"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_1097_80379"
            x1="193.991"
            y1="64.8771"
            x2="221.566"
            y2="64.8771"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#57779C" stop-opacity="0.66" />
            <stop offset="1" stop-color="#91C8E4" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
