import React, { useEffect } from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
import InputGroup from "../../UI/InputGroup/InputGroup";
import Button from "../../UI/Button/Button";
import { useState } from "react";
import { Radio } from "@mui/material";
import { vouchersEshtreanaService } from "../../../../services/VouchersEshtreana";
import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
import { useNavigate } from "react-router-dom";
import Modal from "../../UI/Modal/Modal";
import SuccessPopup from "./SuccessPopup";
import { errorActions } from "../../../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
export default function StartDealFoem(props) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loadingForm, setLoadingForm] = useState(false);

  const [data, setData] = useState({
    byDate: "By Date",
    days: null,
    weeks: null,
    time: 0,
    startDate: null,
    endDate: null,
  });
  const [dataErr, setDataErr] = useState({
    days: false,
    weeks: false,
    time: true,
    startDate: false,
    endDate: false,
  });

  let handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.name === "days" || e.target.name === "weeks") {
      var isANumber = isNaN(e.target.value) === false;

      isANumber
        ? setDataErr((prev) => ({ ...prev, [e.target.name]: true }))
        : setDataErr((prev) => ({ ...prev, [e.target.name]: false }));

      ((e.target.value.startsWith("0") && e.target.length > 1) ||
        // e.target.value === "0" ||
        e.target.value.startsWith("-") ||
        e.target.value === "" ||
        e.target.value.startsWith("+")) &&
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
    if (e.target.name === "startDate") {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000)
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (data.endDate)
        if (new Date(e.target.value) > new Date(data.endDate)) {
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        } else {
          if (new Date(data.endDate) > new Date(e.target.value))
            setDataErr((prev) => ({ ...prev, endDate: true }));
        }
    } else if (e.target.name === "endDate") {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000)
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (data.startDate) {
        if (new Date(e.target.value) < new Date(data.startDate)) {
          setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
        } else {
          if (new Date(data.startDate) > Date.now() - 1 * 24 * 60 * 60 * 1000)
            setDataErr((prev) => ({ ...prev, startDate: true }));
        }
      }
      if (new Date(e.target.value) > new Date(props.voucher.expireDate)) {
        setDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }
  };
  let startDeal = async () => {
    setServerError(false);
    let sendData;
    setShowError(true);

    if (
      (data.byDate === "By Duration" &&
        dataErr.weeks &&
        dataErr.days &&
        dataErr.time) ||
      (data.byDate === "By Date" && dataErr.startDate && dataErr.endDate)
    ) {
      if (data.byDate === "By Duration") {
        sendData = {
          byDate: false,
          weeks: data.weeks,
          days: data.days,
          time: data.time,
        };
      } else {
        sendData = {
          byDate: true,
          startDate: data.startDate,
          endDate: data.endDate,
        };
      }

      if (props.data.quantity) {
        sendData = {
          ...sendData,
          quantity: props.data.quantity,
          buyingPrice: props.data.buyingPrice,
          voucherPrice: props.data.voucherPrice,

          expireDate: props.data.expireDate,
          voucherId: props.id,
        };
      }
      let response = props.data.quantity
        ? await vouchersEshtreanaService.updateVoucherRequest({
            ...sendData,
            sellingPrice: props.sellingPrice,
          })
        : await vouchersEshtreanaService.startVocherDeal(props.id, sendData);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          props.setShowForm(false);
          history("/account/voucher", { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadingForm &&
      setTimeout(() => {
        setLoadingForm(false);
      }, 1);
  }, [loadingForm]);
  return (
    <>
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Voucher Deal is Set Successfully`}
          />
        </Modal>
      )}

      <div className="row mx-0 px-0 ">
        <div className={`col-10 px-0 ${classes.title}`}>
          Schedule Voucher Deal
        </div>
        <div
          onClick={() => props.setShowForm(false)}
          className="cursor col-2 px-0 text-end"
        >
          <Close color="#134074" />
        </div>
      </div>

      {loadingForm ? null : (
        <>
          <>
            <div className="row px-0 mx-0">
              <div className="col-12 px-0 pe-2">
                <div className={classes.input}>
                  <p>Deal Termination</p>
                  <div className={`row p-0 m-0 ${classes["input--options"]}`}>
                    {["By Date", "By Duration"].map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setData((prev) => ({
                            days: null,
                            weeks: null,
                            time: 0,
                            startDate: null,
                            endDate: null,
                            byDate: item,
                          }));
                          // setChange((prev) => prev + 1);
                          setLoadingForm(true);
                        }}
                        className="w-auto px-0 d-inline d-flex align-items-center pe-3"
                      >
                        <Radio
                          checked={data.byDate === item ? true : false}
                          className="h-auto px-0 py-0"
                        />
                        <label className="h-auto" style={{ color: "#134074" }}>
                          &nbsp; {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {data.byDate === "By Duration" ? (
              <div className="row px-0 mx-0">
                <div className="col-4 px-0 pe-2">
                  <div className={classes.input}>
                    <InputGroup
                      //   default={props.data && props.data.title}
                      name="weeks"
                      onChange={(e) => {
                        handleChange(e);
                        setData((prev) => ({ ...prev, weeks: e.target.value }));
                      }}
                      default={data?.weeks}
                      error={showError && !dataErr.weeks}
                      errorMsg={
                        data.weeks ? "Provide a Valid Weeks" : "Provide a Weeks"
                      }
                      // name="sectionTitle"
                      value={"Weeks"}
                      underLineText="when users cant join anymore"
                    />
                  </div>
                </div>
                <div className="col-4 px-0 ps-2 pe-2">
                  <div className={classes.input}>
                    <InputGroup
                      // default={props.data && props.data.title}
                      default={data?.days}
                      name="days"
                      // onChange={(e) => setindustry(e.target.value)}
                      error={showError && !dataErr.days}
                      errorMsg={
                        data.days ? "Provide a Valid Days" : "Provide a Days"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setData((prev) => ({ ...prev, days: e.target.value }));
                      }}
                      value="Days"
                      underLineText={"when users cant join anymore"}
                    />
                  </div>
                </div>
                <div className="col-4 px-0 ps-2">
                  <div className={classes.input}>
                    <InputGroup
                      name="time"
                      type="dropdown"
                      default={data.time}
                      options={[
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                      ]}
                      error={showError && !dataErr.time}
                      errorMsg={
                        data.time ? "provide a Valid Hour" : "Provide an Hour"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setData((prev) => ({ ...prev, time: e.target.value }));
                      }}
                      // default={data?.time}
                      value="Time (Hour)"
                      underLineText={"when users cant join anymore"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row px-0 mx-0">
                <div className="col-6 px-0 pe-2">
                  <div className={classes.input}>
                    <InputGroup
                      //   default={props.data && props.data.title}
                      name="startDate"
                      error={showError && !dataErr.startDate}
                      errorMsg={
                        data.startDate
                          ? "Provide a Valid Start Date & make sure it does not exceed Voucher Expiry Date"
                          : "Provide a Start Date"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setData((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        }));
                      }}
                      type="date"
                      value={"Deal Start Date"}
                      underLineText="when users can Buy the Voucher"
                    />
                  </div>
                </div>
                <div className="col-6 px-0 ps-2 ">
                  <div className={classes.input}>
                    <InputGroup
                      name="endDate"
                      error={showError && !dataErr.endDate}
                      errorMsg={
                        data.endDate
                          ? "Provide a Valid End Date & make sure it does not exceed Voucher Expiry Date"
                          : "Provide a End Date"
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setData((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }));
                      }}
                      type="date"
                      value={"Deal End Date"}
                      underLineText="when users can't join anymore"
                    />
                  </div>
                </div>
              </div>
            )}
          </>

          {serverError && (
            <div className="text-center">
              <span className="errorMsg text-cenetr">{serverErrorMsg}</span>
            </div>
          )}

          <div className="row mx-0 px-0 mb-0 pb-0">
            <div className="col-4 ms-auto px-0">
              <div>
                {loading ? (
                  <Button
                    className={"w-auto px-2 py-0 ms-auto text-end"}
                    type="normal"
                    text={
                      <div className="text-center px-5 my-0 py-0">
                        <span className="spinner-border text-white spinner-border-sm"></span>
                      </div>
                    }
                  />
                ) : (
                  <Button
                    onClick={() => {
                      setLoading(true);
                      startDeal();
                    }}
                    color={"#D1E6FF"}
                    borderColor={"#134074"}
                    fontColor={"#134074"}
                    className={"w-auto px-2 ms-auto text-end"}
                    type="normal"
                    text={`Start Deal`}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
