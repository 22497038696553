import { Radio } from "@mui/joy";
import { useDispatch } from "react-redux";
import classes from "./ColdDeals.module.scss";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Edit from "../../components/Reusable/SVG/Edit";
import Plus from "../../components/Reusable/SVG/Plus";
import Img from "../../components/Reusable/Common/Img";
import { Checkbox, Fab, Skeleton } from "@mui/material";
import Close from "../../components/Reusable/SVG/Close";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import { errorActions } from "../../store/reducers/errorSlice";
import { mainDataHelper } from "../../Helper/MainData/MainData";
import { productService } from "../../services/ProductServices";
import Button from "../../components/Reusable/UI/Button/Button";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import DealsAvtiveL from "../../components/Reusable/SVG/DealsAvtiveL";
import Section from "../../components/Reusable/Common/Section/Section";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import { coldDealsEshtreanaService } from "../../services/ColdDealsEshtreana";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import AddSection from "../../components/Reusable/Common/AddMemberCard/AddSection";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import DropDownSearch from "../../components/Reusable/UI/DropDownSearch/DropDownSearch";
export default function ColdDealsFrom() {
  //----------------------Declarations---------------------------------
  const history = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [areas, setAreas] = useState([]);
  const [cities, setCitites] = useState([1]);
  const [loading1, setLoading1] = useState(false);
  const [products, setProducts] = useState([]);
  const [changed, setChanged] = useState(false);
  const [districts, setDistrict] = useState([]);
  const [option, setOption] = useState("Main Data");
  const [showError, setShowError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [productsNames, setProductsNames] = useState([]);
  const [customerTerms, setCustomerTerms] = useState([]);
  const [supplierTerms, setSupplierTerms] = useState([]);
  const [deliveryTerms, setDeliveyryTerms] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedDisrictFinal, setSelectedDistrict] = useState([[]]);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [showEditFormSupplier, setShowEditFormSupplier] = useState(false);
  const [showEditFormCustomer, setShowEditFormCustomer] = useState(false);
  const [showEditFormDelivery, setShowEditFormDelivery] = useState(false);
  const [addDeliverySectionForm, setAddDeliverySectionForm] = useState(false);
  const [addCustomerSectionForm, setAddCustomerSectionForm] = useState(false);
  const [addSupplierSectionForm, setAddSupplierSectionForm] = useState(false);
  const [sectionData, setSectionData] = useState({
    title_en: null,
    title_ar: null,
    content_en: null,
    content_ar: null,
  });
  const [dealData, setDealData] = useState({
    name_en: null,
    name_ar: null,
    quantity: null,
    decreasePercentage: null,
    startingPrice: null,
    profitPercentage: null,
    endDate: null,
    customerType: "Both",
    bigDeal: true,
    allKSA: true,
    quantities: [],
    color: "#ffffff",
    expireDate: null,
    size: null,
    unit: null,
    deposit: null,
    deliveryResponsibility: "Eshtarena",
    deliveryFees: null,
  });
  const [dealDataErr, setDealDataErr] = useState({
    name_en: false,
    name_ar: false,
    quantity: false,
    decreasePercentage: false,
    startingPrice: false,
    profitPercentage: false,
    endDate: false,
    customerType: true,
    bigDeal: true,
    allKSA: true,
    deposit: false,
    quantities: false,
    unit: false,
    color: true,
    expireDate: false,
    size: false,
    deliveryResponsibility: true,
    deliveryFees: false,
    supplierTerms: true,
    customerTerms: true,
    deliveryTerms: true,
  });
  //----------------------Functions---------------------------------
  let handleCreateDeal = async () => {
    setShowError(true);
    let finalArea = [];
    let finalSupplierTerms = [];
    let finalCustomerTerms = [];
    let finalDeliveryTerms = [];
    let temp = {
      city: "",
      district: [],
    };
    let ind = -1;
    if (!dealData.workingAreas) {
      areas.map((item, index) => {
        if (selectedCities.includes(item.city_en)) {
          ind++;
          temp.city = item._id;
          finalArea[ind] = [];
          item.district &&
            item.district.map((district, i) => {
              if (selectedDisrictFinal[ind]) {
                if (selectedDisrictFinal[ind][0] === "all") {
                  temp.district.push(district._id);
                } else if (
                  selectedDisrictFinal[ind].includes(district.district_en)
                ) {
                  temp.district.push(district._id);
                }
              }
            });
          finalArea[ind] = temp;
          temp = {
            city: "",
            district: [],
          };
        }
      });
    }

    supplierTerms.map((item) => {
      if (item.selected) {
        finalSupplierTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });

    customerTerms.map((item) => {
      if (item.selected) {
        finalCustomerTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });
    deliveryTerms.map((item) => {
      if (item.selected) {
        finalDeliveryTerms.push({
          title_en: item.title_en,
          title_ar: item.title_ar,
          desc_en: item.content_en,
          desc_ar: item.content_ar,
        });
      }
    });

    finalSupplierTerms.length === 0 &&
      setDealDataErr((prev) => ({ ...prev, supplierTerms: false }));
    finalCustomerTerms.length === 0 &&
      setDealDataErr((prev) => ({ ...prev, customerTerms: false }));
    finalDeliveryTerms.length === 0 &&
      setDealDataErr((prev) => ({ ...prev, deliveryTerms: false }));
    setShowError(true);
    if (
      dealDataErr.name_ar &&
      dealDataErr.name_en &&
      dealDataErr.unit &&
      ((dealData.bigDeal && dealDataErr.deposit) || !dealData.bigDeal) &&
      dealDataErr.deliveryFees &&
      (dealData.allKSA || !dealData.allKSA || selectedCities.length > 0) &&
      selectedProduct._id &&
      finalCustomerTerms.length !== 0 &&
      finalSupplierTerms.length !== 0 &&
      finalDeliveryTerms.length !== 0
    ) {
      let quantities = [];
      dealData.quantities.map((item) => quantities.push({ quantity: item }));

      let data1 = {
        title_en: dealData.name_en,
        title_ar: dealData.name_ar,
        customerType: dealData.customerType,
        isBigDeal: dealData.bigDeal,
        allKsa: dealData.allKSA,
        product: selectedProduct._id,
        quantity: dealData.quantity,
        endDate: dealData.endDate,
        unit: dealData.unit,
        startingPrice: dealData.startingPrice,
        decreasePercentage: dealData.decreasePercentage,
        profitPercentage: dealData.profitPercentage,
        supplierPaymentTerms: finalSupplierTerms,
        customerPaymentTerms: finalCustomerTerms,
      };

      if (
        selectedProduct.variants.includes("colors") ||
        selectedProduct.variants.includes("Colors")
      )
        data1.color = dealData.color;
      if (
        selectedProduct.variants.includes("sizes") ||
        selectedProduct.variants.includes("Sizes")
      )
        data1.size = dealData.size;
      if (
        selectedProduct.variants.includes("expireDate") ||
        selectedProduct.variants.includes("ExpireDate")
      )
        data1.expireDate = dealData.expireDate;
      if (dealData.bigDeal) data1.deposit = parseInt(dealData.deposit);

      if (!dealData.allKSA) data1.cities = finalArea;

      data1.deliveryTerms = finalDeliveryTerms;
      data1.deliveryFees = dealData.deliveryFees;
      let response = await coldDealsEshtreanaService.createColdDeal(data1);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history(`/account/cold-deal`, { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
      }
    } else {
      let arr = [];
      //     Object.keys(dataErr)
      // .forEach(function eachKey(key) {
      //   if(dataErr[key]===false)
      //   arr.push(key);
      // });
      !dealDataErr.name_en && arr.push("English Deal Name ");
      !dealDataErr.name_ar && arr.push("Arabic Deal Name");
      !dealDataErr.deposit && dealData.bigDeal && arr.push("Deposit");
      dealData.workingAreas &&
        !dealDataErr.workingArease &&
        arr.push("Working Areas");
      !selectedProduct && arr.push("Product");
      !dealDataErr.quantity && arr.push("Quantity");
      !dealDataErr.unit && arr.push("Unit");
      !dealDataErr.decreasePercentage && arr.push("Decrease Percentage");
      !dealDataErr.startingPrice && arr.push("Starting Price");
      !dealDataErr.profitPercentage && arr.push("Profit Percentage");
      !dealDataErr.endDate && arr.push("End Date");
      !dealDataErr.deliveryFees && arr.push("Delivery Fees");
      finalDeliveryTerms.length === 0 && arr.push("Delivery Terms");
      finalCustomerTerms.length === 0 && arr.push("Cusomer Payment Terms");
      finalSupplierTerms.length === 0 && arr.push("Supplier Payment Terms");

      // setServerErrorMsg(`There a Missing / Incorrect Data are Required Must be Filled , Please Check Your Data .`);
      setServerErrorMsg(
        `There a Incorrect / Missing Data are Required [${[...arr]}]`
      );
      setServerError(true);
    }
    setLoadingButton(false);
  };
  let handleChange = (e) => {
    setDealData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setDealDataErr((prev) => ({
      ...prev,
      [e.target.name]: e.target.value !== "" ? true : false,
    }));
    if (e.target.name === "endDate" || e.target.name === "expireDate") {
      if (new Date(e.target.value) < Date.now() - 1 * 24 * 60 * 60 * 1000)
        setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (e.target.name === "endDate" && dealData.expireDate)
        if (e.target.value > dealData.expireDate)
          setDealDataErr((prev) => ({
            ...prev,
            endDate: false,
            expireDate: false,
          }));
        else
          setDealDataErr((prev) => ({
            ...prev,
            endDate: true,
            expireDate: true,
          }));
      if (e.target.name === "expireDate" && dealData.endDate)
        if (dealData.endDate > e.target.value)
          setDealDataErr((prev) => ({
            ...prev,
            endDate: false,
            expireDate: false,
          }));
        else
          setDealDataErr((prev) => ({
            ...prev,
            endDate: true,
            expireDate: true,
          }));
    }

    if (
      e.target.name === "deposit" ||
      e.target.name === "quantity" ||
      e.target.name === "startingPrice" ||
      e.target.name === "decreasePercentage" ||
      e.target.name === "profitPercentage"
    ) {
      (isNaN(e.target.value) ||
        parseFloat(e.target.value) <= 0 ||
        e.target.value.startsWith("-") ||
        e.target.value === "" ||
        e.target.value.startsWith("+")) &&
        setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      if (
        e.target.name === "decreasePercentage" ||
        e.target.name === "profitPercentage"
      ) {
        if (!(e.target.value >= 0 && e.target.value <= 100))
          setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));
      }
    }
    if (e.target.name === "deliveryFees") {
      (isNaN(e.target.value) ||
        (e.target.value.startsWith(0) && e.target.value.length !== 1) ||
        e.target.value.startsWith("-") ||
        e.target.value === "" ||
        e.target.value.startsWith("+")) &&
        setDealDataErr((prev) => ({ ...prev, [e.target.name]: false }));
    }
  };

  // TODO:Helper
  let getProducts = async (name) => {
    let response = await productService.getProducts(1, 10, `&search=${name}`);
    let arr = [];
    [...response.data.products].map((product) => {
      arr.push(product.name_en);
    });

    setProducts((prev) => [...products, ...response.data.products]);
    setLoading1(false);

    setProductsNames(arr);
    return arr;
  };
  useEffect(() => {
    getProducts();
  }, [page]);

  let getSingleProduct = async (id) => {
    let response = await productService.getSingleProduct(id);
    setSelectedProduct(response.data.product);
    setLoadingProduct(false);
  };

  let handleAddSection = (data) => {
    if (option === "Supplier Payment Terms") {
      if (sectionData.title_en) {
        let arr = [];
        supplierTerms.map((item1, index1) => {
          if (selectedIndex === index1) {
            arr.push({
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: sectionData.selected,
            });
          } else
            arr.push({
              title_en: item1.title_en,
              title_ar: item1.title_ar,
              content_en: item1.content_en,
              content_ar: item1.content_ar,
              selected: item1.selected,
            });
        });
        setSupplierTerms(arr);
      } else {
        setSupplierTerms((prev) => [
          ...prev,
          {
            title_en: data.titleEN,
            title_ar: data.titleAR,
            content_en: data.descriptionEN,
            content_ar: data.descriptionAR,
            selected: true,
          },
        ]);
      }
    } else {
      if (option === "Delivery Terms") {
        if (sectionData.title_en) {
          let arr = [];
          deliveryTerms.length > 0 &&
            deliveryTerms.map((item1, index1) => {
              if (selectedIndex === index1) {
                arr.push({
                  title_en: data.titleEN,
                  title_ar: data.titleAR,
                  content_en: data.descriptionEN,
                  content_ar: data.descriptionAR,
                  selected: sectionData.selected,
                });
              } else
                arr.push({
                  title_en: item1.title_en,
                  title_ar: item1.title_ar,
                  content_en: item1.content_en,
                  content_ar: item1.content_ar,
                  selected: item1.selected,
                });
            });
          setDeliveyryTerms(arr);
        } else {
          setDeliveyryTerms((prev) => [
            ...prev,
            {
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: true,
            },
          ]);
        }
      } else {
        if (sectionData.title_en) {
          let arr = [];
          customerTerms.map((item1, index1) => {
            if (selectedIndex === index1) {
              arr.push({
                title_en: data.titleEN,
                title_ar: data.titleAR,
                content_en: data.descriptionEN,
                content_ar: data.descriptionAR,
                selected: sectionData.selected,
              });
            } else
              arr.push({
                title_en: item1.title_en,
                title_ar: item1.title_ar,
                content_en: item1.content_en,
                content_ar: item1.content_ar,
                selected: item1.selected,
              });
          });
          setCustomerTerms(arr);
        } else {
          setCustomerTerms((prev) => [
            ...prev,
            {
              title_en: data.titleEN,
              title_ar: data.titleAR,
              content_en: data.descriptionEN,
              content_ar: data.descriptionAR,
              selected: true,
            },
          ]);
        }
      }
    }
  };
  //----------------------Use Effects---------------------------------

  useEffect(() => {
    let arr = [];
    selectedCities.map((item) => {
      areas.map((item1) => item1.city_en === item && arr.push(item1.district));
    });
    setDistrict(arr);
  }, [changed]);

  useEffect(() => {
    getProducts();
    mainDataHelper.getCities(setServerError, setServerErrorMsg, setAreas);
    mainDataHelper.getSupplierTerms(
      setServerError,
      setServerErrorMsg,
      setSupplierTerms
    );
    mainDataHelper.getCustomerTerms(
      setServerError,
      setServerErrorMsg,
      setCustomerTerms
    );
    mainDataHelper.getDeliveryTerms(
      setServerError,
      setServerErrorMsg,
      setDeliveyryTerms
    );
  }, []);

  useEffect(() => {
    setSelectedProduct(null);
    products.map((item) => {
      if (item.name_en === selectedProductName) {
        getSingleProduct(item._id);
        return;
      }
    });
  }, [selectedProductName]);

  useEffect(() => {
    if (serverError)
      setTimeout(() => {
        setServerError(false);
      }, 4000);
  }, [serverError]);
  return (
    <div className={`w-100 ${classes["cold-deals"]} `}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Deal Created Successfully`}
          />
        </Modal>
      )}

      {(addSupplierSectionForm ||
        addCustomerSectionForm ||
        addDeliverySectionForm ||
        showEditFormCustomer ||
        showEditFormDelivery ||
        showEditFormSupplier) && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            addSupplierSectionForm && setAddSupplierSectionForm(false);
            addDeliverySectionForm && setAddDeliverySectionForm(false);
            addCustomerSectionForm && setAddCustomerSectionForm(false);
            showEditFormCustomer && setShowEditFormCustomer(false);
            showEditFormSupplier && setShowEditFormSupplier(false);
            showEditFormDelivery && setShowEditFormDelivery(false);
          }}
        >
          <AddSection
            data={
              (showEditFormCustomer ||
                showEditFormSupplier ||
                showEditFormDelivery) &&
              sectionData
            }
            handleSubmit={(data) => handleAddSection(data)}
            add={
              addCustomerSectionForm ||
              addSupplierSectionForm ||
              addDeliverySectionForm
            }
            edit={
              showEditFormCustomer ||
              showEditFormSupplier ||
              showEditFormDelivery
            }
            setShowForm={() => {
              addDeliverySectionForm && setAddDeliverySectionForm(false);

              addSupplierSectionForm && setAddSupplierSectionForm(false);
              addCustomerSectionForm && setAddCustomerSectionForm(false);
              showEditFormCustomer && setShowEditFormCustomer(false);
              showEditFormDelivery && setShowEditFormDelivery(false);
              showEditFormSupplier && setShowEditFormSupplier(false);
            }}
          />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowConfirmation(false);
          }}
        >
          <SuccessPopup message1={"The Deal Request is Created Successfully"} />
        </Modal>
      )}

      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setServerError(false);
          }}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      <CompanyNav />
      <section className="custom-container">
        <PageTitle text={"Deal preparation Request"} icon={<DealsAvtiveL />} />
        <section className={`${classes["main-section"]}`}>
          <div className={`row px-0 mx-0   ${classes.options}`}>
            <SelectOption
              onSelect={(val) => setOption(val)}
              default={option}
              options={[
                "Main Data",
                "Pricing Data",
                "Delivery Terms",
                "Customer Payment Terms",
                "Supplier Payment Terms",
              ]}
            />
          </div>
          {option === "Main Data" && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-lg-6 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <p>Customer Type</p>
                    <div className={`row p-0 m-0 ${classes["input--options"]}`}>
                      {["B2C", "B2B", "Both"].map((item, index) => (
                        <div className="w-auto cursor d-inline d-flex align-items-center ">
                          <Radio
                            checked={
                              dealData.customerType === item ? true : false
                            }
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                customerType: item,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                customerType: item,
                              }));
                            }}
                            className="h-auto  cursor"
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 px-0 ps-lg-2">
                  <div className={classes.input}>
                    <p>Big Deal</p>
                    <div className={`row p-0 m-0 ${classes["input--options"]}`}>
                      {["Yes", "No"].map((item, index) => (
                        <div
                          key={index}
                          className="w-auto cursor d-inline d-flex align-items-center "
                        >
                          <Radio
                            checked={
                              (dealData.bigDeal && item === "Yes") ||
                              (!dealData.bigDeal && item === "No")
                                ? true
                                : false
                            }
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                bigDeal: item === "Yes" ? true : false,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                bigDeal: item === "Yes" ? true : false,
                              }));
                            }}
                            className="h-auto cursor"
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row px-0 mx-0">
                <div className="col-xl-6 px-0 pe-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.name_en}
                      name="name_en"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.name_en}
                      errorMsg="Provide a Deal Name"
                      value={"Deal Name"}
                    />
                  </div>
                </div>
                <div className="col-xl-6 px-0 ps-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.name_ar}
                      name="name_ar"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.name_ar}
                      errorMsg="من فضلك سجل اسم العرض"
                      value={"اسم العرض"}
                    />
                  </div>
                </div>
              </div>
              {dealData.bigDeal && (
                <div className="row px-0 mx-0">
                  <div className="col-12 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={dealData.deposit}
                        error={showError && !dealDataErr.deposit}
                        errorMsg={
                          dealData.deposit
                            ? "Provide a Valid Deal Deposit"
                            : "Provide a Deal Deposit"
                        }
                        name="deposit"
                        onChange={(e) => handleChange(e)}
                        value={"Deposit"}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-lg-2">
                  <div className={classes.input}>
                    <p>Working Areas</p>
                    <div className={`row p-0 m-0 ${classes["input--options"]}`}>
                      {["All Egypt", "Different Areas"].map((item, index) => (
                        <div
                          key={index}
                          className="w-auto cursor d-inline d-flex align-items-center "
                        >
                          <Radio
                            checked={
                              (dealData.allKSA && item === "All Egypt") ||
                              (!dealData.allKSA && item === "Different Areas")
                                ? true
                                : false
                            }
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                allKSA: item === "All Egypt" ? true : false,
                              }));
                            }}
                            className="h-auto px-0 py-0"
                          />
                          <label
                            onClick={() => {
                              setDealData((prev) => ({
                                ...prev,
                                allKSA: item === "All Egypt" ? true : false,
                              }));
                            }}
                            className="h-auto  cursor"
                          >
                            &nbsp; {item}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {!dealData.allKSA &&
                (areas.length > 0 ? (
                  cities.map((item, index) => (
                    <div key={index} className="row px-0 mx-0">
                      <div className="col-lg-6 px-0 pe-lg-2">
                        <div className={classes.input}>
                          <InputGroup
                            type="dropdown"
                            city={true}
                            default={
                              selectedCities[index]
                                ? selectedCities[index]
                                : "select City"
                            }
                            options={["select City", ...areas]}
                            error={showError && selectedCities.length === 0}
                            errorMsg="Provide a City"
                            onChange={(e) => {
                              let arr = selectedCities;
                              let arr2 = [];
                              if (
                                selectedCities.includes(e.target.value) ||
                                e.target.value === "select City"
                              ) {
                                arr.map((item, ind) => {
                                  if (index !== ind) {
                                    arr2.push(item);
                                  }
                                });
                                setSelectedCities(arr2);
                                let arr1 = selectedDisrictFinal;
                                arr1[index] = [];
                                setSelectedDistrict(arr1);
                              } else {
                                arr[index] = e.target.value;
                                setSelectedCities((prev) => arr);
                                let arr1 = selectedDisrictFinal;
                                arr1[index] = [];
                                setSelectedDistrict(arr1);
                              }
                              setChanged((prev) => !prev);
                            }}
                            value={"Cities"}
                          />
                          {cities.length === index + 1 && (
                            <p
                              onClick={() => {
                                setSelectedCities((prev) => [...prev, []]);

                                setCitites((prev) => [...prev, cities.length]);
                              }}
                              className={`${classes["input--link"]}`}
                            >
                              <Plus color="#949494" /> Add City
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 px-0 ps-lg-2">
                        <div className={classes.input}>
                          <InputGroup
                            type="dropdown"
                            default={"select District"}
                            options={
                              districts[index] && [
                                "select District",
                                ...districts[index],
                              ]
                            }
                            district={true}
                            error={showError && selectedCities.length === 0}
                            errorMsg="Provide a District"
                            selected={selectedDisrictFinal[index]}
                            onChange={(e) => {
                              if (
                                e.target.value !== "select District" &&
                                !selectedDisrictFinal[index].includes(
                                  e.target.value
                                )
                              ) {
                                let arr = selectedDisrictFinal;
                                arr[index] = arr[index]
                                  ? [...arr[index], e.target.value]
                                  : [e.target.value];
                                setSelectedDistrict(arr);
                                setChanged((prev) => !prev);
                              }
                            }}
                            value={"Districts"}
                          />
                          <p className={`${classes["input--options"]}`}>
                            <Checkbox
                              checked={
                                selectedDisrictFinal[index] &&
                                selectedDisrictFinal[index][0] &&
                                selectedDisrictFinal[index][0] === "all"
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let arr = selectedDisrictFinal;
                                arr[index] = ["all"];
                                setSelectedDistrict(arr);
                                setChanged((prev) => !prev);
                              }}
                            />
                            All districts
                          </p>
                        </div>
                      </div>
                      <div className="row px-0 mx-0 mb-3 ">
                        {selectedDisrictFinal[index] &&
                          selectedDisrictFinal[index].length > 0 &&
                          selectedDisrictFinal[index].map((item, ind) => (
                            <div
                              key={ind}
                              className="w-auto d-inline mt-2 pe-lg-2 position-relative"
                            >
                              <div
                                onClick={() => {
                                  let arr = [];

                                  if (
                                    selectedDisrictFinal[index][0] === "all"
                                  ) {
                                    let arr = selectedDisrictFinal;
                                    arr[index] = [];
                                    setSelectedDistrict(arr);
                                    setChanged((prev) => !prev);
                                  } else {
                                    arr = [];
                                    arr = selectedDisrictFinal[index].filter(
                                      (item1, index) => {
                                        if (item1 !== item) return item1;
                                      }
                                    );
                                    if (arr.length > 0) {
                                      selectedDisrictFinal[index] = arr;
                                      setSelectedDistrict(selectedDisrictFinal);
                                    } else {
                                      selectedDisrictFinal[index] = [];
                                      setSelectedDistrict(selectedDisrictFinal);
                                    }
                                    setChanged((prev) => !prev);
                                  }
                                }}
                                style={{
                                  right: "10px",
                                  zIndex: 2,
                                  // top: "10px",
                                }}
                                className="text-end  cursor  h-100 align-items-center d-flex position-absolute"
                              >
                                <Close />
                              </div>
                              <Fab
                                style={{ zIndex: 1 }}
                                variant="extended"
                                className=" pe-5"
                              >
                                {item}
                              </Fab>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center pt-5 pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                      There are No Citites Added Yet
                    </div>
                  </div>
                ))}
              <div className="row px-0 mx-0">
                <div className="col-12 px-0  ">
                  <div className={classes.input}>
                    {/* <InputGroup
                      type="dropdown"
                      // autoComplete={true}
                      // loading={loadingProduct}

                      default={selectedProduct ? selectedProduct.name_en : ''}
                      options={['Select Product', ...productsNames]}
                      linkText={{
                        text: '+ Add Product',
                        fn: () => {
                          window.scrollTo({
                            top: 0,
                            left: 100,
                            behavior: 'smooth',
                          });
                          history('/account/product/new');
                        },
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.value === 'loadMore') {
                          setLoading1(true);
                          setPage((prev) => prev + 1);
                        } else {
                          if (e.target.value === 'Select Product') {
                            setSelectedProductName(null);
                            setSelectedProduct(null);
                          } else {
                            setLoadingProduct(true);
                            setSelectedProduct(null);
                            setSelectedProductName(e.target.value);
                          }
                        }
                      }}
                      pagination={loading1 || totalPages <= page ? false : true}
                      error={showError && !selectedProductName}
                      errorMsg="Provide a Product"
                      value={'Choose Product'}
                    /> */}
                    <DropDownSearch
                      getData={async (val) => {
                        const data = await getProducts(val);
                        return data;
                      }}
                      setResult={(res) => {
                        setLoadingProduct(true);
                        setSelectedProductName(res);
                        // setSelectedProductName(e.target.value);
                      }}
                      title="Product"
                      error={showError && !selectedProductName}
                      errorMsg="Provide a Product"
                    />
                  </div>
                </div>
              </div>
              {
                <>
                  {loadingProduct ? (
                    <div className="row px-0 mx-0">
                      <div className="col-xl-8 order-xl-1 order-2 px-0 mx-0">
                        {[0, 1, 2, 3, 4].map((index) => (
                          <div
                            key={index}
                            className={`row mx-0 px-0 ${classes.info}`}
                          >
                            <h1 className="w-lg-auto mx-auto d-inline px-0">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1.5rem", width: "300px" }}
                              />
                            </h1>
                            <h1 className="w-lg-auto mx-auto d-inline px-0">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1.5rem", width: "300px" }}
                              />
                            </h1>
                          </div>
                        ))}
                      </div>
                      <div className="col-xl-4 order-xl-2 order-1 p-5 pt-0 mx-0">
                        <div className={`${classes.info}`}>
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "223px", width: "284px" }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    selectedProduct && (
                      <div className="row px-0 mx-0">
                        <div className="col-xl-9 order-xl-1 order-2 px-0">
                          <div
                            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                          >
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Product Name :</p>
                                <span>{selectedProduct.name_en}</span>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Model :</p>
                                <span>{selectedProduct.model}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                          >
                            <div dir="ltr" className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p dir="ltr">اسم المنتج :</p>
                                <span dir="ltr">{selectedProduct.name_ar}</span>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Country of Origin :</p>
                                <span>{selectedProduct.country_en}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                          >
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Product Code :</p>
                                <span>{selectedProduct.uuid}</span>
                              </div>
                            </div>
                            <div className="col-lg-6  col-12 px-0">
                              <div dir="ltr" className={classes["single-item"]}>
                                <p dir="ltr" className="order-1">
                                  بلد المنشأ :
                                </p>
                                <span dir="ltr" className="order-2">
                                  {selectedProduct.country_ar}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                          >
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Category :</p>
                                <span>{selectedProduct.category.name_en}</span>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Factory Name :</p>
                                <span>{selectedProduct.factory_en}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                          >
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Sub Category :</p>
                                <span>
                                  {selectedProduct.subcategory.name_en}
                                </span>
                              </div>
                            </div>
                            <div dir="ltr" className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p dir="ltr">اسم المصنع :</p>
                                <span dir="ltr">
                                  {selectedProduct.factory_ar}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                          >
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Market Price :</p>
                                <span>{selectedProduct.price}</span>
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 px-0">
                              <div className={classes["single-item"]}>
                                <p>Batch Number :</p>
                                <span>{selectedProduct.patchNumber}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3  order-xl-2 order-1 mb-lg-0 mb-2 px-0 p-xl-0 ">
                          <div
                            className={`${classes.info}  h-100 align-items-center justify-content-center d-flex`}
                          >
                            <Img
                              style={{
                                objectFit: "cover",
                              }}
                              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/product/${selectedProduct.defaultImg}`}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              }
              {selectedProduct && (
                <>
                  <div className="row px-0 mx-0">
                    <div className="col-12 px-0 ">
                      <div className={classes.input}>
                        <p>Description</p>
                      </div>
                    </div>
                  </div>
                  <div className="row px-0 mx-0">
                    <div className="col-xl-6 pe-xl-2 px-0">
                      <Section
                        border={true}
                        text={selectedProduct.description_en}
                      />
                    </div>

                    <div className="col-xl-6 ps-xl-2 px-0  ms-auto">
                      <Section
                        border={true}
                        text={selectedProduct.description_ar}
                      />
                    </div>
                  </div>
                </>
              )}
              {selectedProduct && (
                <div className="row px-0 mx-0">
                  {(selectedProduct.variants.includes("colors") ||
                    selectedProduct.variants.includes("Colors")) && (
                    <div className="col-xl-4 px-0 pe-xl-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={dealData.color}
                          type="color"
                          name="color"
                          onChange={(e) => handleChange(e)}
                          error={showError && !dealDataErr.color}
                          errorMsg="Provide a Product Color"
                          value={"Product Color"}
                        />
                      </div>
                    </div>
                  )}
                  {(selectedProduct.variants.includes("ExpireDate") ||
                    selectedProduct.variants.includes("expireDate")) && (
                    <div className="col-xl-4 px-0 pe-xl-2 ps-xl-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={dealData.expireDate}
                          type="date"
                          name="expireDate"
                          onChange={(e) => handleChange(e)}
                          error={showError && !dealDataErr.expireDate}
                          errorMsg={
                            dealData.expireDate
                              ? "Provide an Valid Expiry Date"
                              : "Provide an Expiry Date"
                          }
                          value={"Expiry Date"}
                        />
                      </div>
                    </div>
                  )}
                  {(selectedProduct.variants.includes("sizes") ||
                    selectedProduct.variants.includes("Sizes")) && (
                    <div className="col-xl-4 px-0 ps-xl-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={dealData.size}
                          name="size"
                          onChange={(e) => handleChange(e)}
                          error={showError && !dealDataErr.size}
                          errorMsg="Provide a Product Size"
                          value={"Product Size"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                <div className="w-lg-auto  ms-auto d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption("Pricing Data");
                      }}
                      color={"#D1E6FF"}
                      borderColor={"#134074"}
                      fontColor={"#134074"}
                      className={"w-lg-auto  d-inline px-5  ms-auto "}
                      type="normal"
                      text={"Next"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {option === "Pricing Data" && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-xl-6 px-0 pe-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.quantity}
                      name="quantity"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.quantity}
                      errorMsg={`Provide ${
                        dealData.quantity ? "a Valid" : "a"
                      } Quantity`}
                      value={"Quantity"}
                    />
                  </div>
                </div>
                <div className="col-xl-6 px-0 ps-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.unit}
                      name="unit"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.unit}
                      errorMsg="Provide an Unit"
                      value={"Unit"}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-xl-6 px-0 pe-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.decreasePercentage}
                      name="decreasePercentage"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.decreasePercentage}
                      errorMsg={`Provide ${
                        dealData.decreasePercentage ? "a Valid" : "a"
                      } Decrease percentage`}
                      value={"Decrease percentage"}
                      underLineText="the amount by which the suppliers should decrease the price to join"
                    />
                  </div>
                </div>
                <div className="col-xl-6 px-0 ps-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.startingPrice}
                      name="startingPrice"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.startingPrice}
                      errorMsg={`Provide  ${
                        dealData.startingPrice ? "a Valid" : "a"
                      } Starting price`}
                      value={"Starting price"}
                      underLineText="Write the starting price for the first supplier"
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-xl-6 px-0 pe-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.profitPercentage}
                      name="profitPercentage"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.profitPercentage}
                      errorMsg={`Provide  ${
                        dealData.profitPercentagee ? "a Valid" : "a"
                      } Profit percentage`}
                      value={"Profit percentage"}
                    />
                  </div>
                </div>
                <div className="col-xl-6 px-0 ps-xl-2">
                  <div className={classes.input}>
                    <InputGroup
                      default={dealData.endDate}
                      name="endDate"
                      onChange={(e) => handleChange(e)}
                      error={showError && !dealDataErr.endDate}
                      errorMsg={`Provide ${
                        dealData.endDate ? "a Valid" : "an"
                      }  End Date`}
                      value={"End Date"}
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption("Main Data");
                      }}
                      className={"w-lg-auto  d-inline px-5   "}
                      type="reverse"
                      text={"Back"}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption("Delivery Terms");
                      }}
                      color={"#D1E6FF"}
                      borderColor={"#134074"}
                      fontColor={"#134074"}
                      className={"w-lg-auto  d-inline px-5  ms-auto "}
                      type="normal"
                      text={"Next"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {option === "Delivery Terms" && (
            <>
              <>
                <div className="row  pt-0 px-0 mx-0">
                  <div className="col-12 px-0 pe-xl-2 ">
                    <div
                      className={`${classes.input1}   row d-flex align-items-center`}
                    >
                      <p className="w-auto d-inline">
                        Delivery Terms & Conditions
                      </p>

                      <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              setDealDataErr((prev) => ({
                                ...prev,
                                deliveryTerms: true,
                              }));

                              setSectionData({
                                title_en: null,
                                title_ar: null,
                                content_en: null,
                                content_ar: null,
                              });
                              setAddDeliverySectionForm(true);
                            }}
                            color={"#D1E6FF"}
                            borderColor={"#134074"}
                            fontColor={"#134074"}
                            icon={<Plus />}
                            className={"w-auto px-2 ms-auto text-end"}
                            type="normal"
                            text={"Add Section"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showError && !dealDataErr.deliveryTerms && (
                  <div className="text-center">
                    <span className="errorMsg text-cenetr">
                      Provide Delivery Terms & Conditions.
                    </span>
                  </div>
                )}

                {deliveryTerms.length > 0 ? (
                  deliveryTerms.map((item, index) => (
                    <div key={index} className="row mt-0 px-0 mx-0">
                      <div className="col-lg-5 col-10 px-0">
                        <Section
                          checkBox={true}
                          onClick={() => {
                            setDealDataErr((prev) => ({
                              ...prev,
                              deliveryTerms: true,
                            }));

                            let arr = [];
                            deliveryTerms.map((item1, index1) => {
                              if (index === index1) {
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: !item1.selected,
                                });
                              } else
                                arr.push({
                                  title_en: item1.title_en,
                                  title_ar: item1.title_ar,
                                  content_en: item1.content_en,
                                  content_ar: item1.content_ar,
                                  selected: item1.selected,
                                });
                            });
                            setDeliveyryTerms(arr);
                          }}
                          checked={item.selected}
                          title={item.title_en}
                          text={item.content_en}
                        />
                      </div>
                      <div className="col-lg-2 col-2 justify-content-center">
                        <div className="row px-0 mx-0 mt-4 pt-2">
                          <div
                            onClick={() => {
                              setSectionData({ ...item });
                              setSelectedIndex(index);
                              setShowEditFormDelivery(true);
                            }}
                            className="w-auto mx-auto"
                          >
                            <Edit />
                          </div>
                        </div>
                      </div>
                      <div className="d-lg-none d-block col-1"></div>
                      <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                        <Section title={item.title_ar} text={item.content_ar} />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center pt-5 pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                      There are No Terms & Condition Added yet.
                    </div>
                  </div>
                )}
                <div className="row px-0 mx-0">
                  <div className="col-6 px-0 ">
                    <div className={classes.input}>
                      <InputGroup
                        linkText={{ text: "L.E.", fn: () => {} }}
                        name="deliveryFees"
                        onChange={(e) => handleChange(e)}
                        error={showError && !dealDataErr.deliveryFees}
                        errorMsg={
                          dealData.deliveryFees
                            ? "provide a Valid Delivery Fees"
                            : "provide a Delivery Fees"
                        }
                        value={"Delivery Fees"}
                      />
                    </div>
                  </div>
                </div>
              </>

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => setOption("Pricing Data")}
                      className={"w-lg-auto  d-inline px-5   "}
                      type="reverse"
                      text={"Back"}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => setOption("Customer Payment Terms")}
                      color={"#D1E6FF"}
                      borderColor={"#134074"}
                      fontColor={"#134074"}
                      className={"w-lg-auto  d-inline px-5  ms-auto "}
                      type="normal"
                      text={"Next"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {option === "Supplier Payment Terms" && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-xl-2">
                  <div
                    className={`${classes.input} row d-flex align-items-center`}
                  >
                    <p className="w-auto d-inline">
                      Payment Terms & Conditions
                    </p>

                    <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            setDealDataErr((prev) => ({
                              ...prev,
                              supplierTerms: true,
                            }));

                            setSectionData({
                              title_en: null,
                              title_ar: null,
                              content_en: null,
                              content_ar: null,
                            });
                            setAddSupplierSectionForm(true);
                          }}
                          color={"#D1E6FF"}
                          borderColor={"#134074"}
                          fontColor={"#134074"}
                          icon={<Plus />}
                          className={"w-auto px-2 ms-auto text-end"}
                          type="normal"
                          text={"Add Section"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {supplierTerms.map((item, index) => (
                <div key={index} className="row mt-0 px-0 mx-0">
                  <div className="col-lg-5 col-10 px-0">
                    <Section
                      checkBox={true}
                      onClick={() => {
                        setDealDataErr((prev) => ({
                          ...prev,
                          supplierTerms: true,
                        }));
                        let arr = [];
                        supplierTerms.map((item1, index1) => {
                          if (index === index1) {
                            arr.push({
                              title_en: item1.title_en,
                              title_ar: item1.title_ar,
                              content_en: item1.content_en,
                              content_ar: item1.content_ar,
                              selected: !item1.selected,
                            });
                          } else
                            arr.push({
                              title_en: item1.title_en,
                              title_ar: item1.title_ar,
                              content_en: item1.content_en,
                              content_ar: item1.content_ar,
                              selected: item1.selected,
                            });
                        });
                        setSupplierTerms(arr);
                      }}
                      checked={item.selected}
                      title={item.title_en}
                      text={item.content_en}
                    />
                  </div>
                  <div className="col-lg-2 col-2 justify-content-center">
                    <div className="row px-0 mx-0 mt-4 pt-2">
                      <div
                        onClick={() => {
                          setSectionData({ ...item });
                          setSelectedIndex(index);
                          setShowEditFormSupplier(true);
                        }}
                        className="w-auto mx-auto"
                      >
                        <Edit />
                      </div>
                    </div>
                  </div>
                  <div className="d-lg-none d-block col-1"></div>
                  <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                    <Section title={item.title_ar} text={item.content_ar} />
                  </div>
                </div>
              ))}

              {showError && !dealDataErr.supplierTerms && (
                <div className="text-center mb-3">
                  <span className="errorMsg text-cenetr">
                    Provide Supplier Terms & Conditions.
                  </span>
                </div>
              )}

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => {
                        setOption("Customer Payment Terms");
                      }}
                      className={"w-lg-auto  d-inline px-5   "}
                      type="reverse"
                      text={"Back"}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    {loadingButton ? (
                      <div className="text-center">
                        <span className="spinner-border my-0 py-0   spinner-border-sm"></span>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setLoadingButton(true);
                          handleCreateDeal();
                        }}
                        color={"#D1E6FF"}
                        borderColor={"#134074"}
                        fontColor={"#134074"}
                        className={"w-lg-auto  d-inline px-5  ms-auto "}
                        type="normal"
                        text={"Start Deal"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {option === "Customer Payment Terms" && (
            <>
              <div className="row px-0 mx-0">
                <div className="col-12 px-0 pe-xl-2">
                  <div
                    className={`${classes.input} row d-flex align-items-center`}
                  >
                    <p className="w-auto d-inline">
                      Payment Terms & Conditions
                    </p>

                    <div className="w-auto d-inline ms-auto px-0  mb-pb-0">
                      <div className={`  mb-pb-0`}>
                        <Button
                          onClick={() => {
                            setDealDataErr((prev) => ({
                              ...prev,
                              customerTerms: true,
                            }));

                            setSectionData({
                              title_en: null,
                              title_ar: null,
                              content_en: null,
                              content_ar: null,
                            });
                            setAddCustomerSectionForm(true);
                          }}
                          color={"#D1E6FF"}
                          borderColor={"#134074"}
                          fontColor={"#134074"}
                          icon={<Plus />}
                          className={"w-auto px-2 ms-auto text-end"}
                          type="normal"
                          text={"Add Section"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showError && !dealDataErr.customerTerms && (
                <div className="text-center mb-3">
                  <span className="errorMsg text-cenetr">
                    Provide Customer Terms & Conditions.
                  </span>
                </div>
              )}

              {customerTerms.map((item, index) => (
                <div key={index} className="row mt-0 px-0 mx-0">
                  <div className="col-lg-5 col-10 px-0">
                    <Section
                      checkBox={true}
                      onClick={() => {
                        setDealDataErr((prev) => ({
                          ...prev,
                          customerTerms: true,
                        }));

                        let arr = [];
                        customerTerms.map((item1, index1) => {
                          if (index === index1) {
                            arr.push({
                              title_en: item1.title_en,
                              title_ar: item1.title_ar,
                              content_en: item1.content_en,
                              content_ar: item1.content_ar,
                              selected: !item1.selected,
                            });
                          } else
                            arr.push({
                              title_en: item1.title_en,
                              title_ar: item1.title_ar,
                              content_en: item1.content_en,
                              content_ar: item1.content_ar,
                              selected: item1.selected,
                            });
                        });
                        setCustomerTerms(arr);
                      }}
                      checked={item.selected}
                      title={item.title_en}
                      text={item.content_en}
                    />
                  </div>
                  <div className="col-lg-2 col-2 justify-content-center">
                    <div className="row px-0 mx-0 mt-4 pt-2">
                      <div
                        onClick={() => {
                          setSectionData({ ...item });
                          setSelectedIndex(index);
                          setShowEditFormCustomer(true);
                        }}
                        className="w-auto mx-auto"
                      >
                        <Edit />
                      </div>
                    </div>
                  </div>
                  <div className="d-lg-none d-block col-1"></div>
                  <div className="col-lg-5 col-10  px-0  ms-lg-auto me-auto">
                    <Section title={item.title_ar} text={item.content_ar} />
                  </div>
                </div>
              ))}

              <div className={`row mx-0 px-0    mb-pb-0  mb-4`}>
                <div className="w-lg-auto  d-inline   ms-auto px-0 pe-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => setOption("Delivery Terms")}
                      className={"w-lg-auto  d-inline px-5   "}
                      type="reverse"
                      text={"Back"}
                    />
                  </div>
                </div>
                <div className="w-lg-auto  d-inline  px-0  ps-lg-2 mb-pb-0 my-3">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() => setOption("Supplier Payment Terms")}
                      color={"#D1E6FF"}
                      borderColor={"#134074"}
                      fontColor={"#134074"}
                      className={"w-lg-auto  d-inline px-5  ms-auto "}
                      type="normal"
                      text={"Next"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </section>
    </div>
  );
}
//2000
//Done
