import React, { useEffect, useState } from "react";
import "./index.css";
import five from "../../../src/assests/7.png";
import { useSelector } from "react-redux";
export default function Section42() {
  const [width, setWidth] = useState(1400);
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <div dir={language === "ar" && "rtl"}>
      <div className="container">
        <div
          style={{
            height: `${
              window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
            }`,
            paddingBottom: `${window.innerWidth < 1200 && "100px"}`,
          }}
          className="row   d-flex align-items-center   px-0 mx-0  d-flex"
        >
          <div
            style={{
              height: `${
                window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
              }`,
            }}
            className={`col-xl-8   order-2 d-flex flex-column ${
              language === "en" ? `text-xl-start` : `text-xl-end`
            } text-center  pt-xl-0 pt-4 justify-content-xl-center position-relative `}
          >
            <h1
              className={`main-title  ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center`}
            >
              {language === "en"
                ? `What is Cold deal?`
                : `ما هي الصفقات البارده ؟`}{" "}
            </h1>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center mb-0`}
            >
              {language === "en"
                ? `These deals are for specific products that require more time to
              allow suppliers to compete in an open tender throughout the offer
              period to offer us the best price.`
                : `هذه الصفقات مخصصة لمنتجات معينه و التي تحتاج لمذيد من الوقت لإتاحة الفرصه للموردين للتنافس في مناقصة مفتوحة طول فترة العرض لتقديم أفضل سعر لنا`}
            </p>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center mb-0 mt-2`}
            >
              {language === "en"
                ? `Buy the quantity you want at the current offer price before the
              end of the deal period from the mentioned supplier and in case
              there is a better offer from another supplier, eshtarena will
              automatically refund the price difference to your bank card used
              in the purchase.`
                : `اشترى الكمية التي تريدها بالسعر الحالي للعرض قبل انتهاء مدة الصفقة من المورد المذكور و في حالة تقديم عرض أفضل من مورد أخر يقوم اشترينا باسترجاع فرق السعر تلقائياً على بطاقتك البنكيه التي قمت بالدفع منها
`}
            </p>
          </div>
          <div
            // style={{ height: `${window.innerWidth > 1200 ? window.innerHeight : window.innerHeight * 0.5}px` }}
            className={`col-xl-4   order-1 pt-xl-0 pt-5 ${
              language === "en" ? "text-xl-start" : "text-xl-end"
            } text-center justify-content-center align-items-center d-flex mb-xl-0  mb-5`}
          >
            <img
              alt="image_feature"
              style={{
                width: `100%`,
                objectFit: "contain",
                height: "100%",
                maxwidth: `${window.innerWidth}px`,
                maxHeight: `${window.innerHeight - 200}px`,
                // height: `${window.innerWidth > 1200 ? window.innerHeight - 200 : window.innerHeight * 0.4}px`,
              }}
              src={five}
              // alt="feature_image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
