import React from "react";
import classes from "./Cashier.module.scss";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import VoucherActieveL from "../../components/Reusable/SVG/VoucherActieveL";
import ManagerHeader from "../../components/Reusable/Common/MemberCard/ManagerHeader";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import { useState } from "react";
import DataPopup from "../../components/Reusable/UI/DataPopup/DataPopup";
import PieChart from "../../components/Reusable/SVG/PieChart";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Avatar, Skeleton } from "@mui/material";
import Button from "../../components/Reusable/UI/Button/Button";
import Section from "../../components/Reusable/Common/Section/Section";
import Table from "../../components/Reusable/Common/Table/Table";
import Docs from "../../components/Reusable/SVG/Docs";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import SendPOInvoiceFile from "../../components/Reusable/Common/AddMemberCard/SendPOInvoiceFile";
import Download from "../../components/Reusable/SVG/Download";
import People from "../../components/Reusable/SVG/People";
import PeopleActiveL from "../../components/Reusable/SVG/PeopleActiveL";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import { useNavigate, useParams } from "react-router-dom";
import { cashierService } from "../../services/CashierService";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import { useEffect } from "react";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import { errorActions } from "../../store/reducers/errorSlice";
import { scanActions } from "../../store/reducers/scanSlice";
import { useDispatch } from "react-redux";
export default function CashierResult(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [value, setValue] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const history = useNavigate();
  // Stop the camera stream
  const stopCamera = () => {};

  // Turn off the flashlight (if supported)
  const turnOffFlash = () => {};

  // Example usage
  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        })
        .catch((error) => {
          //console.error("Error stopping camera:", error);
        });
    }
    if ("torch" in navigator) {
      navigator.torchEnabled = false;
    } else {
      //console.error("Flashlight not available or not supported.");
    }
    if (localStorage.getItem("scan") === "true") {
      localStorage.setItem("scan", "false");
      dispatch(scanActions.setScan({ scan: false }));

      window.location.reload();
    }
  }, []);
  let getSingleVoucher = async () => {
    let response = await cashierService.getSingleVoucherOrder(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setData(response.data.voucher);
    } else {
      setNotFound(true);
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };

  let handleUseVoucher = async () => {
    setShowError(true);
    if (value && value !== 0) {
      let response = await cashierService.useVoucher({
        value: value,
        voucherId: data._id,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid === true) {
        // setData(response.data.voucher);
        setShowConfirmation(true);
        setTimeout(() => {
          localStorage.getItem("side") === "Supplier"
            ? history("/supplier/voucher", { replace: true })
            : history("/cashier", { replace: true });
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
        // setNotFound(true);
        // setLoading(false);
      }
    }
  };

  useEffect(() => {
    getSingleVoucher();
  }, [id]);
  return (
    <>
      {
        <div className={`w-100 ${classes["cashier"]}`}>
          {serverError && (
            <Modal
              style={{ padding: "24px" }}
              onClose={() => setServerError(false)}
            >
              <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
            </Modal>
          )}
          {showConfirmation && (
            <Modal
              style={{ padding: "24px" }}
              onClose={() => setShowConfirmation(false)}
            >
              <SuccessPopup
                setShowForm={setShowConfirmation}
                message1={`The Voucher is Used Successfully`}
              />
            </Modal>
          )}

          <CompanyNav />
          <section className="custom-container">
            <div className={classes["title"]}>
              <PageTitle
                onClick={() => {
                  // setShowAddForm(true);
                }}
                text="Voucher Details"
                icon={<VoucherActieveL />}
                // buttonText={`Add Voucher`}
                // buttonType="normal"
                // buttonIcon={<Plus />}
              />
            </div>
            {loading ? (
              <section className={`${classes["main-section"]}`}>
                <>
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-md-6 col-12 px-0">
                      <div dir="auto" className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-md-6 col-12 px-0">
                      <div dir="auto" className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12 px-0">
                      <div className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-md-6 col-12 px-0">
                      <div dir="auto" className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                    <div dir="auto" className="col-md-6 col-12 px-0">
                      <div className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-md-6 col-12 px-0">
                      <div dir="auto" className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                    <div dir="auto" className="col-md-6 col-12 px-0">
                      <div className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                  >
                    <div className="col-md-6 col-12 px-0">
                      <div dir="auto" className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                    <div dir="auto" className="col-md-6 col-12 px-0">
                      <div className={classes["single-item"]}>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "200px" }}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "300px" }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              </section>
            ) : notFound ? (
              <div className="w-100 row justify-content-center pt-5 pb-4">
                <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                  There is No Voucher Found
                </div>
              </div>
            ) : (
              <>
                <section className={`${classes["main-section"]}`}>
                  <>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-md-6 col-12 px-0">
                        <div dir="auto" className={classes["single-item"]}>
                          <p>Voucher Code</p>
                          <span>{data.barcode}</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-12 px-0">
                        <div dir="auto" className={classes["single-item"]}>
                          <p>Voucher Status</p>
                          <span>{data.status}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-md-6 col-12 px-0">
                        <div dir="auto" className={classes["single-item"]}>
                          <p>Voucher Title</p>
                          <span>{data.title_en}</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-12 px-0">
                        <div className={classes["single-item"]}>
                          <p>عنوان الكوبون</p>
                          <span>{data.title_ar}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-md-6 col-12 px-0">
                        <div dir="auto" className={classes["single-item"]}>
                          <p>Voucher Value Left</p>
                          <span>{data.left}</span>
                        </div>
                      </div>
                      <div dir="auto" className="col-md-6 col-12 px-0">
                        <div className={classes["single-item"]}>
                          <p>Voucher Value</p>
                          <span>{data.totalValue}</span>
                        </div>
                      </div>
                    </div>
                  </>
                </section>
                <div className={classes["title"]}>
                  <PageTitle
                    onClick={() => {
                      // setShowAddForm(true);
                    }}
                    text="Customer Data"
                    icon={<PeopleActiveL />}
                    // buttonText={`Add Voucher`}
                    // buttonType="normal"
                    // buttonIcon={<Plus />}
                  />
                </div>
                <section className={`${classes["main-section"]}`}>
                  <>
                    <div
                      className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                    >
                      <div className="col-md-6 col-12 px-0">
                        <div dir="auto" className={classes["single-item"]}>
                          <p>Customer Name</p>
                          <span>{data.customerName}</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-12 px-0">
                        <div className={classes["single-item"]}>
                          <p>Customer phone</p>
                          <span>{data.phone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row px-0 mx-0 mt-5  align-items-center  ">
                      <div className="col-lg-4 col-10  px-0 ">
                        <div className={classes.input}>
                          <InputGroup
                            //   default={data.commercailName_en}
                            //   name="name"
                            onChange={(e) => setValue(e.target.value)}
                            error={showError && (!value || value === 0)}
                            errorMsg="Provide a Required Value"
                            value={"Required Value"}
                          />
                        </div>
                      </div>
                      <div className="w-auto d-inline mt-3">
                        <Button
                          onClick={() => handleUseVoucher()}
                          color={"#D1E6FF"}
                          borderColor={"#134074"}
                          fontColor={"#134074"}
                          className={"w-auto px-5  ms-auto text-end"}
                          type="normal"
                          text={"Use Voucher"}
                        />
                      </div>
                    </div>
                  </>
                </section>
              </>
            )}
          </section>
        </div>
      }
    </>
  );
}
