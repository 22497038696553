import React, { useState } from "react";
import classes from "./InputGroup.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import Close from "../../SVG/Close";
import { useEffect } from "react";
import Img from "../../Common/Img";
import pdf from "../../../../assests/pdf.png";
import Modal from "../Modal/Modal";
import { FileUploader } from "react-drag-drop-files";
export default function UploadImageGroup2(props) {
  const [showImage, setShowImage] = useState(false);
  const [showImageIndex, setShowImageIndex] = useState("null");
  const [data, setData] = useState([]);
  let handleUpload = (e) => {
    setData((prev) => [...prev, e.target ? e.target.files[0] : e]);
    props.onChange(e);
  };
  useEffect(() => {
    props.default && setData(props.default);
  }, [props.default]);

  let handleRemoveImage = (index) => {
    let arr = [];
    arr = data.filter((item, ind) => index !== ind);
    setData(arr);
    props.onRemove(arr);
  };

  useEffect(() => {
    if (showImageIndex !== "null") {
      setShowImage(true);
    }
  }, [showImageIndex]);

  useEffect(() => {}, []);
  return (
    <div className={` ${classes.input} row  `}>
      {showImage && (
        <Modal
          onClose={() => {
            setShowImageIndex("null");
            setShowImage(false);
          }}
        >
          <Img
            className="w-100 h-100"
            src={
              data[showImageIndex] && data[showImageIndex].name
                ? URL.createObjectURL(data[showImageIndex])
                : (props.imageType === "manager" &&
                    `${process.env.REACT_APP_IMAGE_BASE_URL}/${props.formType}/${data[showImageIndex]}`) ||
                  (props.imageType === "supplier" &&
                    `${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${data[showImageIndex]}`) ||
                  (props.imageType === "invoice/po" &&
                    `${process.env.REACT_APP_IMAGE_BASE_URL}/voucher/${data[showImageIndex]}`) ||
                  (props.imageType === "Product" &&
                    `${process.env.REACT_APP_IMAGE_BASE_URL}/product/${data[showImageIndex]}`)
            }
          />
        </Modal>
      )}
      <div
        className={`${classes["upload-images-infint"]}  ${
          props.error && classes["upload-images-infinte-error"]
        }`}
      >
        <h1 className="text-start">{props.title}</h1>
        <p className="text-center">
          {props.subTitle ? (
            props.subTitle
          ) : (
            <>
              Drag and Drop your{" "}
              {props.type === "Both" ? "File/Image" : props.type} or
              <span style={{ zIndex: 5 }} className="cursor position-relative">
                <input
                  type={
                    props.video
                      ? `video/*,image/*`
                      : props.type === "Both"
                      ? `file`
                      : `image/*`
                  }
                  className="position-absolute"
                  onChange={handleUpload}
                />
                Browse
              </span>
            </>
          )}
        </p>
        {!props.subTitle && (
          <div className="row mx-0 px-">
            <div
              // style={{ width: "100%", height: "100%" }}
              className="w-auto text-center mx-auto d-inline"
            >
              <FileUploader
                classes={` ${classes["drag"]} `}
                // width="auto"
                multiple={false}
                handleChange={handleUpload}
                name="file"
                types={
                  props.type === "Both"
                    ? ["JPEG", "PNG", "PDF", "JPG", "XLSX", "dOCS"]
                    : ["JPEG", "PNG", "JPG"]
                }
              />
            </div>
          </div>
        )}
        <div div className="row px-0 mx-0 pt-2">
          <Swiper
            slidesPerView={4}
            // width={props.type === 'Product' && 500}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView:
                  props.type === "product" || props.type === "yardSale" ? 3 : 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView:
                  props.type === "product" || props.type === "yardSale" ? 3 : 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView:
                  props.type === "product" || props.type === "yardSale" ? 3 : 3,
                spaceBetween: 10,
              },
              1025: {
                slidesPerView:
                  props.type === "product" || props.type === "yardSale" ? 5 : 4,
                spaceBetween: 10,
              },
              1441: {
                slidesPerView:
                  props.type === "product" || props.type === "yardSale" ? 5 : 4,
                spaceBetween: 10,
              },
            }}
            loop={false}
            loopFillGroupWithBlank={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            style={{
              height: props.type === "product" && "100px",
              width: "100%",
            }}
            className="mySwiper  justify-content-start    align-items-center d-flex p-0 "
          >
            {data.map((item, index) => (
              <SwiperSlide
                key={index}
                className={` text-start px-0 pt-0 mt-0 me-3   ${classes["input-feild-image"]}  px-0`}
              >
                <div
                  className={`w-100 px-0 mx-0 align-items-center  d-flex justify-content-center  ${
                    props.error && classes.error
                  }`}
                  key={index}
                >
                  <div className="position-relative px-0 overflow-hidden  rounded-3">
                    {(localStorage.getItem("side") === "Top Manager" ||
                      (!props.noDelete && data[index] && data[index].name)) &&
                      !props.noDeleteAll && (
                        <div
                          onClick={() => handleRemoveImage(index)}
                          style={{ zIndex: "1", top: "4px", right: "4px" }}
                          className={` w-auto bg-white   position-absolute ${classes["close-btn"]}`}
                        >
                          <Close />
                        </div>
                      )}
                    {(!data[index].name &&
                      (data[index].includes(".pdf") ||
                        data[index].includes(".docx") ||
                        data[index].includes(".xlsx"))) ||
                    (data[index] &&
                      data[index].name &&
                      (data[index].name.includes(".pdf") ||
                        data[index].name.includes(".docx") ||
                        data[index].name.includes(".xlsx"))) ? (
                      // <div className=" justify-self-center mx-auto">
                      <a
                        href={
                          data[index] && data[index].name
                            ? URL.createObjectURL(data[index])
                            : (props.imageType === "manager" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/${props.formType}/${data[index]}`) ||
                              (props.imageType === "supplier" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${data[index]}`) ||
                              (props.imageType === "invoice/po" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/voucher/${data[index]}`) ||
                              (props.imageType === "Product" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/product/${data[index]}`)
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Img
                          noShow={true}
                          style={{ width: "100%" }}
                          className="bg-transparent"
                          src={pdf}
                          alt=""
                        />
                      </a>
                    ) : (
                      <Img
                        onClick={() => {
                          setShowImageIndex(index);
                        }}
                        src={
                          data[index] && data[index].name
                            ? URL.createObjectURL(data[index])
                            : (props.imageType === "manager" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/${props.formType}/${data[index]}`) ||
                              (props.imageType === "supplier" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/supplier/${data[index]}`) ||
                              (props.imageType === "invoice/po" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/voucher/${data[index]}`) ||
                              (props.imageType === "Product" &&
                                `${process.env.REACT_APP_IMAGE_BASE_URL}/product/${data[index]}`)
                        }
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {props.error && (
        <span className="px-0 pt-2 errorMsg">&nbsp;{props.errorMsg}</span>
      )}
    </div>
  );
}
