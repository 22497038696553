import React, { useEffect, useRef } from "react";
import classes from "./Map.module.scss";
import { useState } from "react";
import * as mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Close from "../../SVG/Close";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWJkb2NvZGUiLCJhIjoiY2xodDUxeGZqMG1yMTNtbzBvOTM3bHB2byJ9.s5dQOR9QKm2nallRtGJzyg";

let Map = (props) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    setLocation({ lng: props.location.lng, lat: props.location.lat });
  }, []);
  useEffect(() => {
    if (!mapContainer.current) return;

    if (!map && location) {
      let map1 = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v10",
        center: [props.location.lng, props.location.lat],
        zoom: 12,
      });
      const nav = new mapboxgl.NavigationControl({});
      map1.addControl(nav, "bottom-right");

      let marker = null;
      if (props.type === "pick" || props.type === "show") {
        var icon = document.createElement("div");
        const width = 50;
        const height = 50;
        icon.className = "marker1";
        icon.innerHTML = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9999 0C10.7199 0 6.3999 4.32 6.3999 9.6C6.3999 14.88 15.9999 27.2 15.9999 27.2C15.9999 27.2 25.5999 14.88 25.5999 9.6C25.5999 4.32 21.2799 0 15.9999 0ZM15.9999 12.8C14.2399 12.8 12.7999 11.36 12.7999 9.6C12.7999 7.84 14.2399 6.4 15.9999 6.4C17.7599 6.4 19.1999 7.84 19.1999 9.6C19.1999 11.36 17.7599 12.8 15.9999 12.8Z" fill="#9F4DFF"/>
        <path d="M26.08 17.7596C25.6 18.7196 25.12 19.6796 24.48 20.6396C27.2 21.5996 28.8 22.8796 28.8 23.9996C28.8 25.9196 23.68 28.7996 16 28.7996C8.32 28.7996 3.2 25.9196 3.2 23.9996C3.2 22.8796 4.8 21.5996 7.52 20.4796C6.88 19.5196 6.4 18.5596 5.92 17.5996C2.24 19.1996 0 21.4396 0 23.9996C0 28.4796 6.72 31.9996 15.68 31.9996C23.68 31.9996 32 29.2796 32 23.9996C32 21.4396 29.76 19.1996 26.08 17.7596Z" fill="#9F4DFF"/>
        </svg>
        
        `;
        icon.style.width = `${width}px`;
        icon.style.height = `${height}px`;
        marker = new mapboxgl.Marker(icon)
          .setLngLat([props.location.lng, props.location.lat])

          .addTo(map1);
      }

      if (props.type === "pick") {
        map1.on("click", (e) => {
          props.setLocation({ lng: e.lngLat.lng, lat: e.lngLat.lat });
          marker && marker.remove();
          setLocation({ lng: e.lngLat.lng, lat: e.lngLat.lat });
          var icon = document.createElement("div");
          const width = 50;
          const height = 50;
          icon.className = "marker1";
          icon.innerHTML = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.9999 0C10.7199 0 6.3999 4.32 6.3999 9.6C6.3999 14.88 15.9999 27.2 15.9999 27.2C15.9999 27.2 25.5999 14.88 25.5999 9.6C25.5999 4.32 21.2799 0 15.9999 0ZM15.9999 12.8C14.2399 12.8 12.7999 11.36 12.7999 9.6C12.7999 7.84 14.2399 6.4 15.9999 6.4C17.7599 6.4 19.1999 7.84 19.1999 9.6C19.1999 11.36 17.7599 12.8 15.9999 12.8Z" fill="#9F4DFF"/>
          <path d="M26.08 17.7596C25.6 18.7196 25.12 19.6796 24.48 20.6396C27.2 21.5996 28.8 22.8796 28.8 23.9996C28.8 25.9196 23.68 28.7996 16 28.7996C8.32 28.7996 3.2 25.9196 3.2 23.9996C3.2 22.8796 4.8 21.5996 7.52 20.4796C6.88 19.5196 6.4 18.5596 5.92 17.5996C2.24 19.1996 0 21.4396 0 23.9996C0 28.4796 6.72 31.9996 15.68 31.9996C23.68 31.9996 32 29.2796 32 23.9996C32 21.4396 29.76 19.1996 26.08 17.7596Z" fill="#9F4DFF"/>
          </svg>

          `;
          icon.style.width = `${width}px`;
          icon.style.height = `${height}px`;
          marker = new mapboxgl.Marker(icon)
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .addTo(map1);
        });
      }
      map1.on("load", () => {
        setMap(map1);
      });
    }
  }, [location, map, props.location]);

  return (
    <>
      <div className="row m-0 p-0 position-relative  ">
        <div
          className={` ${
            props.type === "pick" || props.type === "show"
              ? "col-12 px-0 mx-0"
              : `col-xl-9    col-8 px-0 mx-0`
          } `}
        >
          {
            <div
              ref={mapContainer}
              className={` px-0 mx-0    ${
                props.type === "pick" || props.type === "show"
                  ? classes["map-container1"]
                  : classes["map-container"]
              }`}
            />
          }

          <div
            style={{
              position: "absolute",
              zIndex: "500",
              // left: lang === "arabic" && "20px",
              right: "20px",
              top: "20px",
            }}
            className="col-lg-3 col-5 text-end "
          >
            <div
              onClick={() => props.setShowForm && props.setShowForm(false)}
              style={{ borderRadius: "50%" }}
              className="w-auto d-inline bg-white"
            >
              <Close />
            </div>
            {props.type === "pick" && location && (
              <div className="row p-0 mx-0">
                <div
                  style={{
                    position: "absolute",
                    zIndex: "500",
                    top: "50px",
                  }}
                  className="col-12 px-0 "
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Map;
