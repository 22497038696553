import React from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
import InputGroup from "../../UI/InputGroup/InputGroup";
import Button from "../../UI/Button/Button";
import TextAreaGroup from "../../UI/InputGroup/TextAreaGroup";
import { useState } from "react";
// import { TermsAndConditionservice } from "../../../../services/MainData/TermsAndConditions";
import { useEffect } from "react";
import { vouchersSupplierService } from "../../../../services/VoucherSuppliers";
// import { PrivacyPolicyervice } from "../../../../services/MainData/PrivacyPolicy";
// import { errorActions } from "../../../../store/reducers/errorSlice";
// import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
// import { useDispatch } from "react-redux";

export default function SendResponseForm(props) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  let handleSubmit = async () => {
    setShowError(true);
    if (responseMsg.length > 0) {
      let response = await vouchersSupplierService.rejectVoucher(props.id, {
        rejectionMessage: responseMsg,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="row mx-0 px-0 ">
        <div className={`col-10 px-0 ${classes.title}`}>
          Voucher {props.type} message
        </div>
        <div
          onClick={() => props.setShowForm(false)}
          className="cursor col-2 px-0 text-end"
        >
          <Close color="#134074" />
        </div>
      </div>

      {
        <>
          <div className="row px-0 mx-0">
            <div className="col-12 px-0 ">
              <div className={classes.input}>
                <InputGroup
                  onChange={(e) => setResponseMsg(e.target.value)}
                  error={showError && responseMsg.length === 0}
                  errorMsg="Provide a Response Message"
                  value={
                    props.type === "Rejection"
                      ? "Rejection Message"
                      : "Response Message"
                  }
                />
              </div>
            </div>
          </div>
        </>
      }

      <div className="row mx-0 px-0 mb-0 pb-0">
        <div className="col-4 ms-auto px-0">
          <div>
            {loading ? (
              <Button
                className={"w-auto px-2 py-0 ms-auto text-end"}
                type="normal"
                text={
                  <div className="text-center px-5 my-0 py-0">
                    <span className="spinner-border text-white spinner-border-sm"></span>
                  </div>
                }
                fontColor={props.type === "Rejection" ? "white" : "#134074"}
                color={props.type === "Rejection" ? "red" : "#D1E6FF"}
                borderColor={props.type === "Rejection" ? "red" : "#134074"}
              />
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  handleSubmit();
                }}
                fontColor={props.type === "Rejection" ? "white" : "#134074"}
                color={props.type === "Rejection" ? "red" : "#D1E6FF"}
                borderColor={props.type === "Rejection" ? "red" : "#134074"}
                className={"w-auto px-2 ms-auto text-end"}
                type="normal"
                text={props.type === "Rejection" ? `Reject` : "send"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
