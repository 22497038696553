import React from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
import InputGroup from "../../UI/InputGroup/InputGroup";
import Button from "../../UI/Button/Button";
import TextAreaGroup from "../../UI/InputGroup/TextAreaGroup";
import { useState } from "react";
// import { TermsAndConditionservice } from "../../../../services/MainData/TermsAndConditions";
import { useEffect } from "react";
// import { PrivacyPolicyervice } from "../../../../services/MainData/PrivacyPolicy";
// import { errorActions } from "../../../../store/reducers/errorSlice";
// import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
// import { useDispatch } from "react-redux";

export default function SendPOInvoiceFile(props) {
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();

  return (
    <>
      <div className="row mx-0 px-0 ">
        <div className={`col-10 px-0 ${classes.title}`}>{props.title}</div>
        <div
          onClick={() => props.setShowForm(false)}
          className="cursor col-2 px-0 text-end"
        >
          <Close color="#134074" />
        </div>
      </div>

      {
        <>
          <div className="row px-0 mx-0">
            <div className="col-12 px-0 ">
              <div className={classes.input}>
                <InputGroup
                  //   default={data.title}
                  //   onChange={(e) =>
                  // setData((prev) => ({ ...prev, title: e.target.value }))
                  //   }
                  //   error={showError && !data.title}
                  //   errorMsg="Provide a Title"
                  type="File"
                  name="sectionTitle"
                  value={"File"}
                />
              </div>
            </div>
          </div>
        </>
      }

      <div className="row mx-0 px-0 mb-0 pb-0">
        <div className="col-4 ms-auto px-0">
          <div>
            {loading ? (
              <Button
                onClick={() => {
                  setLoading(true);
                  //   handleSubmit();
                }}
                // icon={<Plus />}

                className={"w-auto px-2 py-0 ms-auto text-end"}
                type="normal"
                text={
                  <div className="text-center px-5 my-0 py-0">
                    <span className="spinner-border text-white spinner-border-sm"></span>
                  </div>
                }
              />
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  //   handleSubmit();
                }}
                // icon={<Plus />}

                // fontColor="white"
                // color="#134074"
                // borderColor="#134074"
                className={"w-auto px-2 ms-auto text-end"}
                type="normal"
                text={`Send`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
