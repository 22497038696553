import React, { useEffect, useState } from "react";
import "./index.css";
import six from "../../assests/66.png";
import { useSelector } from "react-redux";
export default function Section4() {
  const [width, setWidth] = useState(1400);
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <div dir={language === "ar" && "rtl"} style={{ background: "#F0F0F5" }}>
      <div className="container">
        <div
          style={{
            height: `${
              window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
            }`,
            paddingBottom: `${window.innerWidth < 1200 && "100px"}`,
          }}
          className="row   d-flex align-items-center   px-0 mx-0 "
        >
          <div
            style={{
              height: `${
                window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
              }`,
            }}
            className={`col-xl-8  order-xl-1 order-2 d-flex flex-column ${
              language === "en" ? "text-xl-start" : "text-xl-start"
            } text-center  pt-xl-0 pt-4 justify-content-xl-center position-relative `}
          >
            <h1
              className={`main-title  ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center`}
            >
              {language === "en"
                ? `How can you use the purchase vouchers ?`
                : `كيف يمكنك إستخدام كوبونات الشراء ؟
`}
            </h1>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center mb-0`}
            >
              {language === "en"
                ? `After purchasing a voucher for a specific brand from the group
              purchase offer, the voucher is automatically added to your
              vouchers’ wallet on eshtarena.`
                : `بعد شرائك للكوبون من عرض الشراء الجماعي يتم إضافة الكوبون تلقائياً لمحفظة كوبوناتك على تطبيق اشترينا`}
            </p>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center mb-0 mt-2`}
            >
              {language === "en"
                ? `You can open the voucher from your wallet, view your balance and
              bill the branch or the supplier's website with the voucher barcode
              number.`
                : `يمكنك فتح الكوبون من المحفظة و الإطلاع على رصيدك الحالى و محاسبة الفرع او الموقع الإلكتروني للمورد برقم الباركود الخاص بالكوبون
`}
            </p>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center mb-0 mt-2`}
            >
              {language === "en"
                ? `You can cash out the full or partial value of the voucher and
              spend the remaining balance in future purchases.`
                : `يمكنك صرف قيمة الكوبون كامله أو جزئ منها وصرف المتبقي في عمليات شراء قادمة 
`}
            </p>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center mt-2`}
            >
              {language === "en"
                ? `You will receive a notification from eshtarena after each purchase
              on what was spent with the remaining balance in this voucher.`
                : `سوف تتلقى إشعار من اشترينا بعد كل عملية شراء بما تم صرفه و رصيدك المتبقى 
`}
            </p>
          </div>
          <div
            className={`col-xl-4  order-xl-2 order-1 pt-xl-0 pt-5 ${
              language === "en" ? "text-xl-start" : "text-xl-end"
            } text-center justify-content-center align-items-center d-flex mb-xl-0 mb-5`}
          >
            <img
              className="text-center"
              style={{
                width: `90%`,
                objectFit: "contain",
                height: "80%",
                maxwidth: `${window.innerWidth}px`,
                maxHeight: `${window.innerHeight - 200}px`,
              }}
              src={six}
              alt="feature_image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
