import React, { useEffect, useState } from "react";
import classes from "./MainData.module.scss";
import { Skeleton } from "@mui/joy";
import SocialMediaCard from "../../components/Reusable/Common/MemberCard/SocialMediaCard";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import Button from "../../components/Reusable/UI/Button/Button";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import DealsAvtiveL from "../../components/Reusable/SVG/DealsAvtiveL";
import Plus from "../../components/Reusable/SVG/Plus";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import AddSocial from "../../components/Reusable/Common/AddMemberCard/AddSocial";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import { MainDataService } from "../../services/mainData";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import SuccessPopUp from "../../components/Sections/SuccessPopUp";
export default function MainData() {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    apple: "",
    google: "",
  });
  const [formDataErr, setFormDataErr] = useState({
    appple: false,
    google: false,
  });
  const [showError, setShowError] = useState(false);
  const [showAddSocialMedia, setShowAddSocialMedia] = useState(false);
  const [shownOption, setShownOption] = useState("Social Media");
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [socail, setSocail] = useState([]);
  let getAboutData = async () => {
    let response = await MainDataService.getData();
    setFormData({
      google: response.data.about.google,
      apple: response.data.about.apple,
    });

    setFormDataErr({
      founded: true,
      size: true,
      overview_ar: true,
      overview_en: true,
    });
    setSocail(response.data.about.social);

    setFormDataErr({
      founded: true,
      size: true,
      overview_ar: true,
      overview_en: true,
    });
    setLoading(false);
  };

  useEffect(() => {
    getAboutData();
  }, []);

  let handleSubmit1 = async () => {
    if (formData.google || formDataErr.apple) {
      let data = {};
      if (formData.google) {
        data.google = formData.google;
      }

      if (formData.apple) {
        data.apple = formData.apple;
      }

      let response = await MainDataService.updateAppLinks(data);
      let vaild = false;
      vaild = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (vaild === true) {
        setShowConfirmationMessage(true);
        setTimeout(() => {
          setShowConfirmationMessage(false);
        }, 2000);
      }
    }
  };
  let handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormDataErr((prev) => ({ ...prev, [e.target.name]: true }));

    if (e.target.value !== "") {
      setFormDataErr((prev) => ({ ...prev, [e.target.name]: true }));
    } else setFormDataErr((prev) => ({ ...prev, [e.target.name]: false }));
  };

  return (
    <div className={`w-100 ${classes["original-deals"]}`}>
      <CompanyNav />
      <section className="custom-container">
        <PageTitle text={"Main Data"} icon={<DealsAvtiveL />} />
        {serverError && (
          <Modal
            style={{ padding: "24px" }}
            onClose={() => setServerError(false)}
          >
            <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
          </Modal>
        )}
        {showConfirmationMessage && (
          <Modal
            style={{ padding: "24px" }}
            onClose={() => setShowConfirmationMessage(false)}
          >
            <SuccessPopUp
              setShowForm={setShowConfirmationMessage}
              message1="Data Edited Successfully"
            />
          </Modal>
        )}
        <div className={`row px-0 mx-0  my-3 ${classes.options}`}>
          <SelectOption
            onSelect={(val) => setShownOption(val)}
            default={shownOption}
            options={["Social Media", "App Links"]}
          />
        </div>
        {showAddSocialMedia && (
          <Modal
            style={{ padding: "24px" }}
            onClose={() => setShowAddSocialMedia(false)}
          >
            <AddSocial
              onSubmit={() => {
                setLoading(true);
                setShowAddSocialMedia(false);
              }}
              setShowForm={setShowAddSocialMedia}
              type="Add"
              socialMedia={true}
            />
          </Modal>
        )}
        <section className={`${classes["main-section"]}`}>
          {loading ? (
            <>
              <div className="mt-5">
                <Skeleton
                  variant="text"
                  sx={{ height: "3rem", width: "50%" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ height: "1rem", width: "25%" }}
                />
              </div>
              <div className="mt-5 pt-5">
                <div className="row mx-0 px-0">
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                </div>
                <div className="row mx-0 px-0">
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                </div>

                <div className="row mx-0 px-0">
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                  <div className="col-6 pe-2">
                    <Skeleton
                      variant="text"
                      sx={{ height: "2rem", width: "100px" }}
                    />
                    <Skeleton variant="text" sx={{ height: "4rem" }} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {shownOption === "App Links" && (
                <>
                  <div className="row mx-0 px-0">
                    <div className="col-6 px-0 mx-0">
                      <div className="row  px-0 mx-0">
                        <div className="col-12 px-0 pe-2">
                          <div className={classes.input}>
                            <InputGroup
                              default={formData.google}
                              onChange={(e) => handleChange(e)}
                              name="google"
                              value="Google Store"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 px-0 mx-0">
                      <div className="row  px-0 mx-0">
                        <div className="col-12 px-0 ps-2">
                          <div className={classes.input}>
                            <InputGroup
                              default={formData.apple}
                              onChange={(e) => handleChange(e)}
                              name="apple"
                              value="Apple Store"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mx-0 px-0 mt-3">
                    <div className="col-4 px-0 ms-auto">
                      <div className={classes.input}>
                        <Button
                          onClick={() => handleSubmit1()}
                          className={"w-auto px-2 ms-auto text-end"}
                          type="normal"
                          text={"Save Changes"}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {shownOption === "Social Media" && (
                <>
                  <PageTitle
                    onClick={() => setShowAddSocialMedia(true)}
                    text="Social Media"
                    button="Add"
                    buttonText="Add Social Media"
                    buttonType="normal"
                    buttonIcon={<Plus />}
                  />
                  <div
                    className={`row  px-0 mx-0 mt-2 ${classes["company-profile--member"]}`}
                  >
                    {socail.length > 0 ? (
                      socail.map((item, index) => (
                        <div key={index} className={classes["single-item"]}>
                          <SocialMediaCard
                            updateData={() => {
                              setLoading(true);
                              getAboutData();
                            }}
                            item={{
                              id: item._id,
                              title: item.title,
                              pic: item.logo,
                              link: item.link,
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="w-100 row justify-content-center py-5 my-5 ">
                        <div className="no-found-data text-center mx-auto  w-auto  w-100 ">
                          There is No Social Media Added Yet.
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
