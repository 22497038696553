import React, { useEffect, useState } from "react";
import FilterDots1 from "../../SVG/FilterDots1";
import classes from "./MemberCard.module.scss";
import Edit from "../../SVG/Edit";
import ReactDOM from "react-dom";

import Recycle from "../../SVG/Recycle";
import Modal from "../../UI/Modal/Modal";
import Close from "../../SVG/Close";
import AddMemberCard from "../AddMemberCard/AddMemberCard";
import zIndex from "@mui/material/styles/zIndex";
import RemoveMember from "../AddMemberCard/RemoveMember";
// import { CompanyService } from '../../../../services/Company';
// import { adminsActions } from '../../../../store/reducers/adminsSlice';
import { useDispatch, useSelector } from "react-redux";
// import { membersActions } from '../../../../store/reducers/memberSlice';
import Img from "../Img";
import { MainDataService } from "../../../../services/mainData";
import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
import AddSocial from "../AddMemberCard/AddSocial";
// import { serverErrorCatcher } from '../../../../Helper/ServerErrorCatcher';
// import { errorActions } from '../../../../store/reducers/errorSlice';
// import { AboutService } from '../../../../services/aboutService';
export default function SocialMediaCard(props) {
  const [shownOptions, setShownOptions] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  // const { members } = useSelector((state) => state.members);
  const [serverError, setServerError] = useState(null);
  const [serverErrorMsg, setServerErrorMsg] = useState(null);
  const dispatch = useDispatch();
  let onSubmitMember = (member) => {
    props.updateData();
    setShowEdit(false);
  };

  return (
    <>
      {shownOptions &&
        ReactDOM.createPortal(
          <div
            className={classes.backdrop}
            onClick={() => setShownOptions(false)}
          ></div>,
          document.getElementById("overlays")
        )}
      {showEdit && (
        <Modal style={{ padding: "24px" }} onClose={() => setShowEdit(false)}>
          <AddSocial
            onSubmit={onSubmitMember}
            item={props.item}
            setShowForm={setShowEdit}
            type="Edit"
            socialMedia={true}
          />
        </Modal>
      )}
      {showRemove && (
        <Modal style={{ padding: "24px" }} onClose={() => setShowRemove(false)}>
          <RemoveMember
            socialMedia={true}
            buttonText={"Remove Social Media"}
            message2="Are You Sure?"
            onClick={async () => {
              let response = await MainDataService.deleteSocial(props.item.id);
              let vaild = false;
              vaild = serverErrorCatcher(
                response,
                setServerError,
                setServerErrorMsg
              );
              if (vaild === true) {
                // let arr = members.filter((item) => item.id !== props.item.id);
                // dispatch(membersActions.updateMembers(arr));
                setShowRemove(false);
                props.updateData();
              } else {
                // dispatch(
                //   errorActions.setError({ error: true, errorMsg: localStorage.getItem('errorMsg') })
                // );
              }
            }}
            setShowForm={setShowRemove}
          />
        </Modal>
      )}
      <div
        onClick={() => setShownOptions((prev) => !prev)}
        className="cursor"
        style={{ position: "absolute", right: "0px" }}
      >
        <FilterDots1 />
      </div>
      {shownOptions && (
        <div style={{ zIndex: "5000" }}>
          <div
            className={`position-absolute  ${classes.filter} row`}
            style={{
              right: "35px",
              minWidth: "auto",
              // width: "350px !importnat",
              // minWidth: "auto",
            }}
          >
            <p
              className="cursor row   px-0 mx-0 d-flex align-items-cenetr"
              onClick={(e) => {
                // e.preventDefault();
                setShowEdit(true);
                setShownOptions(false);
              }}
            >
              <div className="w-auto d-inline px-0 mx-0">
                <Edit />
              </div>
              <div className="w-auto d-inline">&nbsp;Edit</div>
            </p>
            <p
              onClick={(e) => {
                // e.preventDefault();
                setShowRemove(true);
                setShownOptions(false);
              }}
              className="cursor row px-0 mx-0 d-flex align-items-cenetr"
            >
              <div className="w-auto d-inline mx-0 px-0">
                <Recycle />
              </div>
              <div className="w-auto d-inline "> &nbsp;Remove</div>
            </p>
          </div>
        </div>
      )}
      <img
        style={{
          objectFit: "contain",
          borderRadius: "8px",
          background: "transparent",
          backgroundColor: "transparent",
        }}
        src={
          props.item.pic.name
            ? URL.createObjectURL(props.item.pic)
            : `${process.env.REACT_APP_IMAGE_BASE_URL}/advice/${props.item.pic}`
        }
        alt=""
      />
      <h1>{props.item.title}</h1>
    </>
  );
}
