import React from "react";
import UploadImage from "../InputFeild/UploadImage";
import classes from "./InputGroup.module.scss";
import Label from "../Label/Label";
export default function UploadImageGroup(props) {
  return (
    <div className="row px-0 ">
      <div className="row px-0 mx-0 ">
        <div className="w-auto px-0  d-inline">
          <Label for={props.name} value={props.value} />
          <div className="row px-0 mx-0">
            <span className="px-0">{props.descText}</span>
          </div>
        </div>
        {props.linkText && (
          <div className={` w-auto d-inline text-end ms-auto ${classes.green}`}>
            {props.linkText.text}
          </div>
        )}
      </div>
      <div className="col-12 px-0 ">
        <UploadImage
          png={props.png}
          video={props.video}
          multiple={props.multiple}
          onRemove={(ind) => props.onRemove(ind)}
          onChange={(e) => props.onChange(e)}
          default={props.default}
          error={props.error}
          type={props.type}
          text={props.uploadText}
          name={props.name}
          value={props.value}
        />
      </div>

      {true && (
        <span
          style={{ fontSize: "12px", color: "gray" }}
          className="px-0 pt-2 "
        >
          &nbsp;File Type : jpg , jpeg {props.png && ", png"}
          {props.video && ", mp4"}
        </span>
      )}
      {props.error && (
        <span className="px-0 errorMsg">&nbsp;{props.errorMsg}</span>
      )}
    </div>
  );
}
