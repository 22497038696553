import React, { useRef, useState } from "react";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import classes from "./Category.module.scss";
import Plus from "../../components/Reusable/SVG/Plus";
import CategoryActiveL from "../../components/Reusable/SVG/CategoryActiveL";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import SearchInput from "../../components/Reusable/UI/InputFeild/SearchInput";
import Table from "../../components/Reusable/Common/Table/Table";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import AddCategory from "../../components/Reusable/Common/AddMemberCard/AddCategory";
import RemoveCard from "../../components/Reusable/Common/AddMemberCard/RemoveMember";
import { categoryService } from "../../services/categoryService";
import { useEffect } from "react";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import Edit from "../../components/Reusable/SVG/Edit";
import Recycle from "../../components/Reusable/SVG/Recycle";
import { Skeleton } from "@mui/material";
import UsePagination from "../../components/Reusable/UI/Pagination/Pagination";
import { subcategoryService } from "../../services/subCategoryService";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
export default function Category(props) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const controllerRef = useRef(null);

  const [confirmationMsg, setConfirmationMsg] = useState("");
  const [searchValue, setSearchValue] = useState("");
  let handleDelete = async () => {
    let response =
      props.type === "Category"
        ? await categoryService.deleteCategory(editData._id)
        : await subcategoryService.deleteSubcategory(editData._id);

    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setConfirmationMsg(`${props.type} is Deleted Successfully`);
      setShowConfirmation(true);
      setShowDeleteForm(false);
      setLoading(true);
      props.type === "Category" ? getCategories() : getSubcategories();
      setLoading(false);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    } else {
      setShowDeleteForm(false);
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
  };
  let getCategories = async () => {
    let query = "";
    query += searchValue && `&search=${searchValue}`;
    let response = await categoryService.getAllCategories(
      page,
      5,
      query ? query : false
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      let arr = [];
      arr = response.data.categories.map((item) => [
        item.uuid,
        item.categoryImg,
        item.name_en,
        item.subcategoryCount,
        item.createdAt ? item.createdAt.slice(0, 10) : "01/01/2022",
        <>
          <div className="row px-0 justify-content-center mx-0">
            <div
              onClick={() => {
                setEditData(item);
                setShowEditForm(true);
              }}
              className="w-auto d-inline"
            >
              <Edit />
            </div>
            <div
              onClick={() => {
                setEditData(item);
                setShowDeleteForm(true);
              }}
              className="w-auto d-inline"
            >
              <Recycle />
            </div>
          </div>
        </>,
      ]);
      setData(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };
  let getSubcategories = async () => {
    let query = "";
    query += searchValue && `&search=${searchValue}`;
    let response = await subcategoryService.getAllSubcategories(
      page,
      5,
      query ? query : false
    );

    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);

      let arr = [];
      arr = response.data.subcategories.map((item) => [
        item.uuid,
        item.subcategoryImg,
        item.category,
        item.name_en,
        item.subcategoryCount,
        item.createdAt ? item.createdAt.slice(0, 10) : "01/01/2022",
        <>
          <div className="row px-0 justify-content-center mx-0">
            <div
              onClick={() => {
                setEditData(item);
                setShowEditForm(true);
              }}
              className="w-auto d-inline"
            >
              <Edit />
            </div>
            <div
              onClick={() => {
                setEditData(item);
                setShowDeleteForm(true);
              }}
              className="w-auto d-inline"
            >
              <Recycle />
            </div>
          </div>
        </>,
      ]);
      setData(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        setServerError(false);
      }, 2000);
    }
  }, [serverError]);
  useEffect(() => {
    setPage(1);
  }, [props.type]);

  useEffect(() => {
    setLoading(true);
    props.type === "Category" ? getCategories() : getSubcategories();
    setFirstLoad(false);
  }, [page, props.type]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoading(true);
        if (page === 1)
          props.type === "Category" ? getCategories() : getSubcategories();
        else setPage(1);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);
  return (
    <div className={`w-100 ${classes["category-page"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop message1={serverErrorMsg} setShowForm={setServerError} />
        </Modal>
      )}

      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowAddForm(false);
          }}
        >
          <SuccessPopup message1={confirmationMsg} />
        </Modal>
      )}
      {(showAddForm || showEditForm) && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowAddForm(false);
            setShowEditForm(false);
          }}
        >
          <AddCategory
            type={showAddForm ? "Add" : "Edit"}
            formType={props.type}
            setShowForm={() => {
              setShowAddForm(false);
              setShowEditForm(false);
            }}
            data={showEditForm && editData}
            onSuccess={() => {
              setConfirmationMsg(
                `${props.type} is ${
                  showAddForm ? "Added" : "Edited"
                } Successfully`
              );
              setShowConfirmation(true);
              setShowAddForm(false);
              setShowEditForm(false);
              setLoading(true);
              props.type === "Category" ? getCategories() : getSubcategories();
              setLoading(false);
              setTimeout(() => {
                setShowConfirmation(false);
              }, 2000);
            }}
          />
        </Modal>
      )}

      {showDeleteForm && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowDeleteForm(false);
          }}
        >
          <RemoveCard
            onClick={() => {
              handleDelete();
            }}
            setShowForm={setShowDeleteForm}
            message1={`Are you sure you want to remove this ${
              props.type === "Category" ? "Category" : "Sub Category"
            }?`}
            message2={
              props.type === "Category"
                ? `By removing this category, it won’t be shown on the Categories list anymore, and all sub-Categories will be deleted as well.`
                : `By removing this subcategory, it won’t show on the Sub-Categories list anymore`
            }
            buttonText={`Remove ${
              props.type === "Category" ? "Category" : "Sub Category"
            }`}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <PageTitle
          onClick={() => {
            setShowAddForm(true);
          }}
          text={props.type}
          icon={<CategoryActiveL />}
          buttonText={`Add ${props.type}`}
          buttonType="normal"
          buttonIcon={<Plus />}
        />
        <section className={`${classes["main-section"]}`}>
          {!firstLoad && (
            <div className={`row   mx-0 px-0  ${classes["table-option"]}`}>
              <div className="col-xl-5 col-lg-6  px-0 mb-xl-0 ">
                <SearchInput
                  onChange={(val) => {
                    setLoading(true);
                    setSearchValue(val.target.value);
                  }}
                  placeholder={
                    props.type === "Category"
                      ? `Search By Category Name / ID`
                      : `Search By ( Parent Category / Subcategory ) Name / ID `
                  }
                />
              </div>
            </div>
          )}
          {props.type === "Category" ? (
            loading ? (
              <>
                <Table
                  options={[
                    {
                      title: "Category ID",
                      icon: false,
                    },
                    {
                      title: "Category Image",
                      icon: false,
                    },
                    {
                      title: "Category Name",
                      icon: false,
                    },
                    {
                      title: "Number Of Sub Categories",
                      icon: false,
                    },

                    {
                      title: "Date",
                      icon: false,
                    },
                    {
                      title: "Action",
                      icon: false,
                    },
                  ]}
                  data={[
                    [
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    ],
                    [
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    ],
                  ]}
                  customStyleIndex={[]}
                  customStyleFn={[false, false, false, false, false, false]}
                />
                <div
                  className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                >
                  <p className="w-auto d-inline me-auto px-0">
                    <Skeleton
                      variant="text"
                      sx={{ width: "200px", fontSize: "1rem" }}
                    />
                  </p>
                  <div className="w-auto d-inline ms-auto">
                    <Skeleton
                      variant="text"
                      sx={{ width: "200px", fontSize: "1rem" }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <Table
                  type="Category"
                  options={[
                    {
                      title: "Category ID",
                      icon: false,
                    },
                    {
                      title: "Category Image",
                      icon: false,
                    },
                    {
                      title: "Category Name",
                      icon: false,
                    },
                    {
                      title: "Number Of Sub Categories",
                      icon: false,
                    },

                    {
                      title: "Date",
                      icon: false,
                    },
                    {
                      title: "Action",
                      icon: false,
                    },
                  ]}
                  // wholeData={wholeDataCompanies}
                  data={data}
                  customStyleIndex={[]}
                  customStyleFn={[false, false, false, false, false, false]}
                />
                {totalPages > 1 && (
                  <div
                    className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                  >
                    <div className="w-auto d-inline ms-auto">
                      <UsePagination
                        page={page}
                        pages={totalPages}
                        onChangePage={(page) => {
                          setLoading(true);
                          setPage(page);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          ) : loading ? (
            <>
              <Table
                options={[
                  {
                    title: "Sub-Category ID",
                    icon: false,
                  },
                  {
                    title: "Sub-Category Image",
                    icon: false,
                  },
                  {
                    title: "Parent Category Name",
                    icon: false,
                  },
                  {
                    title: "Sub Category Name",
                    icon: false,
                  },

                  {
                    title: "Date",
                    icon: false,
                  },
                  {
                    title: "Action",
                    icon: false,
                  },
                ]}
                data={[
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                  [
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />,
                  ],
                ]}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              <div
                className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
              >
                <p className="w-auto d-inline me-auto px-0">
                  <Skeleton
                    variant="text"
                    sx={{ width: "200px", fontSize: "1rem" }}
                  />
                </p>
                <div className="w-auto d-inline ms-auto">
                  <Skeleton
                    variant="text"
                    sx={{ width: "200px", fontSize: "1rem" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <Table
                type="Sub Category"
                options={[
                  {
                    title: "Sub-Category ID",
                    icon: false,
                  },
                  {
                    title: "Sub-Category Image",
                    icon: false,
                  },
                  {
                    title: "Parent Category Name",
                    icon: false,
                  },
                  {
                    title: "Sub Category Name",
                    icon: false,
                  },
                  {},
                  {
                    title: "Date",
                    icon: false,
                  },
                  {
                    title: "Action",
                    icon: false,
                  },
                ]}
                // wholeData={wholeDataCompanies}
                data={data}
                customStyleIndex={[]}
                customStyleFn={[false, false, false, false, false, false]}
              />
              {totalPages > 1 && (
                <div
                  className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                >
                  <div className="w-auto d-inline ms-auto">
                    <UsePagination
                      page={page}
                      pages={totalPages}
                      onChangePage={(page) => {
                        setLoading(true);
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </section>
      </section>
    </div>
  );
}
