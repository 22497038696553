import React, { useEffect, useState } from "react";
import classes from "./Manager.module.scss";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import PeopleActiveL from "../../components/Reusable/SVG/PeopleActiveL";
import Plus from "../../components/Reusable/SVG/Plus";
import Img from "../../components/Reusable/Common/Img";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import ManagerCard from "../../components/Reusable/Common/ManagerCard/ManagerCard";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import AddMemberManager from "../../components/Reusable/Common/AddMemberCard/AddMemberManager";
import Table from "../../components/Reusable/Common/Table/Table";
import { Radio, Skeleton } from "@mui/material";
import SearchInput from "../../components/Reusable/UI/InputFeild/SearchInput";
import Dropdown from "../../components/Reusable/SVG/Dropdown";
import SingleAd from "../../components/Reusable/Common/AddMemberCard/SingleAd";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import { useLocation, useNavigate } from "react-router-dom";
import AdActiveL from "../../components/Reusable/SVG/AdActiveL";
import AdviceActiveL from "../../components/Reusable/SVG/AdviceActiveL";
import { managerAccountsService } from "../../services/managerAccountsService";
import UsePagination from "../../components/Reusable/UI/Pagination/Pagination";
import { adsService } from "../../services/adsService";
import AdsCard from "../../components/Reusable/Common/Ads/AdsCard";
import { adviceService } from "../../services/adviceService";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import { subcategoryService } from "../../services/subCategoryService";
import { categoryService } from "../../services/categoryService";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import { mainDataHelper } from "../../Helper/MainData/MainData";
import { errorActions } from "../../store/reducers/errorSlice";
import { useDispatch } from "react-redux";
import LoadingTable from "../../components/Reusable/Common/Table/LoadingTable";
export default function Managers(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [option, setOption] = useState("Member");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [roleFilter, setRoleFilter] = useState("Default");
  const [firstLoad, setFirstLoad] = useState(true);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [wholeAdvice, setWholwAdvice] = useState([]);
  const [ads, setAds] = useState([]);
  const [advices, setAdvices] = useState([]);
  const [sort, setSort] = useState("default");
  const [role, setRole] = useState("select role");
  const [status, setStatus] = useState("select status");
  const [searchValue, setSearchValue] = useState("");
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesName, setCategoriesName] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("select category");
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    "select sub-category"
  );
  const location = useLocation();

  let getAds = async () => {
    let response = await adsService.getAds(page, 8);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      setAds(response.data.ads);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };
  let getAdvices = async () => {
    let query = "";
    query += `${searchValue ? `&search=${searchValue}` : ""}`;
    query += `${
      selectedCategory &&
      selectedSubCategory !== " " &&
      selectedCategory !== "select category"
        ? `${`&category=${selectedCategory.toLowerCase()}`}`
        : ""
    }`;
    query += `${
      selectedSubCategory &&
      selectedSubCategory !== " " &&
      selectedSubCategory !== "select sub-category"
        ? `&subcategory=${selectedSubCategory.toLowerCase()}`
        : ""
    }`;

    query += `${
      role && role !== " " && role !== "select role" ? `&role=${role}` : ""
    }`;
    query += `${
      status && status !== " " && status !== "select status"
        ? `&status=${status}`
        : ""
    }`;
    query += `${
      sort && sort !== " " && sort !== "default"
        ? `&date=${sort.toLowerCase()}`
        : ""
    }`;

    let response = await adviceService.getAdvices(page, 5, query);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      let arr = [];
      setWholwAdvice(response.data.advices);
      response.data.advices.map((item) => {
        let categories = item.categories.map((item) => item.name_en);
        arr.push([
          item.uuid,
          item.title_en,
          `${
            item.topManager
              ? item.topManager.name
              : `${item.marketingManager.firstName} ${item.marketingManager.lastName}`
          }`,
          item.role,
          categories,
          item.status,
          new Date(item.startDate).toLocaleDateString(),
          new Date(item.endDate).toLocaleDateString(),
        ]);
      });
      setAdvices(arr);
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };
  let getAllManagers = async () => {
    let query = "";
    let response = await managerAccountsService.getAllManagers(
      props.type === "Category Managers"
        ? "category-managers"
        : "marketing-managers",
      page,
      8,
      query
    );
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      setTotalPages(response.data.totalPages);
      setData(
        props.type === "Category Managers"
          ? response.data.categoryManagers
          : response.data.marketingManagers
      );
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    location.state &&
      location.state.openTap &&
      setOption(location.state.openTap);
  }, []);
  useEffect(() => {
    if (option === "Advice" && !firstLoad)
      mainDataHelper.getAllCategories(
        setServerError,
        setServerErrorMsg,
        setCategories,
        setCategoriesName
      );
  }, []);
  useEffect(() => {
    if (!firstLoad && option === "Advice") {
      selectedCategory !== "select category" &&
        mainDataHelper.getAllSubCategories(
          setServerError,
          setServerErrorMsg,
          selectedCategory,
          categories,
          setSubCategories,
          setSubCategoriesName
        );
    }
  }, [selectedCategory]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      if (props.type === "Category Managers") {
        setOption("Member");
      }

      if (page === 1) {
        option === "Member" && getAllManagers();
        option === "Ads" && getAds();
        option === "Advice" && getAdvices();
      } else setPage(1);
    }
  }, [props.type, option]);
  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
      option === "Member" && getAllManagers();
      option === "Ads" && getAds();
      option === "Advice" && getAdvices();
    }
  }, [page]);
  useEffect(() => {
    setLoading(true);
    option === "Member" && getAllManagers();
    option === "Ads" && getAds();
    option === "Advice" && getAdvices();
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstLoad) {
        setLoading(true);
        if (page === 1) option === "Advice" && getAdvices();
        else setPage(1);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  useEffect(() => {
    setLoading(true);
    if (page === 1) option === "Advice" && getAdvices();
    else setPage(1);
  }, [role, sort, selectedCategory, selectedSubCategory, status]);

  return (
    <div className={`w-100 ${classes["managers"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={confirmationMsg}
          />
        </Modal>
      )}

      {showAddMemberForm && (
        <Modal
          style={{ padding: "24px", height: `${window.innerHeight - 64}px` }}
          onClose={() => {
            setShowAddMemberForm(false);
          }}
        >
          <AddMemberManager
            setShowForm={setShowAddMemberForm}
            formType={props.type}
            onSuccess={() => {
              setConfirmationMsg(`Member Added Successfully`);
              setShowAddMemberForm(false);
              setLoading(true);
              getAllManagers();
              setShowConfirmation(true);
              setTimeout(() => {
                setShowConfirmation(false);
              }, 2000);
            }}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes["title"]}>
          <PageTitle
            onClick={() => {
              option === "Member"
                ? setShowAddMemberForm(true)
                : history(
                    `/account/marketing-manager/new-${
                      option === "Ads" ? "ad" : "advice"
                    }`
                  );
            }}
            text={props.type}
            icon={
              option === "Member" ? (
                <PeopleActiveL />
              ) : option === "Ads" ? (
                <AdActiveL />
              ) : (
                <AdviceActiveL />
              )
            }
            buttonText={`Add ${option}`}
            buttonType="normal"
            buttonIcon={<Plus />}
          />
        </div>
        <section className={`${classes["main-section"]}`}>
          {props.type === "Marketing Managers" && (
            <div className={`row px-0 mx-0   ${classes.options}`}>
              <SelectOption
                onSelect={(val) => {
                  setOption(val);
                }}
                default={option}
                options={["Member", "Ads", "Advice"]}
              />
            </div>
          )}
          {option === "Member" &&
            (loading ? (
              <div className="row mx-0 px-0 ">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                  <div key={index} className="col-xl-3 col-lg-6  mb-4">
                    <ManagerCard key={index} loading={true} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="row mx-0 px-0 h-auto ">
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <div
                      key={index}
                      className="col-xl-3 col-lg-6  mb-4 h-auto  "
                    >
                      <ManagerCard
                        id={item._id}
                        show={true}
                        key={index}
                        type={
                          props.type === "Category Managers"
                            ? "category-manager"
                            : "marketing-manager"
                        }
                        profilePicture={item.pic}
                        options={[
                          {
                            title: "Name",
                            value: `${item.firstName} ${item.lastName}`,
                          },
                          { title: "ID", value: item.uuid },
                        ]}
                      />
                    </div>
                  ))
                ) : (
                  <div className="w-100 row justify-content-center pt-5 pb-4">
                    <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                      There are No {props.type} Found.
                    </div>
                  </div>
                )}
                {totalPages > 1 && (
                  <div
                    className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                  >
                    <div className="w-auto d-inline mx-auto">
                      <UsePagination
                        page={page}
                        pages={totalPages}
                        onChangePage={(page) => {
                          setLoading(true);
                          setPage(page);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}

          {option === "Ads" &&
            (loading ? (
              <div className="row mx-0 px-0 ">
                {[0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
                  <AdsCard key={item} loading={true} />
                ))}
              </div>
            ) : (
              <>
                <div className="row mx-0 px-0">
                  {ads.length > 0 ? (
                    ads.map((item, index) => (
                      <AdsCard
                        onSuccess={() => {
                          setLoading(true);
                          getAds();
                        }}
                        key={index}
                        item={item}
                      />
                    ))
                  ) : (
                    <div className="w-100 row justify-content-center pt-5 pb-4">
                      <div className="no-found-data mx-auto w-auto  text-center w-100 ">
                        There are No Ads Found
                      </div>
                    </div>
                  )}
                </div>
                {totalPages > 1 && (
                  <div
                    className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                  >
                    <div className="w-auto d-inline mx-auto">
                      <UsePagination
                        page={page}
                        pages={totalPages}
                        onChangePage={(page) => {
                          setLoading(true);
                          setPage(page);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            ))}
          {option === "Advice" && (
            <div className="">
              {!firstLoad && (
                <div
                  className={`row  align-items-end d-flex mx-0 px-0  ${classes["table-option"]}`}
                >
                  <div className="col-xl-3 px-0 mb-xl-0 ">
                    <SearchInput
                      onChange={(val) => setSearchValue(val.target.value)}
                      placeholder={`Search for a Advice`}
                    />
                  </div>
                  <div className="col-xl-9 px-0 ps-xl-2">
                    <div className="row px-0 mx-0  mb-xl-0 mb-2">
                      <div className="col-xl-2 col-6  pe-1 px-0">
                        <InputGroup
                          options={["select category", ...categoriesName]}
                          filter={true}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          // error={showError && formDataErr.password}
                          // errorMsg={formDataErr.password}
                          // name="password"
                          type="dropdown"
                          value={"Category"}
                          default={selectedCategory}
                        />
                      </div>
                      {selectedCategory && (
                        <div className="col-xl-2 col-6  ps-1 pe-1 px-0">
                          <InputGroup
                            options={[
                              "select sub-category",
                              ...subCategoriesName,
                            ]}
                            filter={true}
                            onChange={(e) =>
                              setSelectedSubCategory(e.target.value)
                            }
                            // error={showError && formDataErr.password}
                            // errorMsg={formDataErr.password}
                            // name="password"
                            type="dropdown"
                            value={"Sub Category"}
                            default={selectedSubCategory}
                          />
                        </div>
                      )}
                      <div className="col-xl-2 col-6  ps-1 pe-1 px-0">
                        <InputGroup
                          options={[
                            "select status",
                            "Scheduled",
                            "Running",
                            "Ended",
                          ]}
                          filter={true}
                          onChange={(e) => setStatus(e.target.value)}
                          type="dropdown"
                          value={"Status"}
                          default={status}
                        />
                      </div>
                      <div className="col-xl-2 col-6  ps-1 pe-1 px-0">
                        <InputGroup
                          options={[
                            "select role",
                            "Top Manager",
                            "Marketing Manager",
                          ]}
                          filter={true}
                          onChange={(e) => setRole(e.target.value)}
                          // error={showError && formDataErr.password}
                          // errorMsg={formDataErr.password}
                          // name="password"
                          type="dropdown"
                          value={"role"}
                          default={role}
                        />
                      </div>
                      <div className="col-xl-2 col-6  px-0 ps-1">
                        <InputGroup
                          options={["Latest", "Earliest", "Default"]}
                          filter={true}
                          onChange={(e) => setSort(e.target.value)}
                          // error={showError && formDataErr.password}
                          // errorMsg={formDataErr.password}
                          // name="password"
                          type="dropdown"
                          value="Order By"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading ? (
                <LoadingTable />
              ) : (
                <>
                  <Table
                    type="Advice"
                    options={[
                      {
                        title: "ID",
                        icon: false,
                      },
                      {
                        title: "Title",
                        icon: false,
                      },
                      {
                        title: "Advice Owner",
                        icon: false,
                      },
                      {
                        title: "Owner Role",
                        icon: false,
                        filterOptions: ["option1", "option2", "Default"],
                        setOption: (val) => {
                          setRoleFilter(val);
                        },
                        selectedOption: roleFilter,
                      },
                      {
                        title: "Category",
                        icon: false,
                      },

                      {
                        title: "Status",
                        icon: false,
                      },

                      {
                        title: "Start Date",
                        icon: false,
                      },
                      {
                        title: "End Date",
                        icon: false,
                      },
                    ]}
                    wholeData={wholeAdvice}
                    data={advices}
                    onSuccess={() => {
                      setLoading(true);
                      getAdvices();
                    }}
                    customStyleIndex={[]}
                    filterOption={[]}
                    customStyleFn={[false, false, false, false, false, false]}
                  />
                  {totalPages > 1 && (
                    <div
                      className={`row mx-0 px-0 align-items-center ${classes.pagination}`}
                    >
                      <div className="w-auto d-inline mx-auto">
                        <UsePagination
                          page={page}
                          // selected={page1}
                          pages={totalPages}
                          onChangePage={(page) => {
                            setLoading(true);
                            setPage(page);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </section>
      </section>
    </div>
  );
}
