import React, { useEffect, useState } from "react";
import "./index.css";
import five from "../../assests/5.png";
import { useSelector } from "react-redux";
export default function Section3() {
  const { language } = useSelector((state) => state.language);

  const [width, setWidth] = useState(1400);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  return (
    <div dir={language === "ar" && "rtl"}>
      <div className="container">
        <div
          style={{
            height: `${
              window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
            }`,
            paddingBottom: `${window.innerWidth < 1200 && "100px"}`,
          }}
          className="row   d-flex align-items-center   px-0 mx-0 "
        >
          <div
            style={{
              height: `${
                window.innerWidth > 1200 ? `${window.innerHeight}px` : "auto"
              }`,
            }}
            className={`col-xl-8   order-2 d-flex flex-column ps-xl-5 ${
              language === "en" ? "text-xl-start" : "text-xl-end  "
            } text-center  pt-xl-0  justify-content-xl-center position-relative `}
          >
            <h1
              className={`main-title   text-center  ${
                language === "en" ? `text-xl-start` : `text-xl-end`
              }`}
            >
              {language === "en"
                ? `What is Voucher deal?`
                : `ما هي صفقات الكوبونات ؟
`}{" "}
            </h1>
            <p
              className={`sub-title ${
                language === "en" ? "text-xl-start" : "text-xl-end"
              } text-center`}
            >
              {language === "en"
                ? `They are pre-prepared group purchase offers from Eshtarena with a
              specific price, quantity and supplier on a discounted purchase
              voucher without terms or conditions from various suppliers.`
                : `هي عروض شراء جماعي محضره مسبقاً من اشترينا محدده السعر و الكميه و المورد على كوبون شراء مخفض دون شروط او احكام من مختلف الموردين.
`}
            </p>
            <ul className="px-0 mx-0">
              <div
                className={`row px-0  row-cols-2 ${
                  language === "en"
                    ? "text-start justify-content-start"
                    : "text-end justify-content-end"
                } mx-0 align-items-center`}
              >
                <div
                  className={`w-auto col d-inline ${
                    language === "en" ? "text-end" : "text-start"
                  } px-0 mx-0 d-xl-inline d-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className=" col  d-flex flex-grow-1 d-inline mx-xl-0 mx-auto">
                  <li
                    className={` ${
                      language === "en" ? "text-xl-start" : "text-xl-end"
                    } text-center`}
                  >
                    {language === "en"
                      ? `Buy a purchase voucher from the supplier you choose in the
                    group offer at a discounted price and enjoy spending the
                    full voucher value at the supplier without terms or
                    conditions`
                      : `اشتري كوبون شراء من المورد الي تختاره في العرض الجماعي بسعر مخفض و استمتع بإنفاق قيمة الكوبون كامله لدى المورد دون شروط او احكام`}
                  </li>
                </div>
              </div>
              <div
                className={`row px-0  row-cols-2 ${
                  language === "en"
                    ? "text-start justify-content-star"
                    : "text-end justify-content-end"
                } t mx-0 align-items-center`}
              >
                <div
                  className={`w-auto col d-inline ${
                    language === "en" ? "text-start" : "text-end"
                  }  px-0 mx-0 d-xl-inline d-none`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    class="bi bi-dot"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                  </svg>
                </div>
                <div className=" col  d-flex flex-grow-1 d-inline mx-xl-0 mx-auto">
                  <li
                    className={` ${
                      language === "en" ? "text-xl-start" : "text-xl-end"
                    } text-center`}
                  >
                    {language === "en"
                      ? `Save before you buy from the brands you love and pay from
                    your voucher wallet on eshtarena at the supplier's branches
                    or website.`
                      : `وفر قبل ما تشترى من الماركات التي تحبها و ادفع من محفظة كوبوناتك على اشترينا عند فروع المورد او الموقع الإلكتروني الخاص به
`}
                  </li>
                </div>
              </div>
            </ul>
          </div>
          <div
            className={`col-xl-4  order-1 pt-xl-0 pt-5 ${
              language === "ar" ? "text-xl-end" : "text-xl-start"
            } text-center justify-content-center align-items-center d-flex mb-xl-0 mb-5`}
          >
            <img
              alt="image_feature"
              style={{
                width: `100%`,
                objectFit: "contain",
                height: "100%",
                maxwidth: `${window.innerWidth}px`,
                maxHeight: `${window.innerHeight - 200}px`,
              }}
              src={five}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
