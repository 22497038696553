import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import classes from "./Voucher.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/Reusable/UI/Modal/Modal";
import { errorActions } from "../../store/reducers/errorSlice";
import Button from "../../components/Reusable/UI/Button/Button";
import { serverErrorCatcher } from "../../Helper/ServerErrorCatcher";
import Section from "../../components/Reusable/Common/Section/Section";
import VoucherActieveL from "../../components/Reusable/SVG/VoucherActieveL";
import FailPop from "../../components/Reusable/Common/AddMemberCard/FailPop";
import InputGroup from "../../components/Reusable/UI/InputGroup/InputGroup";
import { vouchersEshtreanaService } from "../../services/VouchersEshtreana";
import PageTitle from "../../components/Reusable/Common/PageTitle/PageTitle";
import CompanyNav from "../../components/Reusable/Common/CompanyNav/CompanyNav";
import SelectOption from "../../components/Reusable/Common/SelectOption/SelectOption";
import ManagerHeader from "../../components/Reusable/Common/MemberCard/ManagerHeader";
import StartDealFoem from "../../components/Reusable/Common/AddMemberCard/StartDealForm";
import SuccessPopup from "../../components/Reusable/Common/AddMemberCard/SuccessPopup";
import TermsSections from "../../components/Reusable/Common/TermsSections/TermsSections";
export default function VoucherResponseDetails(props) {
  //--------------------Declarations---------------------
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [voucher, setVoucher] = useState(null);
  const [type, setType] = useState("Needs Review");
  const [option, setOption] = useState("Main Data");
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [suggestQuantity, setSuggestQuantity] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [suggestExpireDate, setSuggestExpireDate] = useState(false);
  const [showStartDealForm, setShowStartDealForm] = useState(false);
  const [suggestBuyingPrice, setSuggestBuyingPrice] = useState(false);
  const [suggestVoucherValue, setSuggestVoucherValue] = useState(false);
  const [ButtonSendRequestLoading, setButtonSendRequestLoading] =
    useState(false);
  const [data, setData] = useState({
    quantity: null,
    voucherPrice: null,
    buyingPrice: null,
    expireDate: null,
  });

  //--------------------Functions---------------------
  let getSingleVoucher = async () => {
    let response = await vouchersEshtreanaService.getSingleVoucher(id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid === true) {
      response.data.voucher.status === "Need review"
        ? setType("Needs Review")
        : setType(response.data.voucher.status);
      setVoucher(response.data.voucher);
      response.data.voucher.status === "Need review" &&
        setData({
          quantity: response.data.voucher.quantityNegotiation.isSupplierAccepted
            ? "Accepted"
            : null,
          voucherPrice: response.data.voucher.voucherPriceNegotiation
            .isSupplierAccepted
            ? "Accepted"
            : null,
          buyingPrice: response.data.voucher.buyingPriceNegotiation
            .isSupplierAccepted
            ? "Accepted"
            : null,
          expireDate: response.data.voucher.expireDateNegotiation
            .isSupplierAccepted
            ? "Accepted"
            : null,
        });
    } else {
      dispatch(
        errorActions.setError({
          error: true,
          errorMsg: localStorage.getItem("errorMsg"),
        })
      );
    }

    setLoading(false);
  };

  let sendVoucherResponse = async () => {
    if (
      data.quantity === "Accepted" &&
      data.buyingPrice === "Accepted" &&
      data.voucherPrice === "Accepted" &&
      data.expireDate === "Accepted" &&
      sellingPrice
    ) {
      setShowStartDealForm(true);
    } else {
      let response = await vouchersEshtreanaService.updateVoucherRequest({
        quantity: data.quantity,
        buyingPrice: data.buyingPrice,
        voucherPrice: data.voucherPrice,
        expireDate: data.expireDate,
        voucherId: id,
        sellingPrice,
      });
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);
      if (Valid === true) {
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          history("/account/voucher");
        }, 2000);
      } else {
        dispatch(
          errorActions.setError({
            error: true,
            errorMsg: localStorage.getItem("errorMsg"),
          })
        );
      }
    }
  };
  //--------------------UseEffects---------------------
  useEffect(() => {
    getSingleVoucher();
  }, [id]);

  useEffect(() => {
    if (!showStartDealForm) {
      setButtonSendRequestLoading(false);
    }
  }, [showStartDealForm]);
  const [sellingPrice, setSellingPrice] = useState(null);
  return (
    <div className={`w-100 ${classes["voucher"]}`}>
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showConfirmation && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setShowConfirmation(false)}
        >
          <SuccessPopup
            setShowForm={setShowConfirmation}
            message1={`Voucher Request Sent Successfully`}
          />
        </Modal>
      )}

      {showStartDealForm && (
        <Modal
          onClose={() => !data.quantity && setShowStartDealForm(false)}
          style={{ padding: "24px" }}
        >
          <StartDealFoem
            id={id}
            data={data}
            voucher={voucher}
            sellingPrice={sellingPrice}
            setShowForm={() => setShowStartDealForm(false)}
          />
        </Modal>
      )}
      <CompanyNav />
      <section className="custom-container">
        <div className={classes["title"]}>
          <PageTitle text="Voucher Details" icon={<VoucherActieveL />} />
        </div>
        {loading ? (
          <section className={`${classes["main-section"]}`}>
            <ManagerHeader loading={true} />
            <div className={`row px-0 mx-0   ${classes.options} mb-0`}>
              <div className="row px-0 mx-0">
                <div className="col-xl-2 col-lg-4">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "2rem", width: "90%" }}
                  />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "2rem", width: "90%" }}
                  />
                </div>
                <div className="col-xl-2 col-lg-4">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "2rem", width: "90%" }}
                  />
                </div>
              </div>
            </div>
            <>
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div
                  key={index}
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "300px" }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "250px" }}
                        />
                      </p>
                      <span>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "2rem", width: "300px" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </section>
        ) : (
          <section className={`${classes["main-section"]}`}>
            <ManagerHeader
              id={voucher.supplier._id}
              profilePicture={voucher.supplier.pic}
              type="supplier"
              data={{
                name: voucher.supplier.name_en,
                categories: voucher.categories,
              }}
              response={type}
            />
            <div className={`row px-0 mx-0    ${classes.options}`}>
              <SelectOption
                onSelect={(val) => setOption(val)}
                default={option}
                options={
                  (type === "Accepted" && [
                    "Main Data",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                  ]) ||
                  (type === "Needs Review" && [
                    "Main Data",
                    "Supplier Payment Terms",
                    "Customer Payment Terms",
                    "Supplier Response",
                  ]) ||
                  (type === "Rejected" && ["Main Data", "Supplier Response"]) ||
                  []
                }
              />
            </div>

            {option === "Main Data" && (
              <>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Voucher Code</p>
                      <span>{voucher.uuid}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Voucher Title</p>
                      <span>{voucher.title_en}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>عنوان الكوبون</p>
                      <span>{voucher.title_ar}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Quantity</p>
                      <span>{voucher.quantity}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Eshtreana Buying Price</p>
                      <span>{voucher.buyingPrice}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Eshtreana Selling Price</p>
                      <span>{voucher.sellingPrice}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`row  px-0 mx-0 mt-2 ${classes["company-profile--data"]}`}
                >
                  <div className="col-md-6 col-12 px-0">
                    <div dir="auto" className={classes["single-item"]}>
                      <p>Voucher Expiry Date</p>
                      <span>{voucher.expireDate}</span>
                    </div>
                  </div>
                  <div dir="auto" className="col-md-6 col-12 px-0">
                    <div className={classes["single-item"]}>
                      <p>Voucher Value</p>
                      <span>{voucher.voucherPrice}</span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {option === "Supplier Response" && type === "Needs Review" && (
              <>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={voucher.quantity}
                        disable={true}
                        value={"Quantity"}
                      />
                    </div>
                  </div>
                  {!voucher.quantityNegotiation.isSupplierAccepted && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={voucher.quantityNegotiation.quantity}
                          disable={true}
                          onChange={(e) =>
                            setData((prev) => ({
                              ...prev,
                              quantity: e.target.value,
                            }))
                          }
                          value={"Supplier Suggested"}
                        />
                      </div>
                    </div>
                  )}
                  {suggestQuantity && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.quantity}
                          onChange={(e) =>
                            setData((prev) => ({
                              ...prev,
                              quantity: e.target.value,
                            }))
                          }
                          value={"New Quantity"}
                        />
                      </div>
                    </div>
                  )}
                  {!voucher.quantityNegotiation.isSupplierAccepted && (
                    <div className="col-lg-3 justify-content-end align-self-center ms-auto mt-3  d-flex px-0">
                      {!voucher.quantityNegotiation.isSupplierAccepted &&
                        (data.quantity === "Accepted" ? (
                          <div className={classes.input}>
                            <p className="mt-2 pt-1">Accepted</p>
                          </div>
                        ) : (
                          <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                            <div className={`  mb-pb-0`}>
                              <Button
                                onClick={() => {
                                  // setOption("Media");
                                  if (!suggestQuantity) {
                                    setData((prev) => ({
                                      ...prev,
                                      quantity: "Accepted",
                                    }));
                                  }
                                  setSuggestQuantity(false);
                                }}
                                // icon={<Plus />}
                                color={"#D1E6FF"}
                                borderColor={"#134074"}
                                fontColor={"#134074"}
                                className={
                                  "w-auto d-inline px-3  ms-auto text-end"
                                }
                                type="normal"
                                text={suggestQuantity ? "Cancel" : "Accept"}
                              />
                            </div>
                          </div>
                        ))}
                      {
                        <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => {
                                data.quantity === "Accepted"
                                  ? setData((prev) => ({
                                      ...prev,
                                      quantity: null,
                                    }))
                                  : setSuggestQuantity(true);
                              }}
                              // icon={<Plus />}

                              className={"w-auto d-inline px-3   "}
                              type="reverse"
                              text={
                                data.quantity === "Accepted"
                                  ? "Cancel"
                                  : "Suggest"
                              }
                            />
                          </div>
                        </div>
                      }
                    </div>
                  )}
                </div>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={voucher.buyingPrice}
                        disable={true}
                        value={"Buying Price"}
                      />
                    </div>
                  </div>
                  {!voucher.buyingPriceNegotiation.isSupplierAccepted && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={voucher.buyingPriceNegotiation.buyingPrice}
                          disable={true}
                          value={"Supplier Suggested"}
                        />
                      </div>
                    </div>
                  )}

                  {suggestBuyingPrice && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.buyingPrice}
                          //   name="industry"
                          onChange={(e) =>
                            setData((prev) => ({
                              ...prev,
                              buyingPrice: e.target.value,
                            }))
                          }
                          value={"New Buying Price"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-lg-3 justify-content-end align-self-center ms-auto mt-3  d-flex px-0">
                    {!voucher.buyingPriceNegotiation.isSupplierAccepted &&
                      (data.buyingPrice === "Accepted" ? (
                        <div className={classes.input}>
                          <p className="mt-2">Accepted</p>
                        </div>
                      ) : (
                        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => {
                                if (!suggestBuyingPrice) {
                                  setData((prev) => ({
                                    ...prev,
                                    buyingPrice: "Accepted",
                                  }));
                                }
                                setSuggestBuyingPrice(false);
                              }}
                              color={"#D1E6FF"}
                              borderColor={"#134074"}
                              fontColor={"#134074"}
                              className={
                                "w-auto d-inline px-3  ms-auto text-end"
                              }
                              type="normal"
                              text={suggestBuyingPrice ? "Cancel" : "Accept"}
                            />
                          </div>
                        </div>
                      ))}
                    {
                      <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              data.buyingPrice === "Accepted"
                                ? setData((prev) => ({
                                    ...prev,
                                    buyingPrice: null,
                                  }))
                                : setSuggestBuyingPrice(true);
                            }}
                            className={"w-auto d-inline px-3   "}
                            type="reverse"
                            text={
                              data.buyingPrice === "Accepted"
                                ? "Cancel"
                                : "Suggest"
                            }
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={voucher.voucherPrice}
                        disable={true}
                        value={"Voucher Value"}
                      />
                    </div>
                  </div>
                  {!voucher.voucherPriceNegotiation.isSupplierAccepted && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={voucher.voucherPriceNegotiation.voucherPrice}
                          disable={true}
                          value={"Supplier Suggested"}
                        />
                      </div>
                    </div>
                  )}
                  {suggestVoucherValue && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={data.voucherPrice}
                          onChange={(e) =>
                            setData((prev) => ({
                              ...prev,
                              voucherPrice: e.target.value,
                            }))
                          }
                          value={"New Voucher Value"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-lg-3 justify-content-end align-self-center ms-auto mt-3  d-flex px-0">
                    {!voucher.voucherPriceNegotiation.isSupplierAccepted &&
                      (data.voucherPrice === "Accepted" ? (
                        <div className={classes.input}>
                          <p className="mt-2 pt-1">Accepted</p>
                        </div>
                      ) : (
                        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => {
                                if (!suggestVoucherValue) {
                                  setData((prev) => ({
                                    ...prev,
                                    voucherPrice: "Accepted",
                                  }));
                                }
                                setSuggestVoucherValue(false);
                              }}
                              color={"#D1E6FF"}
                              borderColor={"#134074"}
                              fontColor={"#134074"}
                              className={
                                "w-auto d-inline px-3  ms-auto text-end"
                              }
                              type="normal"
                              text={suggestVoucherValue ? "Cancel" : "Accept"}
                            />
                          </div>
                        </div>
                      ))}
                    {
                      <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              // setOption("Main Data");
                              data.voucherPrice === "Accepted"
                                ? setData((prev) => ({
                                    ...prev,
                                    voucherPrice: null,
                                  }))
                                : setSuggestVoucherValue(true);
                            }}
                            // icon={<Plus />}

                            className={"w-auto d-inline px-3   "}
                            type="reverse"
                            text={
                              data.voucherPrice === "Accepted"
                                ? "Cancel"
                                : "Suggest"
                            }
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-3 px-0 pe-lg-2">
                    <div className={`${classes.input} `}>
                      <InputGroup
                        default={voucher.expireDate}
                        disable={true}
                        type="date"
                        value={"Voucher Expiry Date"}
                      />
                    </div>
                  </div>
                  {!voucher.expireDateNegotiation.isSupplierAccepted && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={classes.input}>
                        <InputGroup
                          default={voucher.expireDateNegotiation.expireDate}
                          disable={true}
                          type="date"
                          value={"Supplier Suggested"}
                        />
                      </div>
                    </div>
                  )}
                  {suggestExpireDate && (
                    <div className="col-lg-3 px-0 pe-lg-2">
                      <div className={`${classes.input} `}>
                        <InputGroup
                          default={data.expireDate}
                          onChange={(e) =>
                            setData((prev) => ({
                              ...prev,
                              expireDate: e.target.value,
                            }))
                          }
                          type="date"
                          value={"New Voucher Expiry Date"}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-lg-3 justify-content-end align-self-center ms-auto mt-3   d-flex px-0">
                    {!voucher.expireDateNegotiation.isSupplierAccepted &&
                      (data.expireDate === "Accepted" ? (
                        <div className={classes.input}>
                          <p className="mt-2 pt-1">Accepted</p>
                        </div>
                      ) : (
                        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
                          <div className={`  mb-pb-0`}>
                            <Button
                              onClick={() => {
                                if (!suggestExpireDate) {
                                  setData((prev) => ({
                                    ...prev,
                                    expireDate: "Accepted",
                                  }));
                                }
                                setSuggestExpireDate(false);
                              }}
                              // icon={<Plus />}
                              color={"#D1E6FF"}
                              borderColor={"#134074"}
                              fontColor={"#134074"}
                              className={
                                "w-auto d-inline px-3  ms-auto text-end"
                              }
                              type="normal"
                              text={suggestExpireDate ? "Cancel" : "Accept"}
                            />
                          </div>
                        </div>
                      ))}
                    {
                      <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
                        <div className={`  mb-pb-0`}>
                          <Button
                            onClick={() => {
                              // setOption("Main Data");
                              data.expireDate === "Accepted"
                                ? setData((prev) => ({
                                    ...prev,
                                    expireDate: null,
                                  }))
                                : setSuggestExpireDate(true);
                            }}
                            // icon={<Plus />}

                            className={"w-auto d-inline px-3   "}
                            type="reverse"
                            text={
                              data.expireDate === "Accepted"
                                ? "Cancel"
                                : "Suggest"
                            }
                          />
                        </div>
                      </div>
                    }
                  </div>

                  {/* </div> */}
                </div>
                <div className="row px-0 mx-0 align-items-center">
                  <div className="col-lg-6 px-0 pe-lg-2">
                    <div className={classes.input}>
                      <InputGroup
                        default={sellingPrice}
                        //   name="industry"
                        onChange={(e) => setSellingPrice(e.target.value)}
                        // name="sectionTitle"
                        //   type="date"
                        value={"Selling Price"}
                        //   underLineText="when users cant join anymore"
                      />
                    </div>
                  </div>

                  {/* </div> */}
                </div>
                {/* </div> */}
                {data.buyingPrice &&
                data.expireDate &&
                data.quantity &&
                data.voucherPrice &&
                sellingPrice ? (
                  <div
                    className={`row mx-0 px-0  justify-content-end mb-0 -pb-0`}
                  >
                    {/* <div className="w-auto  ms-auto"> */}

                    <div
                      className={`w-auto d-inline  px-0 ps-lg-2 mb-pb-0 ${classes.input}`}
                    >
                      <div className={`  mb-pb-0`}>
                        {ButtonSendRequestLoading ? (
                          <Button
                            color={"#D1E6FF"}
                            borderColor={"#134074"}
                            fontColor={"#134074"}
                            // className={"w-auto d-inline px-3  ms-auto text-end"}
                            type="normal"
                            text={
                              <div className="text-center px-5 my-0 py-0">
                                <span className="spinner-border text-white spinner-border-sm"></span>
                              </div>
                            }
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              // setOption("Media");
                              setButtonSendRequestLoading(true);
                              sendVoucherResponse();
                            }}
                            // icon={<Plus />}
                            color={"#D1E6FF"}
                            borderColor={"#134074"}
                            fontColor={"#134074"}
                            className={"w-auto d-inline px-5  ms-auto text-end"}
                            type="normal"
                            text={"Send Request"}
                          />
                        )}
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                ) : null}
              </>
            )}
            {option === "Supplier Response" && type === "Rejected" && (
              <>
                <div className="row mt-0 px-0 mx-0">
                  <div className="col-12  px-0">
                    <Section
                      border={true}
                      title="Supplier rejection message"
                      text={voucher.rejectionMessage}
                    />
                  </div>
                </div>
              </>
            )}

            {option === "Customer Payment Terms" && (
              <TermsSections
                terms={voucher.customerPaymentTerms}
                border={true}
              />
            )}
            {option === "Supplier Payment Terms" && (
              <TermsSections
                terms={voucher.supplierPaymentTerms}
                border={true}
              />
            )}

            {type === "Accepted" && (
              <div
                className={` row mx-0 px-0 ${classes.input}  justify-content-end mb-pb-0`}
              >
                <div className="w-auto d-md-inline mb-md-0 mb-3 px-0 pe-2 mb-pb-0">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() =>
                        history("/account/voucher", { replace: true })
                      }
                      className={"w-auto d-inline px-5   "}
                      type="reverse"
                      text={"Ignore"}
                    />
                  </div>
                </div>
                <div className="w-auto d-inline  px-0 ps-md-2 pe-md-0 pe-2 mb-pb-0">
                  <div className={`  mb-pb-0`}>
                    {ButtonSendRequestLoading ? (
                      <Button
                        color={"#D1E6FF"}
                        borderColor={"#134074"}
                        fontColor={"#134074"}
                        type="normal"
                        text={
                          <div className="text-center px-5 my-0 py-0">
                            <span className="spinner-border text-white spinner-border-sm"></span>
                          </div>
                        }
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          // setButtonSendRequestLoading(true);
                          setShowStartDealForm(true);
                        }}
                        type="normal"
                        color={"#D1E6FF"}
                        fontColor={"#134074"}
                        borderColor={"#134074"}
                        text={"Start Deal"}
                        className={"w-auto d-inline px-5  ms-auto text-end"}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {type === "Rejected" && (
              <div
                className={` row mx-0 px-0 ${classes.input}  justify-content-end mb-pb-0`}
              >
                <div className="w-auto d-md-inline mb-md-0 mb-3 px-0  mb-pb-0">
                  <div className={`  mb-pb-0`}>
                    <Button
                      onClick={() =>
                        history("/account/voucher", { replace: true })
                      }
                      className={"w-auto d-inline px-5   "}
                      type="reverse"
                      text={"Ignore"}
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
        )}
      </section>
    </div>
  );
}
//Done
