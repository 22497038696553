import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import classes from "./Pagination.module.scss";
import { useState } from "react";
import { useEffect } from "react";
const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

export default function UsePagination(props) {
  const [current, setCurrent] = useState(1);
  const { items } = usePagination({
    count: props.pages,
  });

  useEffect(() => {
    setCurrent(props.page);
  }, [props.page]);

  return (
    <nav className="w-auto d-inline ">
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "  . . .  ";
          } else if (type === "page") {
            children = (
              <button
                key={index}
                type="button"
                className={
                  current === index
                    ? classes["pagination-item--selected"]
                    : classes["pagination-item"]
                }
                onClick={() => {
                  if (page !== current) {
                    setCurrent(page);
                    props.onChangePage(page);
                  }
                }}
              >
                <span>{page}</span>
              </button>
            );
          } else {
            children =
              type === "next"
                ? current !== props.pages && (
                    <button
                      key={index}
                      onClick={() => {
                        if (type === "next") {
                          props.onChangePage(current + 1);
                          setCurrent(current + 1);
                        } else {
                          props.onChangePage(current - 1);
                          setCurrent(current - 1);
                        }
                      }}
                      type="button"
                      className={` ${classes["pagination-item--type"]}  `}
                      // {...item}
                    >
                      {type}
                    </button>
                  )
                : current !== 1 && (
                    <button
                      onClick={() => {
                        if (type === "next") {
                          props.onChangePage(current + 1);
                          setCurrent(current + 1);
                        } else {
                          props.onChangePage(current - 1);
                          setCurrent(current - 1);
                        }
                      }}
                      type="button"
                      className={` ${classes["pagination-item--type"]}  `}
                      // {...item}
                    >
                      {type}
                    </button>
                  );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}
