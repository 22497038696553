import React, { useState } from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
import InputGroup from "../../UI/InputGroup/InputGroup";
import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import RemoveCard from "./RemoveMember";
import { useNavigate } from "react-router-dom";
import { adviceService } from "../../../../services/adviceService";
import { useEffect } from "react";
import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
import FailPop from "./FailPop";
export default function SigleAdvice(props) {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [comment, setComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setComments(props.item.comments);
  }, []);
  let addComment = async () => {
    if (comment) {
      let response = await adviceService.addComment(props.item._id, comment);
      let Valid = false;
      Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

      if (Valid) {
        setComments((prev) => [comment, ...prev]);
        setComment(null);
        setLoading(false);
      }
    }
  };
  let deleteAdvice = async () => {
    let response = await adviceService.deleteAdvice(props.item._id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid) {
      setShowDeleteWarning(false);
      props.onSuccess();
    } else {
    }
  };
  return (
    <div
      className="row px-0 mx-0"
      // className="border border-danger overflow-hidden"
      style={{
        height: `${window.innerHeight - 112}px`,
        overflowY: "scroll",
        overflowX: "hidden",
        // width: "fit-content",
      }}
    >
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {showDeleteWarning && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowDeleteWarning(false);
          }}
        >
          <RemoveCard
            setShowForm={setShowDeleteWarning}
            message1="Are you sure you want to remove this Advice?"
            message2="By removing this Advice, it won’t show anymore."
            buttonText="Remove Advice"
            onClick={() => deleteAdvice()}
          />
        </Modal>
      )}
      <div className="row mx-0 px-0 ">
        <div className={`col-10 px-0 ${classes.title}`}>Advice Details</div>
        <div
          onClick={() => props.setShowForm(false)}
          className="cursor col-2 px-0 text-end"
        >
          <Close color="#134074" />
        </div>
      </div>
      <div className={`${classes["single-item"]} px-0`}>
        <p>
          Advice ID : <span> {props.item.uuid} </span>
        </p>
      </div>
      <div className={`row px-0 mx-0 ${classes["single-item"]} px-0`}>
        {/* <div className="row "> */}
        <div dir="auto" className="col-6 px-0">
          <p>
            Advice Title :<span> {props.item.title_en}</span>
          </p>
        </div>
        <div dir="auto" className="col-6 px-0">
          <p>
            عنوان النصيحة :<span> {props.item.title_ar}</span>
            {/* <span> {props.item.title_ar}</span> عنوان النصيحة : */}
          </p>
        </div>
        {/* </div> */}
      </div>
      <div className={`${classes["single-item"]} px-0`}>
        <p>
          Advice Owner :<span> {props.item.role} </span>
        </p>
      </div>
      <div className={`${classes["single-item"]} px-0`}>
        <p>
          Category :
          <span>
            {props.item.categories.map((item) => (
              <> {item.item.name_en} &nbsp;</>
            ))}
          </span>
        </p>
      </div>
      <div className={`${classes["single-item"]} px-0`}>
        <p>
          Sub Category :
          <span>
            {props.item.subCategories.map((item) => (
              <> {item.item.name_en} &nbsp;</>
            ))}
          </span>
        </p>
      </div>
      <div className={`row px-0 mb-3 mx-0 ${classes["single-item"]} `}>
        {/* <div className="row px-0"> */}
        <div dir="auto" className="col-6    ps-2">
          <p className="mb-1 mx-0 px-0">Content</p>
          <div className={classes.content}>{props.item.advice_en}</div>
        </div>
        <div dir="auto" className="col-6  pe-2">
          <p className="mb-1 px-0 mx-0">المحتوي </p>
          <div className={classes.content}>{props.item.advice_ar}</div>
        </div>
        {/* </div> */}
      </div>
      <div className="row px-0 mx-0">
        {/* <div className="col-12  "> */}
        <div className={`${classes.input} px-0 mb-3`}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              addComment();
            }}
          >
            {loading ? null : (
              <InputGroup
                default={comment}
                placeholder={comment}
                onChange={(e) => setComment(e.target.value)}
                //   error={showError && !data.description}
                //   errorMsg="Provide a Description"
                //   name="description"
                value="Comment"
              />
            )}
          </form>
          {/* </div> */}
        </div>
      </div>
      <div className={`px-0 ${classes["single-item"]}`}>
        {/* <div className="row px-0"> */}
        {comments.length > 0 && (
          <div dir="auto">
            <p>Previos Comments</p>
            {comments.map((item, index) => (
              <div key={index} className={`${classes.content} mb-1`}>
                {item}
              </div>
            ))}
          </div>
        )}
        {/* </div> */}
      </div>
      <div className={`row px-0 mx-0 ${classes.date} mt-3`}>
        {/* <div className="row px-0"> */}
        <div dir="auto" className="col-6 px-0">
          <p>
            Starting Date :
            <span className={classes.blue}>
              {" "}
              {new Date(props.item.startDate).toLocaleDateString()}
            </span>
          </p>
        </div>
        <div dir="auto" className="col-6 px-0">
          <p>
            End Date :
            <span className={classes.blue}>
              {" "}
              {new Date(props.item.endDate).toLocaleDateString()}
            </span>
            {/* <span> العنوان</span> عنوان النصيحة : */}
          </p>
        </div>
      </div>
      {/* </div> */}
      <div className="row mx-0 px-0  justify-content-end mb-pb-0">
        {/* <div className="w-auto  ms-auto"> */}
        <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
          <div className={`  mb-pb-0`}>
            <Button
              onClick={() => {
                // setOption("Main Data");
                setShowDeleteWarning(true);
                // props.setShowForm(false);
              }}
              // icon={<Plus />}
              color="red"
              fontColor="white"
              borderColor="red"
              className={"w-auto d-inline px-2   "}
              type="normal"
              text={"Delete Advice"}
            />
          </div>
        </div>
        <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
          <div className={`  mb-pb-0`}>
            <Button
              onClick={() => {
                // setOption("Media");
                history(
                  `/account/marketing-manager/advice/${props.item._id}/edit`
                );
              }}
              // icon={<Plus />}
              color={"#D1E6FF"}
              borderColor={"#134074"}
              fontColor={"#134074"}
              className={"w-auto d-inline px-2  ms-auto text-end"}
              type="normal"
              text={"Update Advice"}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
