import React from "react";
import classes from "./AddMember.module.scss";
import Close from "../../SVG/Close";
import InputGroup from "../../UI/InputGroup/InputGroup";
import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import RemoveCard from "./RemoveMember";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { adsService } from "../../../../services/adsService";
import { serverErrorCatcher } from "../../../../Helper/ServerErrorCatcher";
import FailPop from "./FailPop";
export default function SigleAd(props) {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [ad, setAd] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();
  let getAd = async () => {
    let response = await adsService.getSingleAds(props.id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid) {
      setAd(response.data.ads);
      setLoading(false);
    } else {
    }
  };

  let deleteAd = async () => {
    let response = await adsService.deleteAd(props.id);
    let Valid = false;
    Valid = serverErrorCatcher(response, setServerError, setServerErrorMsg);

    if (Valid) {
      props.onSuccess();
    } else {
    }
  };
  useEffect(() => {
    getAd();
  }, []);
  return (
    <>
      {showDeleteWarning && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => {
            setShowDeleteWarning(false);
          }}
        >
          <RemoveCard
            setShowForm={setShowDeleteWarning}
            message1="Are you sure you want to remove this Ad?"
            message2="By removing this Advice, it won’t show anymore."
            buttonText="Remove Ad"
            onClick={() => {
              props.setShowForm(false);

              deleteAd();
            }}
          />
        </Modal>
      )}
      {serverError && (
        <Modal
          style={{ padding: "24px" }}
          onClose={() => setServerError(false)}
        >
          <FailPop setShowForm={setServerError} message1={serverErrorMsg} />
        </Modal>
      )}
      {loading ? null : (
        <div className="row px-0 mx-0">
          <div className={`col-10 px-0 ${classes.title}`}>Ad Details</div>
          <div
            onClick={() => props.setShowForm(false)}
            className="cursor col-2 px-0 text-end"
          >
            <Close color="#134074" />
          </div>

          <div className={`${classes["single-item"]} px-0`}>
            <p>
              Ad Title : <span> {ad.title_en} </span>
            </p>
          </div>
          {/* <div className={`${classes["single-item"]}`}>
            <p>
              Ad Owner : <span> {ad.topManager.name} </span>
            </p>
          </div> */}
          <div className={`${classes["single-item"]} px-0`}>
            <p>
              Category :
              <span>
                {ad.categories.map((item, index) => (
                  <>
                    {item?.item?.name_en} &nbsp;
                    {index !== ad.categories.length - 1 && ","}
                  </>
                ))}
              </span>
            </p>
          </div>
          <div className={`${classes["single-item"]} px-0`}>
            <p>
              Sub Category :
              <span>
                {ad.subCategories.map((item, index) => (
                  <>
                    {item?.item?.name_en} &nbsp;{" "}
                    {index !== ad.subCategories.length - 1 && ","}
                  </>
                ))}
              </span>
            </p>
          </div>
          <div className={`${classes["single-item"]} px-0`}>
            <p>Ad Link </p>
            <div
              className={`${classes.contnet} text-black-50 cursor border border-0`}
            >
              <a href={ad.link} target="_blank">
                Ad's Link
              </a>
            </div>
          </div>
          <div className={`row px-0 mx-0 ${classes.date} mt-3`}>
            {/* <div className="row px-0"> */}
            <div dir="auto" className="col-6 px-0">
              <p>
                Starting Date :
                <span className={classes.blue}>
                  {" "}
                  {new Date(ad.startDate).toLocaleDateString()}
                </span>
              </p>
            </div>
            <div dir="auto" className="col-6 px-0">
              <p>
                End Date :
                <span className={classes.blue}>
                  {new Date(ad.endDate).toLocaleDateString()}
                </span>
                {/* <span> العنوان</span> عنوان النصيحة : */}
              </p>
            </div>
          </div>
          {/* </div> */}
          <div className="row mx-0 px-0  justify-content-end mb-pb-0">
            {/* <div className="w-auto  ms-auto"> */}
            <div className="w-auto d-inline  px-0 pe-2 mb-pb-0">
              <div className={`  mb-pb-0`}>
                <Button
                  onClick={() => {
                    setShowDeleteWarning(true);
                    // props.setShowForm(false);
                    // setOption("Main Data");
                  }}
                  // icon={<Plus />}
                  color="red"
                  fontColor="white"
                  borderColor="red"
                  className={"w-auto d-inline px-2   "}
                  type="normal"
                  text={"Delete Ad"}
                />
              </div>
            </div>
            <div className="w-auto d-inline  px-0 ps-2 mb-pb-0">
              <div className={`  mb-pb-0`}>
                <Button
                  onClick={() => {
                    // setOption("Media");
                    history(`/account/marketing-manager/ad/${props.id}/edit`);
                  }}
                  // icon={<Plus />}
                  color={"#D1E6FF"}
                  borderColor={"#134074"}
                  fontColor={"#134074"}
                  className={"w-auto d-inline px-2  ms-auto text-end"}
                  type="normal"
                  text={"Update Ad"}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
}
