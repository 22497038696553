import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import NavBar from "../../components/NavBar/NavBar";
import SwiperImages from "../../components/Sections/SwiperImages";
import Section1 from "../../components/Sections/Section1";
import Section2 from "../../components/Sections/Section2";
import Section3 from "../../components/Sections/Section3";
import Section4 from "../../components/Sections/Section4";
import Section5 from "../../components/Sections/Section5";
import Section6 from "../../components/Sections/Section6";
import Footer from "../../components/Sections/Footer";
import Section42 from "../../components/Sections/Section42";
import Loading from "../../components/Sections/Loading";
import { MainDataService } from "../../services/mainData";

export default function Landing() {
  const [formData, setFormData] = useState({
    id: null,
    google: null,
    apple: null,
  });

  const [socail, setSocail] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [showLoading, setShowLoading] = useState(true);

  const updateWindowDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const getAboutData = async () => {
    try {
      let response = await MainDataService.getData();
      setFormData({
        id: response.data.about._id,
        google: response.data.about.google,
        apple: response.data.about.apple,
      });
      setSocail(response.data.about.social);
    } catch (error) {
      //console.error("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    getAboutData();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showLoading ? (
        ReactDOM.createPortal(
          <div
            style={{
              background: "#340040",
              width: `${windowDimensions.width}px`,
              height: `100vh`,
              overflowY: "hidden",
              // border: "1px solid red",
            }}
          >
            <Loading />
          </div>,
          document.getElementById("overlays")
        )
      ) : (
        <div
        // className="border border-success"
        // style={{
        //   height: showLoading && `100vh`,
        //   overflowY: showLoading ? "hidden" : "visible",
        // }}
        >
          <div style={{ opacity: showLoading ? 0 : 1 }}>
            <NavBar />
            <div className="overflow-hidden">
              <SwiperImages />
              <section id="about-us">
                <Section1 data={formData} />
              </section>
              <section id="deals">
                <Section2 />
                <Section3 />
                <Section4 />
                <Section42 />
              </section>
              <section id="partners" className="mb-5 pb-5">
                <Section5 />
              </section>
              <section id="contact-us">
                <Section6 />
              </section>
              <Footer social={socail} data={formData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
